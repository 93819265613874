import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import Cookies from 'js-cookie';

export type CookieConsentContextType = {
  consent: boolean | null;
  updateConsent: (value: boolean | null) => void;
};

export const CookieConsentContext = createContext<
  CookieConsentContextType | undefined
>(undefined);

export const useCookieConsent = () => {
  const context = useContext(CookieConsentContext);
  if (context === undefined) {
    throw new Error(
      "useCookieConsent deve essere usato all'interno di un CookieConsentProvider"
    );
  }
  return context;
};

interface CookieConsentProviderProps {
  children: ReactNode;
}
export const CookieConsentProvider: React.FC<CookieConsentProviderProps> = ({
  children,
}) => {
  const [consent, setConsent] = useState<boolean | null>(null);

  useEffect(() => {
    const userConsent = Cookies.get('userConsent');
    const currentConsent =
      userConsent === 'true' ? true : userConsent === 'false' ? false : null;
    setConsent(currentConsent);
  }, []);

  const updateConsent = (value: boolean | null) => {
    if (value === null) {
      Cookies.remove('userConsent');
    } else {
      Cookies.set('userConsent', String(value), { expires: 90 });
    }
    setConsent(value);
  };

  return (
    <CookieConsentContext.Provider value={{ consent, updateConsent }}>
      {children}
    </CookieConsentContext.Provider>
  );
};
