// Maintenance.tsx
import React from "react";

const Maintenance: React.FC = () => {
	return (
		<div className="vh-50" style={{ textAlign: "center", marginTop: "50px" }}>
			<h1>Fantantonio in Manutenzione</h1>
			<p className="text-light">Stiamo lavorando per migliorare il sito. Torna a trovarci più tardi.</p>
		</div>
	);
};

export default Maintenance;
