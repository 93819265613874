import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row } from "react-bootstrap";
import TeamCard from "./TeamCard";
import { ISquadra, TorneoData } from "../Interfaces/Interface";
import { shuffleArray } from "../Script/Utility";
import { useLocation } from "react-router-dom";

interface TeamListProps {
	items: (ISquadra | TorneoData)[];
}

const TeamList: React.FC<TeamListProps> = ({ items }) => {
	const location = useLocation();

	const isSquadra = (item: ISquadra | TorneoData): item is ISquadra => {
		return (item as ISquadra).giocatori !== undefined;
	};

	// controllo per escludere al squadra dello staff dal caroselle delel squadre partecipanti
	const filteredItems = items.filter((item) => item.id !== "0");
	const shuffledItems = shuffleArray(filteredItems);

	const settings = {
		dots: false,
		infinite: true,
		speed: 1000,
		autoplay: true,
		autoplaySpeed: 4500,
		slidesToShow: 3,
		slidesToScroll: 1,
		swipeToSlide: true,
		draggable: true,
		centerMode: true,
		centerPadding: "0px",
		responsive: [
			{
				breakpoint: 1600,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1400,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				},
			},
			{
				breakpoint: 576,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
				},
			},
		],
	};

	return (
		<Container>
			<Row className="text-center my-3">
				<div className="d-flex align-items-center justify-content-center mb-3">
					{location.pathname.includes("/finale") ? (
						<h1 className="fs-1 glass-card bg-transparent px-2 py-1">Fasi finali</h1>
					) : (
						<h4 className="fs-2 glass-card bg-transparent px-2 py-1 text-light">Squadre di questa stagione</h4>
					)}
				</div>
				<Slider {...settings} className="slider-hvr">
					{shuffledItems.map((item) => (
						<div key={item._id} className="tooltip-container slide">
							<TeamCard item={item} />
							<span className="tooltip-right">{/*{isSquadra(item) ? item.nome : item.nomeSquadra}*/} Dettagli</span>
						</div>
					))}
				</Slider>
			</Row>
		</Container>
	);
};

export default TeamList;
