import { BackendModule, ReadCallback, Services, InitOptions } from "i18next";

interface BackendOptions {
	loadPath?: string;
}

class CachedHttpBackend implements BackendModule {
	type = "backend" as const;
	services!: Services; // Uso del definite assignment assertion
	backendOptions!: BackendOptions;
	i18nOptions!: InitOptions; // Uso del definite assignment assertion

	init(services: Services, backendOptions: BackendOptions, i18nOptions: InitOptions): void {
		this.services = services;
		this.backendOptions = backendOptions;
		this.i18nOptions = i18nOptions;
	}

	read(language: string, namespace: string, callback: ReadCallback): void {
		const cacheKey = `i18n_${language}_${namespace}`;
		const cached = localStorage.getItem(cacheKey);

		if (cached) {
			const data = JSON.parse(cached);
			const now = new Date();
			if (now.getTime() - data.timestamp < 90 * 24 * 60 * 60 * 1000) {
				return callback(null, data.value); // Successo, utilizza i dati dalla cache
			}
		}

		// Se non presente in cache o scaduto, carica da remoto
		fetch(`/locales/${language}/${namespace}.json`)
			.then((response) => response.json())
			.then((data) => {
				localStorage.setItem(cacheKey, JSON.stringify({ timestamp: Date.now(), value: data }));
				callback(null, data); // Successo, dati freschi
			})
			.catch((error) => callback(error, null)); // Gestione errori
	}
}

export default CachedHttpBackend;
