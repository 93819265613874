import React, { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { useCookieConsent } from "../Context/CoockieContext";
import NotifyToast from "../Toast/NotifyToast";
import { useNotificationSound } from "../Context/NoticationSoundContext";

const Cookie = () => {
	const { consent, updateConsent } = useCookieConsent();
	const { soundEnabled } = useNotificationSound();
	const [showBanner, setShowBanner] = useState(consent === null || consent === undefined);

	useEffect(() => {
		setShowBanner(consent === null || consent === undefined);
	}, [consent]);

	const handleAccept = () => {
		updateConsent(true);
		NotifyToast.success("Cookie Accettati :)", soundEnabled);
	};

	const handleDecline = () => {
		updateConsent(false);
		NotifyToast.error("Cookie Non Accettati :(", soundEnabled);
	};

	return showBanner ? (
		<CookieConsent location="center" cookieName="userConsent" expires={150} containerClasses="cookie-banner" overlay>
			<div className="cookie-content">
				{`Questo sito utilizza cookie per migliorare l'esperienza utente. Per
        maggiori informazioni sui cookie che utilizziamo, consultare la nostra`}{" "}
				<a href="/policy" className="nav-link tel gold mx-auto">
					Privacy Policy
				</a>
			</div>
			<div className="button-container">
				<button className="cookie-accept-btn" onClick={handleAccept}>
					Ho Capito
				</button>
				<button className="cookie-decline-btn" onClick={handleDecline}>
					Rifiuto
				</button>
			</div>
		</CookieConsent>
	) : null;
};

export default Cookie;
