import React from 'react';

type AboutIconProps = {
  className?: string;
  width?: string;
};

const AboutIcon: React.FC<AboutIconProps> = ({
  className = '',
  width = '35px',
}) => {
  return (
    <svg
      width={width}
      height="35px"
      viewBox="0 0 512 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={`border-gold ${className}`}
    >
      <title>about-filled</title>
      <g id="Page-1" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="drop" fill="#fff" transform="translate(42.666667, 42.666667)">
          <path
            d="M213.333333,3.55271368e-14 C331.154987,3.55271368e-14 426.666667,95.51168 426.666667,213.333333 C426.666667,331.153707 331.154987,426.666667 213.333333,426.666667 C95.51296,426.666667 3.55271368e-14,331.153707 3.55271368e-14,213.333333 C3.55271368e-14,95.51168 95.51296,3.55271368e-14 213.333333,3.55271368e-14 Z M234.713387,192 L192.04672,192 L192.04672,320 L234.713387,320 L234.713387,192 Z M213.55008,101.333333 C197.99616,101.333333 186.713387,112.5536 186.713387,127.704107 C186.713387,143.46752 197.698773,154.666667 213.55008,154.666667 C228.785067,154.666667 240.04672,143.46752 240.04672,128 C240.04672,112.5536 228.785067,101.333333 213.55008,101.333333 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default AboutIcon;
