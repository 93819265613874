import React from 'react';

const YoungManIcon = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 124.539 124.539"
      className="icon-lang"
      fill="CurrentColor"
    >
      <g>
        <g>
          <circle cx="69.152" cy="13.686" r="13.686" />
          <path
            d="M97.759,61.177c-2.95-20.117-7.313-29.555-17.372-36.598c-2.849,2.938-6.829,4.772-11.234,4.772
c-4.489,0-8.532-1.909-11.391-4.945c-3.723,3.123-6.575,7.375-9.132,11.226c-2.936,4.421-5.972,8.993-8.745,9.228
c-0.294,0.025-0.577,0.079-0.854,0.149c-0.817-0.224-1.674-0.353-2.561-0.353c-5.374,0-9.746,4.371-9.746,9.746
c0,1.468,1.19,2.657,2.658,2.657c1.468,0,2.657-1.189,2.657-2.657c0-1.936,1.255-3.566,2.987-4.169
c0.002,0.123,0.002,0.245,0.013,0.369c0.235,2.772,2.557,4.864,5.288,4.864c0.151,0,0.302-0.005,0.456-0.019
c0.04-0.003,0.077-0.012,0.116-0.016v66.45c0,1.469,1.19,2.658,2.658,2.658c1.468,0,2.658-1.189,2.658-2.658v-67.48
c0-0.192-0.018-0.38-0.029-0.568c3.494-1.863,6.24-5.066,8.665-8.462v17.184c0,1.361,0.246,2.641,0.689,3.818
c-0.986,4.342-4.824,21.553-5.023,27.359l-0.273,7.902c-0.567,16.33-0.567,16.411-0.567,16.625c0,3.469,2.811,6.279,6.279,6.279
c3.431,0,6.22-2.752,6.278-6.17c0.019-0.7,0.332-9.707,0.561-16.301l0.273-7.903c0.1-2.927,1.908-12.104,3.547-19.707
c0.738,0.104,1.487,0.17,2.243,0.17c0.961,0,1.914-0.093,2.847-0.26c1.643,7.616,3.465,16.858,3.565,19.797l0.272,7.903
c0.229,6.594,0.543,15.601,0.562,16.301c0.06,3.418,2.847,6.17,6.278,6.17c3.468,0,6.279-2.812,6.279-6.279
c0-0.214,0-0.295-0.567-16.625l-0.273-7.902c-0.206-6-4.294-24.164-5.11-27.746c-0.055-0.234-0.123-0.463-0.201-0.686
c0.227-0.871,0.355-1.787,0.355-2.748V44.254c1.7,4.243,3.149,10.074,4.379,18.465c0.389,2.643,2.657,4.543,5.25,4.543
c0.258,0,0.517-0.02,0.778-0.058C96.177,66.779,98.185,64.08,97.759,61.177z"
          />
        </g>
      </g>
    </svg>
  );
};

export default YoungManIcon;
