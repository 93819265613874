import axiosInstance, { refreshToken } from "./axiosConfig";
import axios, { AxiosError } from "axios";

export const fetchProtectedData = async (endpoint: string, options: any = {}): Promise<any> => {
	try {
		const response = await axiosInstance({
			url: endpoint,
			method: options.method || "GET",
			headers: options.headers || {},
			data: options.body || {},
		});
		return response.data;
	} catch (error) {
		if (isAxiosError(error) && error.response && error.response.status === 403) {
			console.log("Token expired, attempting to refresh token...");
			try {
				await refreshToken();
				const retryResponse = await axiosInstance({
					url: endpoint,
					method: options.method || "GET",
					headers: options.headers || {},
					data: options.data || {},
				});
				return retryResponse.data;
			} catch (retryError) {
				console.error("Retry failed after token refresh", retryError);
				throw retryError;
			}
		} else {
			console.error("Failed to fetch protected data", error);
			throw error;
		}
	}
};

function isAxiosError(error: unknown): error is AxiosError {
	return axios.isAxiosError(error);
}
