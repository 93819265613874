import React from 'react';
import ReactModal from 'react-modal';
import { ISquadra } from '../Interfaces/Interface'; // Assicurati che il percorso sia corretto
import { Col, Container, Row } from 'react-bootstrap';

interface PreferitiModalProps {
  isModalOpen: boolean;
  handleModalClose: (confirm: boolean, action: 'replace' | 'remove') => void;
  setIsModalOpen: (isOpen: boolean) => void;
  currentFavorite: string | null;
  squadra: ISquadra | null;
}
const PreferitiModal: React.FC<PreferitiModalProps> = ({
  isModalOpen,
  handleModalClose,
  setIsModalOpen,
  currentFavorite,
  squadra,
}) => {
  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      contentLabel="Scelta Squadra Preferita"
      className="Modal-evento"
      overlayClassName="Overlay"
    >
      {currentFavorite === squadra?.id ? (
        <Container>
          <Row>
            <Col>
              <h2 className="text-center">Conferma Rimozione</h2>
              <p className="text-light text-center">
                Sei sicuro di voler rimuovere{' '}
                <span className="gold text-underline fw-semibold">
                  {squadra?.nome}
                </span>{' '}
                dai preferiti?
              </p>
              <div className="d-flex align-items-center justify-content-around">
                <button
                  className="nav-link gold tel"
                  onClick={() => handleModalClose(true, 'remove')}
                >
                  Rimuovi
                </button>
                <button
                  className="nav-link back tel"
                  onClick={() => setIsModalOpen(false)}
                >
                  Annulla
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <h2 className="text-center">Hai già una squadra preferita!</h2>
          <p className="text-light">
            Vuoi sostituire la squadra preferita attuale con {squadra?.nome}?
          </p>
          <div className="d-flex align-items-center justify-content-around">
            <button
              className="nav-link gold tel"
              onClick={() => handleModalClose(true, 'replace')}
            >
              Sostituisci
            </button>
            <button
              className="nav-link back tel"
              onClick={() => setIsModalOpen(false)}
            >
              Annulla
            </button>
          </div>
        </>
      )}
    </ReactModal>
  );
};

export default PreferitiModal;
