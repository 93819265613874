import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Container, Nav, Navbar, Button, Card } from "react-bootstrap";
import { useOffcanvas } from "../Context/OffCanvasContenxt";
import Back from "./Back";
import { useAuth } from "../Context/AuthContext";
import Modal from "react-modal";
import { motion, AnimatePresence } from "framer-motion";

const modalVariants = {
	hidden: { opacity: 0, y: 100 },
	visible: (i: number) => ({
		opacity: 1,
		y: 0,
		transition: {
			delay: i * 0.1,
		},
	}),
	exit: { opacity: 0, y: 100 },
};

const MobileNavbar = () => {
	const { toggleShow, handleClose } = useOffcanvas();
	const [isActiveHome, setIsActiveHome] = useState(false);
	const [isActiveTrophy, setIsActiveTrophy] = useState(false);
	const [isActiveCalendar, setIsActiveCalendar] = useState(false);
	const [isActiveDoc, setIsActiveDoc] = useState(false);
	const [isActiveFinale, setIsActiveFinale] = useState(false);
	const [isActiveAbout, setIsActiveAbout] = useState(false);
	const [lastScrollY, setLastScrollY] = useState(0);
	const [navbarVisible, setNavbarVisible] = useState(true);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
	const [modalOpen, setModalOpen] = useState(false);
	const [desktopModalOpen, setDesktopModalOpen] = useState(false);
	const { isAuthenticated, user } = useAuth();

	const location = useLocation();

	useEffect(() => {
		const handleNavigation = () => {
			const currentScrollY = window.scrollY;
			if (currentScrollY > lastScrollY + 10) {
				setNavbarVisible(false);
				setModalOpen(false);
			} else if (currentScrollY < lastScrollY - 10) {
				setNavbarVisible(true);
			}
			setLastScrollY(currentScrollY);
		};

		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener("scroll", handleNavigation);
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("scroll", handleNavigation);
			window.removeEventListener("resize", handleResize);
		};
	}, [lastScrollY]);

	useEffect(() => {
		switch (location.pathname) {
			case "/":
				setIsActiveHome(true);
				setIsActiveTrophy(false);
				setIsActiveCalendar(false);
				setIsActiveDoc(false);
				setIsActiveFinale(false);
				setIsActiveAbout(false);
				break;
			case "/classifica":
				setIsActiveHome(false);
				setIsActiveTrophy(true);
				setIsActiveCalendar(false);
				setIsActiveDoc(false);
				setIsActiveFinale(false);
				setIsActiveAbout(false);
				break;
			case "/calendario":
				setIsActiveHome(false);
				setIsActiveTrophy(false);
				setIsActiveCalendar(true);
				setIsActiveDoc(false);
				setIsActiveFinale(false);
				setIsActiveAbout(false);
				break;
			case "/generale":
				setIsActiveHome(false);
				setIsActiveTrophy(false);
				setIsActiveCalendar(false);
				setIsActiveDoc(true);
				setIsActiveFinale(false);
				setIsActiveAbout(false);
				break;
			case "/finale":
				setIsActiveHome(false);
				setIsActiveTrophy(false);
				setIsActiveCalendar(false);
				setIsActiveDoc(false);
				setIsActiveFinale(true);
				setIsActiveAbout(false);
				break;
			default:
				setIsActiveHome(false);
				setIsActiveTrophy(false);
				setIsActiveCalendar(false);
				setIsActiveDoc(false);
				setIsActiveFinale(false);
				setIsActiveAbout(false);
				break;
		}
	}, [location]);

	const handleAnimate = (setActive: React.Dispatch<React.SetStateAction<boolean>>) => {
		setActive(true);
		setTimeout(() => {
			setActive(false);
		}, 350);
	};

	const handleModalToggle = () => {
		setModalOpen(!modalOpen);
	};

	const handleDesktopModalToggle = () => {
		setDesktopModalOpen(!desktopModalOpen);
	};

	const handleLinkClick = (setActive: React.Dispatch<React.SetStateAction<boolean>>, path: string) => {
		handleAnimate(setActive);
		handleClose();
		setModalOpen(false);
		setDesktopModalOpen(false);
	};

	const closeModal = () => {
		setModalOpen(false);
		setDesktopModalOpen(false);
	};

	return (
		<div className={`mobilenavbar mx-2 ${navbarVisible ? "" : "hidden"}`}>
			<Navbar expand="lg" className={`banner mt-3 rounded-pill glass-card mb-2 bg-transparent`}>
				<Container fluid>
					<Nav className="d-flex flex-row align-items-center justify-content-around w-100">
						<Back />
						<Nav.Item>
							<Button
								className={`nav-link nav-links point`}
								onClick={() => {
									toggleShow();
									setModalOpen(false);
								}}
							>
								Menu
							</Button>
						</Nav.Item>
						<Nav.Item>
							<NavLink
								to="/"
								className={`nav-link nav-links point ${isActiveHome ? "active" : ""}`}
								onClick={() => {
									handleLinkClick(setIsActiveHome, "/");
								}}
							>
								Home
							</NavLink>
						</Nav.Item>
						{isMobile ? (
							<>
								<Button
									variant="transparent"
									className="nav-links point dropdown-toggle-custom"
									id="dropdown-basic"
									onClick={handleModalToggle}
								>
									More
								</Button>
								<Modal
									isOpen={modalOpen}
									onRequestClose={handleModalToggle}
									contentLabel="More Options"
									className="custom-modal-content"
									overlayClassName="custom-modal-overlay"
									shouldCloseOnOverlayClick={true}
								>
									<div onClick={closeModal} className="overlay">
										<Card className="glass-card p-4 text-center" onClick={(e) => e.stopPropagation()}>
											<AnimatePresence>
												{modalOpen && (
													<motion.div initial="hidden" animate="visible" exit="exit" variants={modalVariants}>
														{isAuthenticated && user && (
															<div className="d-flex flex-column align-items-center">
																{user.googleProfileImage && (
																	<NavLink
																		to={`/profilo/${user._id}`}
																		className="nav-link mb-2"
																		onClick={() => handleLinkClick(setIsActiveHome, `/profilo/${user._id}`)}
																	>
																		<img
																			src={user.googleProfileImage}
																			alt="Profile"
																			className="profile-image rounded-circle mb-2"
																			style={{ width: "60px", height: "60px" }}
																		/>
																	</NavLink>
																)}
																<NavLink
																	to={`/profilo/${user._id}`}
																	className="nav-link mb-2 gold fs-3"
																	onClick={() => handleLinkClick(setIsActiveHome, `/profilo/${user._id}`)}
																>
																	{user.firstName} {user.lastName}
																</NavLink>
															</div>
														)}
														<div className="d-flex flex-row justify-content-around">
															<div className="d-flex flex-column">
																<NavLink
																	to="/classifica"
																	className={`nav-links text-deco-none fs-4 mx-1 ${isActiveTrophy ? "active" : ""} mb-2`}
																	onClick={() => {
																		handleLinkClick(setIsActiveTrophy, "/classifica");
																	}}
																>
																	Classifica
																</NavLink>

																<NavLink
																	to="/finale"
																	className={`nav-links text-deco-none fs-4 mx-1 ${isActiveFinale ? "active" : ""} mb-2`}
																	onClick={() => {
																		handleLinkClick(setIsActiveFinale, "/finale");
																	}}
																>
																	Fasi Finali
																</NavLink>
															</div>
															<div className="d-flex flex-column">
																<NavLink
																	to="/calendario"
																	className={`nav-links text-deco-none fs-4 mx-1 ${isActiveCalendar ? "active" : ""} mb-2`}
																	onClick={() => {
																		handleLinkClick(setIsActiveCalendar, "/calendario");
																	}}
																>
																	Gironi
																</NavLink>
																<NavLink
																	to="/generale"
																	className={`nav-links text-deco-none fs-4 mx-1 ${isActiveDoc ? "active" : ""} mb-2`}
																	onClick={() => {
																		handleLinkClick(setIsActiveDoc, "/generale");
																	}}
																>
																	Documenti
																</NavLink>
															</div>
														</div>
													</motion.div>
												)}
											</AnimatePresence>
										</Card>
									</div>
								</Modal>
							</>
						) : (
							<>
								<Nav.Item>
									<NavLink
										to="/classifica"
										className={`nav-link nav-links ${isActiveTrophy ? "active" : ""}`}
										onClick={() => handleLinkClick(setIsActiveTrophy, "/classifica")}
									>
										Classifica
									</NavLink>
								</Nav.Item>
								<Nav.Item>
									<Button
										variant="transparent"
										className={`nav-link nav-links point`}
										onClick={handleDesktopModalToggle}
									>
										Giornate
									</Button>
									<Modal
										isOpen={desktopModalOpen}
										onRequestClose={handleDesktopModalToggle}
										contentLabel="More Options"
										className="custom-modal-content"
										overlayClassName="custom-modal-overlay"
										shouldCloseOnOverlayClick={true}
									>
										<div onClick={closeModal} className="overlay">
											<Card className="glass-card p-4 text-center" onClick={(e) => e.stopPropagation()}>
												<AnimatePresence>
													{desktopModalOpen && (
														<motion.div initial="hidden" animate="visible" exit="exit" variants={modalVariants}>
															<div className="d-flex flex-row justify-content-around">
																<div className="d-flex flex-column">
																	<NavLink
																		to="/calendario"
																		className={`nav-links text-deco-none fs-4 mx-1 ${isActiveCalendar ? "active" : ""} mb-2`}
																		onClick={() => {
																			handleLinkClick(setIsActiveCalendar, "/calendario");
																		}}
																	>
																		Gironi
																	</NavLink>
																	<NavLink
																		to="/finale"
																		className={`nav-links text-deco-none fs-4 mx-1 ${isActiveFinale ? "active" : ""} mb-2`}
																		onClick={() => {
																			handleLinkClick(setIsActiveFinale, "/finale");
																		}}
																	>
																		Fasi Finali
																	</NavLink>
																</div>
															</div>
														</motion.div>
													)}
												</AnimatePresence>
											</Card>
										</div>
									</Modal>
								</Nav.Item>
								<Nav.Item>
									<NavLink
										to="/generale"
										className={`nav-link nav-links ${isActiveDoc ? "active" : ""}`}
										onClick={() => handleLinkClick(setIsActiveDoc, "/generale")}
									>
										Documenti
									</NavLink>
								</Nav.Item>
								{isAuthenticated && user && (
									<h4 className="glass-card d-flex align-items-center">
										{user.googleProfileImage && (
											<NavLink
												to={`/profilo/${user._id}`}
												className="nav-link bg-transparent nav-links"
												onClick={() => handleLinkClick(setIsActiveHome, `/profilo/${user._id}`)}
											>
												<img
													src={user.googleProfileImage}
													alt="Profile"
													className="profile-image rounded-circle me-2"
													style={{ width: "40px", height: "40px" }}
												/>
											</NavLink>
										)}
										<NavLink
											to={`/profilo/${user._id}`}
											className="nav-link bg-transparent nav-links"
											onClick={() => handleLinkClick(setIsActiveHome, `/profilo/${user._id}`)}
										>
											{user.firstName} {user.lastName}
										</NavLink>
									</h4>
								)}
							</>
						)}
					</Nav>
				</Container>
			</Navbar>
		</div>
	);
};

export default MobileNavbar;
