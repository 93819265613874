import React, { useState, useEffect } from 'react';
import TopArrow from '../svg/TopArrow';

const Top = () => {
  const [isVisible, setIsVisible] = useState(false);
  const threshold = 400; // Soglia di scroll per mostrare il bottone

  const toggleVisibility = () => {
    if (window.scrollY > threshold) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  return (
    <div
      className="scroll-top"
      onClick={scrollTop}
      style={{
        opacity: isVisible ? 1 : 0,
        display: isVisible ? 'block' : 'none',
        transition: 'opacity 0.25s ease-in-out', // Gestisce la transizione di opacità
      }}
    >
      <TopArrow />
    </div>
  );
};

export default Top;
