import React from 'react';
import { Helmet } from 'react-helmet';
import { useHelmet } from './HelmetContext';

const SEOHelmet = () => {
  const { state } = useHelmet();

  return (
    <Helmet>
      <meta charSet={state.charset} />
      <title>{state.title}</title>
      <meta name="viewport" content={state.viewport} />
      <meta name="description" content={state.description} />
      <meta name="keywords" content={state.keywords} />
      <meta property="og:site_name" content={state['og:site_name']} />
      <meta property="og:title" content={state.title} />
      <meta property="og:image" content={state['og:image']} />
      <meta property="og:description" content={state.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={state['og:url']} />
      <meta name="twitter:card" content={state['twitter:card']} />
      <meta name="twitter:title" content={state['twitter:title']} />
      <meta name="twitter:description" content={state['twitter:description']} />
      <meta name="twitter:image" content={state['twitter:image']} />
      <meta name="twitter:url" content={state['twitter:url']} />
      <link rel="icon" type="image/png" href={state.favicon} sizes="32x32" />
      <link rel="apple-touch-icon" href={state['apple-touch-icon']} />
      <link rel="icon" sizes="192x192" href={state['android-chrome-192x192']} />
      <link rel="icon" sizes="512x512" href={state['android-chrome-512x512']} />
    </Helmet>
  );
};

export default SEOHelmet;
