import React from "react";
import Partita from "../Calendario/Partita"; // Assicurati che il percorso sia corretto
import { parseDate } from "../Script/Utility";
import { Card } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { giornate, Giornata as GiornataType, Partita as PartitaType } from "../Interfaces/Interface";
import squadre from "../Script/squadre.json"; // Importazione standard del JSON

const UltimaGiornata: React.FC = () => {
	const oggi = new Date();
	oggi.setHours(0, 0, 0, 0);

	const giornateConDataParsed = giornate
		.map((giornata: GiornataType) => ({ ...giornata, dataParsed: parseDate(giornata.data) }))
		.filter((giornata: GiornataType) => giornata.dataParsed instanceof Date && giornata.dataParsed <= oggi)
		.sort((a: GiornataType, b: GiornataType) => (b.dataParsed as Date).getTime() - (a.dataParsed as Date).getTime());

	const ultimaGiornata = giornateConDataParsed[0];

	if (!ultimaGiornata) {
		return (
			<Card className="my-2 glass-card hvr3">
				<NavLink to="/calendario" className="nav-link gold text-center" title="Calendario campionato">
					<Card.Body className="d-flex flex-column align-items-center justify-content-center">
						<Card.Text className="text-light text-center border-0 text-underline gold">
							Nessuna partita disputata finora!
						</Card.Text>
						<Card.Text className="text-light text-center border-0">
							{`Dai un'occhiata al calendario e scopri quando si scende in campo!`}
						</Card.Text>
						<img src="/assets/img/soccer-calendar.webp" alt="Calendario" className="img-fluid inverted point" />
					</Card.Body>
				</NavLink>
			</Card>
		);
	}

	const isToday = (ultimaGiornata.dataParsed as Date).getTime() === oggi.getTime();
	const headerText = isToday
		? `Partite di oggi (${ultimaGiornata.data})`
		: `Ultima Giornata Giocata (${ultimaGiornata.data})`;

	// Funzione per ordinare le partite in base all'orario di inizio
	const ordinaPartitePerOrario = (partite: PartitaType[]) => {
		return partite.sort((a: PartitaType, b: PartitaType) => {
			const [aOra, aMinuti] = a.oraInizio.split(":").map(Number);
			const [bOra, bMinuti] = b.oraInizio.split(":").map(Number);
			const aDate = new Date(ultimaGiornata.dataParsed as Date);
			const bDate = new Date(ultimaGiornata.dataParsed as Date);
			aDate.setHours(aOra, aMinuti);
			bDate.setHours(bOra, bMinuti);
			return aDate.getTime() - bDate.getTime();
		});
	};

	const partiteOrdinate = ordinaPartitePerOrario(ultimaGiornata.partite);

	return (
		<Card className="my-2 bg-transparent glass-card border-0">
			<Card.Header className="gold text-center mt-3 border-0 fs-5">{headerText}</Card.Header>
			<Card.Body>
				{partiteOrdinate.map((partita: PartitaType, index: number) => (
					<Partita key={index} partita={partita} squadre={squadre} dataGiornata={ultimaGiornata.data} />
				))}
			</Card.Body>
		</Card>
	);
};

export default UltimaGiornata;
