// TranslationProvider.js

import React, { createContext, useContext, ReactNode, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLoading } from './LoadingContext';

const TranslationLoadingContext = createContext(false);

interface TranslationProviderProps {
  children: ReactNode;
}

export const TranslationProvider: React.FC<TranslationProviderProps> = ({
  children,
}) => {
  const { ready } = useTranslation();
  const { startLoading, stopLoading } = useLoading();

  useEffect(() => {
    if (ready === false) {
      startLoading();
    } else if (ready === true) {
      stopLoading();
    }
  }, [ready, startLoading, stopLoading]);

  return (
    <TranslationLoadingContext.Provider value={!ready}>
      {children}
    </TranslationLoadingContext.Provider>
  );
};

export const useTranslationLoading = (): boolean =>
  useContext(TranslationLoadingContext);
