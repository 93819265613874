import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Container, Row, Col, Card, Table, ListGroup, ListGroupItem, CardText } from "react-bootstrap";
import { giornate, ISquadra, penalita as penalitaData } from "../Interfaces/Interface";
import { calcolaPunti, calcolaStatisticheSquadra, getCookie, gironeA, gironeB, setCookie } from "../Script/Utility";
import PreferitiModal from "../Modal/PreferitiModal";
import { useFavorite } from "../Context/FavoriteContext";
import { useHelmet } from "../Context/HelmetContext";
import Loader from "../Loader/Loader";
import { useCookieConsent } from "../Context/CoockieContext";
import HeartFull from "../svg/HeartFull";
import Heart from "../svg/Heart";
import NotifyToast from "../Toast/NotifyToast";
import { useNotificationSound } from "../Context/NoticationSoundContext";
import TeamPerformanceChart from "./TeamPerformanceChart";

const DettagliSquadra = () => {
	const { consent } = useCookieConsent();
	const { soundEnabled } = useNotificationSound();
	const { id } = useParams<{ id: string }>();
	const [squadra, setSquadra] = useState<ISquadra | null>(null);
	const [posizione, setPosizione] = useState(0);
	const [girone, setGirone] = useState("");
	const { favorite, setFavorite, removeFavorite } = useFavorite();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const { setState } = useHelmet();

	useEffect(() => {
		const trovataInA = gironeA.find((s) => s.id === id);
		const trovataInB = gironeB.find((s) => s.id === id);

		const trovata = trovataInA || trovataInB || null; // Assicura che 'trovata' sia null se non trovata

		if (trovata) {
			setSquadra(trovata);

			// Calcola la posizione nel girone specifico
			if (trovataInA) {
				setPosizione(gironeA.indexOf(trovata) + 1);
				setGirone("A");
			} else if (trovataInB) {
				setPosizione(gironeB.indexOf(trovata) + 1);
				setGirone("B");
			}

			setState((prevState) => ({
				...prevState,
				title: `Dettagli ${trovata.nome} - Campionato San Antonio`,
				description: "Il campionato di calcio a 7 di San Antonio Abate",
			}));
		} else {
			setSquadra(null); // Esplicitamente impostato su null se non trovata
			NotifyToast.error("Squadra non trovata", soundEnabled);
		}
	}, [id, setState, soundEnabled]);

	useEffect(() => {
		// Aggiorna il preferito dal cookie solo una volta al caricamento
		const favoriteFromCookie = getCookie("favoriteTeam");
		setFavorite(favoriteFromCookie);
	}, [setFavorite]);

	if (!squadra) return <Loader />;

	const addToFavorites = () => {
		if (consent === true) {
			if (favorite === squadra?.id) {
				setIsModalOpen(true);
			} else if (favorite) {
				setIsModalOpen(true);
			} else {
				saveFavoriteTeam();
			}
		} else {
			NotifyToast.error(
				"Consenso ai cookie necessario per aggiungere ai preferiti, in basso alla pagina potrai richiedere il consenso.",
				soundEnabled
			);
		}
	};

	const saveFavoriteTeam = () => {
		if (squadra && consent) {
			setCookie("favoriteTeam", squadra.id, 90);
			setFavorite(squadra.id);
			NotifyToast.success(`La squadra ${squadra.nome} è stata aggiunta ai preferiti!`, soundEnabled);
		}
	};

	const handleModalClose = (confirm: boolean, action: "replace" | "remove") => {
		setIsModalOpen(false);
		if (confirm) {
			if (action === "remove") {
				removeFavoriteTeam();
				removeFavorite();
			} else if (action === "replace") {
				saveFavoriteTeam();
			}
		}
	};

	const removeFavoriteTeam = () => {
		if (squadra && consent) {
			setCookie("favoriteTeam", "", -1);
			setFavorite(null);
			NotifyToast.success(`La squadra ${squadra.nome} è stata rimossa dai preferiti!`, soundEnabled);
			removeFavorite();
		}
	};

	// Usa la funzione calcolaStatisticheSquadra per ottenere le statistiche corrette
	const { goalFatti, cartelliniGialli, cartelliniRossi, goalSubiti } = calcolaStatisticheSquadra(squadra);

	const penalita = penalitaData; // Leggi i dati delle penalità dal file JSON
	const punti = calcolaPunti(squadra, penalita);
	const isFavorite = favorite === id;
	const strokeColor = isFavorite ? <HeartFull /> : <Heart />;

	return (
		<Container className="fade-in-animation">
			<PreferitiModal
				isModalOpen={isModalOpen}
				handleModalClose={handleModalClose}
				setIsModalOpen={setIsModalOpen}
				currentFavorite={favorite}
				squadra={squadra}
			/>
			<Row className="justify-content-center">
				<Col xs={12} sm={10} md={8} className="my-3 glass-card bg-transparent">
					<Card className="card-doc bg-doc banner mt-3">
						<Container>
							<Row>
								<Col xs={12} lg={5} className="">
									<div className="position-relative">
										<div className="giglio-pst tooltip-container" onClick={addToFavorites}>
											<button className="nav-link gold favotite">{strokeColor}</button>
											<div className="tooltip">Aggiungi ai preferiti</div>
										</div>
										<div className="insta-pst tooltip-container">
											<a href={squadra.href} target="_blank" rel="noreferrer" className="tel">
												<img className="invertedd insta-dtls" src="/assets/img/instagram_bold.webp" alt="Instagram" />
											</a>
											<div className="tooltip">Instagram {squadra.nome}</div>
										</div>

										<Card.Body className="mx-auto ms-lg-3 d-flex align-items-center justify-content-center flex-column">
											<Card.Title className="gold d-flex align-items-center justify-content-center flex-column mt-3">
												<span className="my-2 fs-2 text-center">{squadra.nome}</span>
												<div className="d-flex align-items-center justify-content-around gap-4">
													<span className="text-light">Girone {girone}</span>
													<span className="text-light">Posizione {posizione}</span>
												</div>
											</Card.Title>
											<div className="d-flex align-items-center justify-content-center">
												<img
													src={squadra.logo}
													alt={`Logo di ${squadra.nome}`}
													className="img-fluid mx-auto logo-dtls"
												/>
											</div>
											{squadra.presidente &&
											squadra.presidente.trim() !== "" &&
											squadra.allenatore &&
											squadra.allenatore.nome.trim() !== "" ? (
												squadra.presidente === squadra.allenatore.nome ? (
													<Card.Subtitle className="mb-2 text-light text-center mt-1 mb-2">
														Presidente e Allenatore: {squadra.presidente}
													</Card.Subtitle>
												) : (
													<>
														<Card.Subtitle className="mb-2 text-light text-center mt-1 mb-2">
															Presidente: {squadra.presidente}
														</Card.Subtitle>
														<Card.Subtitle className="mb-2 text-light text-center mt-1 mb-2">
															Allenatore: {squadra.allenatore.nome}
														</Card.Subtitle>
													</>
												)
											) : (
												<>
													{squadra.presidente && squadra.presidente.trim() !== "" && (
														<Card.Subtitle className="mb-2 text-light text-center mt-1 mb-2">
															Presidente: {squadra.presidente}
														</Card.Subtitle>
													)}
													{squadra.allenatore && squadra.allenatore.nome.trim() !== "" && (
														<Card.Subtitle className="mb-2 text-light text-center mt-1 mb-2">
															Allenatore: {squadra.allenatore.nome}
														</Card.Subtitle>
													)}
												</>
											)}
											<div id="clsf" className="negative-m">
												<div className="table-responsive">
													<Table striped bordered className="text-center clsf">
														<thead>
															<tr className="border-bottom">
																<th className="px-2">CG</th>
																<th className="px-2">CR</th>
																<th className="px-2">GF</th>
																<th className="px-2">GS</th>
																<th className="px-2">V</th>
																<th className="px-2">P </th>
																<th className="px-2">S</th>
																<th className="px-2">PT</th>
															</tr>
														</thead>
														<tbody>
															<tr className="text-light">
																<td>{cartelliniGialli}</td>
																<td>{cartelliniRossi}</td>
																<td>{goalFatti}</td>
																<td>{goalSubiti}</td>
																<td>{squadra.vittorie}</td>
																<td>{squadra.pareggi}</td>
																<td>{squadra.sconfitte}</td>
																<td>{punti}</td>
															</tr>
														</tbody>
													</Table>
												</div>
											</div>
										</Card.Body>
									</div>
								</Col>
								<Col xs={12} lg={7} className="col-img-squad">
									<Card.Body className="">
										<CardText className="gold text-center fs-5 mb-1">Giocatori</CardText>
										<Container>
											<Row>
												{squadra.giocatori ? (
													<ListGroup
														className={`list-group-flush text-center mb-2 ${
															squadra.giocatori.length > 4 ? "giocatori-list" : ""
														}`}
													>
														{squadra.giocatori.map((giocatore) => (
															<ListGroupItem
																key={giocatore.id}
																className="bg-transparent text-light border-0 d-flex align-items-center justify-content-around"
															>
																<Container>
																	<Row>
																		<Col
																			xs={12}
																			md={4}
																			lg={5}
																			xl={6}
																			className="d-flex align-items-center  justify-content-between my-1"
																		>
																			<span className="fw-bold name-details text-start">{giocatore.nome}</span>
																			<span>N°{giocatore.numero}</span>
																		</Col>
																		<Col xs={12} md={8} lg={7} xl={6} className="my-1">
																			<div id="clsf" className="negative-m">
																				<div className="table-responsive">
																					<Table striped bordered size="sm" className="clsf">
																						<thead>
																							<tr className="border-bottom">
																								<th className="px-2">GF</th>
																								<th className="px-2">AS</th>
																								<th className="px-2">CG</th>
																								<th className="px-2">CR</th>
																							</tr>
																						</thead>
																						<tbody>
																							<tr>
																								<td>{giocatore.goal}</td>
																								<td>{giocatore.assist}</td>
																								<td>{giocatore.cartelliniGialli}</td>
																								<td>{giocatore.cartelliniRossi}</td>
																							</tr>
																						</tbody>
																					</Table>
																				</div>
																			</div>
																		</Col>
																	</Row>
																</Container>
															</ListGroupItem>
														))}
													</ListGroup>
												) : (
													<p>Non ci sono giocatori disponibili.</p>
												)}
											</Row>
										</Container>
									</Card.Body>
								</Col>
							</Row>
						</Container>
					</Card>
					<TeamPerformanceChart giornate={giornate} teamId={id as string} logo={squadra.logo} />
					<div className="d-flex align-items-center justify-content-between mx-2 my-2">
						<NavLink to="/" className="nav-link tel gold text-center fs-5 mb-0">
							Home
						</NavLink>
						<NavLink to="/classifica" className="nav-link tel gold text-center fs-5 mb-0">
							Classifica
						</NavLink>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default DettagliSquadra;
