import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect, useState, lazy, Suspense } from "react";
import { useTranslationLoading } from "./components/Context/TranslationContext";
import { useTranslation } from "react-i18next";
import SEOHelmet from "./components/Context/SeoHelmet";
import Cookie from "./components/Coockie/Cookie";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Policy from "./components/Policy/Policy";
import MyOffcanvas from "./components/OffCanvas/MyOffCanvas";
import ToastManager from "./components/Toast/ToastManager";
import { Container, Row } from "react-bootstrap";
import Top from "./components/Script/Top";
import Home from "./components/Home/Home";
import DettagliSquadra from "./components/DettagliSquadre/DettagliSquadre";
import Loader from "./components/Loader/Loader";
import ReactModal from "react-modal";
import MobileNavbar from "./components/Script/MobileNavBar";
import { AnimatePresence, motion } from "framer-motion";
import { useAuth } from "./components/Context/AuthContext";
import { useLoading } from "./components/Context/LoadingContext"; // Importa useLoading
import Register from "./components/Admin/Register";
import TorneoPage from "./components/Finals/TorneoPage";
import PartitaFinale from "./components/Finals/PartitaFinale"; // Importa il componente PartitaFinale
import Finals from "./components/Admin/Finals";
import AuthLinks from "./components/Script/AuthLinks";
import UserRoute from "./components/Admin/routes/UserRoute";
import UserProfile from "./components/Admin/UserProfile";
import { isProduction } from "./components/Script/Utility";
import { giornate } from "./components/Interfaces/Interface";
import Maintenance from "./components/Script/Maintenance/Maintenance";

const NotFound = lazy(() => import("./components/NotFound"));
const General = lazy(() => import("./components/General/General"));
const Doc = lazy(() => import("./components/Doc/Doc"));
const Registrazione = lazy(() => import("./components/Doc/Registrazione"));
const Classifica = lazy(() => import("./components/Classifiche/Classifica"));
const Calendar = lazy(() => import("./components/Calendario/Calendar"));
const Merch = lazy(() => import("./components/Merch/Merch"));
const SquadCreator = lazy(() => import("./components/SquadCreator/SquadCreator"));
const Login = lazy(() => import("./components/Admin/Login"));
const AdminLayout = lazy(() => import("./components/Admin/AdminLayout"));
const Admin = lazy(() => import("./components/Admin/Admin"));
const Squadre = lazy(() => import("./components/Admin/Squadre"));
const Giornate = lazy(() => import("./components/Admin/Giornate"));
const ModificaGiornata = lazy(() => import("./components/Admin/ModificaGiornata"));
const GiornataForm = lazy(() => import("./components/Admin/GiornataForm"));
const SquadraDetail = lazy(() => import("./components/Admin/SquadraDetails"));

ReactModal.setAppElement("#root");

const PrivateRoute = ({ element }: { element: JSX.Element }) => {
	const { isAuthenticated, authLoading } = useAuth();

	if (authLoading) {
		return <Loader />;
	}

	return isAuthenticated ? element : <Navigate to="/login" />;
};

function App() {
	const isTranslationLoading = useTranslationLoading();
	const { ready } = useTranslation();
	const [showLoader, setShowLoader] = useState(true);
	const location = useLocation();
	const { authLoading, isAuthenticated } = useAuth();
	const { isLoading } = useLoading();
	const hasWinner = true;
	const pageVariants = {
		initial: {
			x: "100vh",
		},
		in: {
			x: 0,
			transition: { type: "tween", duration: 0.25 },
		},
		out: {
			x: "-100vh",
			transition: { type: "tween", duration: 0.25 },
		},
	};

	useEffect(() => {
		if (ready) {
			setTimeout(() => {
				setShowLoader(false);
			}, 100);
		}
	}, [ready]);

	const options = [
		{
			imgSrc: "/assets/img/soccer.webp",
			scroll: true,
			backdrop: true,
		},
	];

	return (
		<>
			<SEOHelmet />
			<ToastManager />
			<Cookie />
			<AuthLinks />
			<Container className="position-relative">
				{(showLoader || isLoading || authLoading) && !isTranslationLoading && <Loader />}
				<Row>
					<section className="main-bio-section">
						{!isTranslationLoading && !showLoader && !authLoading && <MobileNavbar />}
						{!isTranslationLoading &&
							!showLoader &&
							!authLoading &&
							options.map((option, index) => <MyOffcanvas key={index} {...option} />)}
						{!isTranslationLoading && !showLoader && !authLoading && <Header />}
						<Suspense fallback={<Loader />}>
							<AnimatePresence mode="wait">
								<Routes location={location} key={location.pathname}>
									<Route
										path="/"
										element={
											<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
												<Home />
											</motion.div>
										}
									/>
									{!isProduction && (
										<>
											<Route path="/profilo/:id" element={<UserRoute element={UserProfile} />} />
											<Route
												path="/login"
												element={
													<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
														<Login />
													</motion.div>
												}
											/>
											<Route
												path="/register"
												element={
													<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
														<Register />
													</motion.div>
												}
											/>
										</>
									)}
									{!isProduction && !authLoading && !isAuthenticated ? null : (
										<Route path="/admin" element={<PrivateRoute element={<AdminLayout />} />}>
											<Route index element={<Admin />} />
											<Route path="squadre" element={<Squadre />} />
											<Route path="squadra/edit/:id" element={<SquadraDetail />} />
											<Route path="giornate" element={<Giornate />} />
											<Route path="giornata/edit/:id" element={<ModificaGiornata />} />
											<Route path="giornata/new" element={<GiornataForm />} />
											<Route path="finals" element={<Finals />} />
										</Route>
									)}
									<Route
										path="/generale"
										element={
											<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
												<General />
											</motion.div>
										}
									/>
									<Route
										path="/doc"
										element={
											<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
												<Doc />
											</motion.div>
										}
									/>
									<Route
										path="/registrazione"
										element={
											<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
												<Registrazione />
											</motion.div>
										}
									/>
									<Route
										path="/calendario"
										element={
											<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
												<Calendar />
											</motion.div>
										}
									/>
									<Route
										path="/classifica"
										element={
											<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
												<Classifica />
											</motion.div>
										}
									/>
									<Route
										path="/policy"
										element={
											<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
												<Policy />
											</motion.div>
										}
									/>
									<Route
										path="/merch"
										element={
											<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
												<Merch />
											</motion.div>
										}
									/>
									<Route
										path="/dettagli/:id"
										element={
											<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
												<DettagliSquadra />
											</motion.div>
										}
									/>
									<Route
										path="/crea-squadra"
										element={
											<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
												{isProduction ? <Maintenance /> : <SquadCreator />}
											</motion.div>
										}
									/>
									<Route
										path="/finale"
										element={
											<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
												<TorneoPage />
											</motion.div>
										}
									/>
									<Route
										path="/partita-finale"
										element={
											<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
												<PartitaFinale id={"6650cb0a58912f7f801e983f"} hasWinner={hasWinner} /> {/* Passa l'ID qui */}
											</motion.div>
										}
									/>
									<Route
										path="*"
										element={
											<motion.div variants={pageVariants} initial="initial" animate="in" exit="out">
												<NotFound />
											</motion.div>
										}
									/>
								</Routes>
							</AnimatePresence>
						</Suspense>
						{!isTranslationLoading && !showLoader && !authLoading && <Footer />}
					</section>
				</Row>
				<Top />
			</Container>
		</>
	);
}

export default App;
