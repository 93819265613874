import React from 'react';

const StatsIcon = () => {
  return (
    <svg
      width="25px"
      height="25px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="invertedd my-1"
    >
      <defs>
        <style>
          {`.cls-1 {
                        fill: #101010;
                        fill-rule: evenodd;
                    }`}
        </style>
      </defs>
      <path
        id="stats3"
        className="cls-1"
        d="M1008,456a12,12,0,1,1,12-12A12,12,0,0,1,1008,456Zm0-22a10,10,0,1,0,10,10A10,10,0,0,0,1008,434Zm4,15a0.991,0.991,0,0,1-.99-1v-4.99a0.99,0.99,0,1,1,1.98,0V448A0.991,0.991,0,0,1,1012,449Zm-4,0a1,1,0,0,1-1-1v-8a1,1,0,0,1,2,0v8A1,1,0,0,1,1008,449Zm-4,0a0.991,0.991,0,0,1-.99-1v-2.989a0.99,0.99,0,1,1,1.98,0V448A0.991,0.991,0,0,1,1004,449Z"
        transform="translate(-996 -432)"
      />
    </svg>
  );
};

export default StatsIcon;
