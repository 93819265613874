import React, { useEffect, useLayoutEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { format, isWithinInterval } from "date-fns";
import { Evento, ISquadra, Partita as PartitaType } from "../Interfaces/Interface";
import ReactModal from "react-modal";
import ListaEventi from "./ListaEventi";

export interface PartitaProps {
	partita: PartitaType;
	squadre: ISquadra[];
	dataGiornata: string;
}

const Partita: React.FC<PartitaProps> = ({ partita, squadre, dataGiornata }) => {
	const [partitaIniziata, setPartitaIniziata] = useState(false);
	const [partitaFinita, setPartitaFinita] = useState(false);
	const [tempoCorrente, setTempoCorrente] = useState("");
	const navigate = useNavigate();
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [partitaEvents, setPartitaEvents] = useState<Evento[]>([]);
	const squadraCasa = squadre.find((s) => s.id === partita.squadraCasaId);
	const squadraOspite = squadre.find((s) => s.id === partita.squadraOspiteId);
	const [isMobile, setIsMobile] = useState(false);

	const acronimiSquadre: { [key: string]: string } = {
		"Fioristi Andriulo": "FSA",
		"It's Time To Cut": "ITC",
		Oraspot: "OSP",
		"Club dello Sport": "CDS",
		"New Team": "NTM",
		"Corona del Re": "CDR",
		"D.M. CAR": "DMC",
		"Pink Bianchini Squad": "PBS",
		"Vecchia Oria/Necktie": "VON",
		"Caveau F.C.": "CAV",
		"Akropolis-Pantheon": "AKR",
		"Privilege Bruno Auto": "PBA",
		"A.S.D. Città di Oria": "CDO",
		Albitour: "ALB",
		"Campionato di Sant'Antonio": "CSA",
	};

	const openModal = (eventi: Evento[]) => {
		setPartitaEvents(eventi);
		setModalIsOpen(true);
	};

	const handleCardClick = () => {
		openModal(partita.eventi ?? []);
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	useLayoutEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth <= 478);
		};

		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	useEffect(() => {
		const [day, month, year] = dataGiornata.split("/");
		const dataPartita = new Date(+year, +month - 1, +day);

		const oraInizioParts = partita.oraInizio.split(":");
		const oraInizioParsed = new Date(dataPartita.setHours(+oraInizioParts[0], +oraInizioParts[1], 0));

		const oraFineParts = partita.oraFine.split(":");
		const oraFineParsed = new Date(dataPartita.setHours(+oraFineParts[0], +oraFineParts[1], 0));

		const aggiornaTempo = () => {
			const oraAttuale = new Date();

			if (oraAttuale < oraInizioParsed) {
				setTempoCorrente(format(oraInizioParsed, "HH:mm"));
			} else if (
				isWithinInterval(oraAttuale, {
					start: oraInizioParsed,
					end: oraFineParsed,
				})
			) {
				setPartitaIniziata(true);
				setPartitaFinita(false);
				setTempoCorrente(format(oraAttuale, "HH:mm:ss"));
			} else {
				setPartitaIniziata(false);
				setPartitaFinita(true);
				setTempoCorrente("");
			}
		};

		const intervalId = setInterval(aggiornaTempo, 1000);
		aggiornaTempo();

		return () => clearInterval(intervalId);
	}, [partita.oraInizio, partita.oraFine, dataGiornata]);

	const handleNavigateCasaDett = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		if (partita.squadraCasaId === "0") {
			return;
		}
		navigate(`/dettagli/${partita.squadraCasaId}`);
	};

	const handleNavigateOspiteDett = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();
		if (partita.squadraOspiteId === "0") {
			return;
		}
		navigate(`/dettagli/${partita.squadraOspiteId}`);
	};

	const risultato = partita.risultato || "";

	const renderRisultato = (risultato: string | undefined): JSX.Element => {
		if (risultato && risultato.length > 3 && isMobile) {
			return <Card.Text className="small-font">{risultato}</Card.Text>;
		} else {
			return <Card.Text className="result-out">{risultato || null}</Card.Text>;
		}
	};

	return (
		<>
			<Card className="my-2 glass-card point hvr3 bg-transparent border-0 tooltip-container" onClick={handleCardClick}>
				<div className="d-flex justify-content-between align-items-center text-light card-with-background p-1 m-1 w-100">
					<Container>
						<Row className="-min h-100">
							<Col xs={4} className="d-flex flex-column align-item-center justify-content-center">
								<Card.Title className="text-center my-fs-out my-auto">
									{squadraCasa ? acronimiSquadre[squadraCasa.nome] : ""}
								</Card.Title>
								<img src={squadraCasa?.logo} alt={`Logo ${squadraCasa?.nome}`} className="logo-casa img-fluid mb-2" />
							</Col>

							<Col xs={3} className="text-center result-section mx-auto">
								{partitaIniziata ? (
									<>
										<Card.Text className="time time-golden">{tempoCorrente}</Card.Text>
										{renderRisultato(partita.risultato)}
									</>
								) : (
									<Card.Text className="time">{tempoCorrente}</Card.Text>
								)}
								{partitaFinita && renderRisultato(partita.risultato)}
							</Col>

							<Col xs={4} className="d-flex flex-column align-item-center justify-content-center">
								<Card.Title className="text-center my-fs-out my-auto">
									{squadraOspite ? acronimiSquadre[squadraOspite.nome] : ""}
								</Card.Title>
								<img
									src={squadraOspite?.logo}
									alt={`Logo ${squadraOspite?.nome}`}
									className="logo-ospite img-fluid mb-2"
								/>
							</Col>
						</Row>
					</Container>
				</div>
				<span className="tooltip-center glass-card">Higlights</span>
			</Card>
			<ReactModal
				id="evento"
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				contentLabel="Dettagli della Squadra"
				className="Modal-evento min-vw-90"
				overlayClassName="Overlay"
			>
				{partitaEvents && (
					<Card className="my-1 bg-glass glass-card event min-vh-90">
						<div className="d-flex flex-column justify-content-between align-items-center text-light card-with-background p-1 m-1">
							<Container fluid>
								<Row className="d-flex justify-content-center">
									<Col xs={4} className="me-auto ms-2">
										<p className="text-center title-clsf mt-1 mb-auto point" onClick={handleNavigateCasaDett}>
											{squadraCasa?.nome}
										</p>
									</Col>
									<Col xs={4} className="ms-auto me-2">
										<p className="text-center title-clsf mt-1 mb-auto point" onClick={handleNavigateOspiteDett}>
											{squadraOspite?.nome}
										</p>
									</Col>
								</Row>
								<Row className="w-100 mx-auto mt-2">
									<Col xs={4} className="d-flex justify-content-center">
										<img
											src={squadraCasa?.logo}
											alt={`Logo ${squadraCasa?.nome}`}
											className="logo-casa point mx-auto"
											onClick={handleNavigateCasaDett}
										/>
									</Col>
									<Col xs={4} className="text-center result-section mx-auto">
										{partitaIniziata ? (
											<>
												<Card.Text className="time time-golden">{tempoCorrente}</Card.Text>
											</>
										) : (
											<Card.Text className="time m-0 p-0">{tempoCorrente}</Card.Text>
										)}
										{partitaFinita && <Card.Text className="result">{partita.risultato || null}</Card.Text>}
									</Col>
									<Col xs={4} className="d-flex justify-content-center">
										<img
											src={squadraOspite?.logo}
											alt={`Logo ${squadraOspite?.nome}`}
											className="logo-ospite point mx-auto"
											onClick={handleNavigateOspiteDett}
										/>
									</Col>
								</Row>
								<Row className="w-100 mx-auto my-1">
									<Col xs={12} lg={10} className="mx-auto">
										{partitaEvents && squadraCasa && squadraOspite && (
											<ListaEventi
												eventi={partitaEvents}
												squadraCasaNome={squadraCasa?.nome}
												squadraOspiteNome={squadraOspite?.nome}
												squadraCasaLogo={squadraCasa?.logo}
												squadraOspiteLogo={squadraOspite?.logo}
											/>
										)}
									</Col>
								</Row>
							</Container>
						</div>
						<div className="d-flex align-items-center justify-content-center mt-auto mb-4">
							<button
								onClick={() => closeModal()}
								className="nav-link text-deco-none fs-5 bg-action text-light px-2 py-1 glass-card"
							>
								Chiudi
							</button>
						</div>
					</Card>
				)}
			</ReactModal>
		</>
	);
};

export default Partita;
