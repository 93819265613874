import React, { useState } from "react";
import { Card, CardBody } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { ISquadra, TorneoData } from "../Interfaces/Interface";

interface TeamCardProps {
	item: ISquadra | TorneoData;
}

function TeamCard({ item }: TeamCardProps) {
	const [mouseDownPosition, setMouseDownPosition] = useState<number | null>(null);
	const [mouseUpPosition, setMouseUpPosition] = useState<number | null>(null);

	const handleMouseDown = (e: React.MouseEvent) => {
		setMouseDownPosition(e.clientX);
	};

	const handleMouseUp = (e: React.MouseEvent) => {
		setMouseUpPosition(e.clientX);
	};

	const handleClick = (e: React.MouseEvent) => {
		if (mouseDownPosition !== null && mouseUpPosition !== null) {
			const distance = Math.abs(mouseDownPosition - mouseUpPosition);
			if (distance > 5) {
				// Se la distanza supera 5px, consideralo uno scorrimento
				e.preventDefault();
			}
		}
	};

	const isSquadra = (item: ISquadra | TorneoData): item is ISquadra => {
		return (item as ISquadra).giocatori !== undefined;
	};

	return (
		<div className="h-100" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
			<NavLink
				to={isSquadra(item) ? `/dettagli/${item.id}` : `/dettagli/${item.id}`}
				onClick={handleClick}
				className="text-deco-none"
			>
				<Card className="d-flex flex-column align-items-center justify-content-center glass-card hvr3 position-relative">
					<CardBody className="p-3 d-flex align-items-center justify-content-between flex-column">
						<div className="mb-auto">
							<img
								src={isSquadra(item) ? item.logo : item.logoSquadra}
								alt={`Logo di ${isSquadra(item) ? item.nome : item.nomeSquadra}`}
								className="img-fluid mb-auto logo-carosello"
							/>
						</div>
						<h3 className="mt-2 text-center h2-list">{isSquadra(item) ? item.nome : item.nomeSquadra}</h3>
					</CardBody>
				</Card>
			</NavLink>
		</div>
	);
}

export default TeamCard;
