import React from 'react';

const PinIcon = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 398.289 398.289"
      className=""
    >
      <g>
        <g>
          <path
            fill="CurrentColor"
            d="M199.144,0C118.186,0,52.323,65.868,52.323,146.821c0,79.085,136.48,239.391,142.286,246.169
			l4.535,5.299l4.529-5.293c5.812-6.784,142.292-167.084,142.292-246.169C345.959,65.868,280.097,0,199.144,0z M199.144,207.343
			c-33.367,0-60.516-27.149-60.516-60.522c0-33.367,27.149-60.516,60.516-60.516c33.373,0,60.522,27.149,60.522,60.516
			C259.666,180.194,232.517,207.343,199.144,207.343z"
          />
          <path
            fill="CurrentColor"
            d="M199.144,98.239c-26.791,0-48.582,21.791-48.582,48.582c0,26.791,21.791,48.588,48.582,48.588
			s48.588-21.791,48.588-48.588C247.732,120.036,225.936,98.239,199.144,98.239z"
          />
        </g>
      </g>
    </svg>
  );
};

export default PinIcon;
