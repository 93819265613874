import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import axiosInstance from "../Script/axiosConfig";
import { Card, Form, Button, Container, Row, Col } from "react-bootstrap";

const Register: React.FC = () => {
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		username: "",
		password: "",
		confirmPassword: "",
		phoneNumber: "",
	});
	const [error, setError] = useState("");
	const navigate = useNavigate();

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		if (formData.password !== formData.confirmPassword) {
			setError("Passwords do not match");
			return;
		}

		try {
			await axiosInstance.post("/api/register", formData);
			navigate("/login");
		} catch (err) {
			setError("Registration failed");
		}
	};

	return (
		<Container className="d-flex justify-content-center align-items-center vh-100">
			<Card className="p-4 glass-card" style={{ width: "100%", maxWidth: "600px" }}>
				<h2 className="text-center">Register</h2>
				<Form onSubmit={handleSubmit}>
					<Row>
						<Col md={6}>
							<Form.Group className="mb-3">
								<Form.Control
									type="text"
									placeholder="First Name"
									name="firstName"
									value={formData.firstName}
									onChange={handleChange}
									required
								/>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group className="mb-3">
								<Form.Control
									type="text"
									placeholder="Last Name"
									name="lastName"
									value={formData.lastName}
									onChange={handleChange}
									required
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<Form.Group className="mb-3">
								<Form.Control
									type="email"
									placeholder="Email"
									name="email"
									value={formData.email}
									onChange={handleChange}
									required
								/>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group className="mb-3">
								<Form.Control
									type="text"
									placeholder="Username"
									name="username"
									value={formData.username}
									onChange={handleChange}
									required
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row>
						<Col md={6}>
							<Form.Group className="mb-3">
								<Form.Control
									type="password"
									placeholder="Password"
									name="password"
									value={formData.password}
									onChange={handleChange}
									required
								/>
							</Form.Group>
						</Col>
						<Col md={6}>
							<Form.Group className="mb-3">
								<Form.Control
									type="password"
									placeholder="Confirm Password"
									name="confirmPassword"
									value={formData.confirmPassword}
									onChange={handleChange}
									required
								/>
							</Form.Group>
						</Col>
					</Row>
					<Form.Group className="mb-3">
						<Form.Control
							type="text"
							placeholder="Phone Number"
							name="phoneNumber"
							value={formData.phoneNumber}
							onChange={handleChange}
						/>
					</Form.Group>
					<Button variant="primary" type="submit" className="w-100 mb-3">
						Register
					</Button>
				</Form>
				{error && <p className="text-danger text-center mt-3">{error}</p>}
				<p className="text-center mt-3 text-light">
					Already have an account?{" "}
					<NavLink to="/login" className="gold text-deco-none">
						Login here
					</NavLink>
				</p>
			</Card>
		</Container>
	);
};

export default Register;
