import { toast, ToastOptions } from "react-hot-toast";
import { playSound } from "../Script/Utility";

const NotifyToast = {
	success: (message: string, soundEnabled: boolean, options?: ToastOptions) => {
		if (soundEnabled) {
			playSound("success", soundEnabled);
		}
		toast.success(message, options);
	},
	error: (message: string, soundEnabled: boolean, options?: ToastOptions) => {
		if (soundEnabled) {
			playSound("error", soundEnabled);
		}
		toast.error(message, options);
	},
};

export default NotifyToast;
