import { Giocatore, Giornata, ISquadra, Partita, Penalita, squadre } from "../Interfaces/Interface";
import Cookies from "js-cookie";
import squadreData from "../Script/squadre.json";
import axios from "./axiosConfig";

export const Url = "http://localhost:3000";
export const isProduction = process.env.REACT_APP_ENV === "production";

export function setupConsole() {
	if (isProduction) {
		// Disabilita la regola per le seguenti linee
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		console.log = () => {};
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		console.error = () => {};
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		console.warn = () => {};
	}
}

export const refreshToken = async (): Promise<void> => {
	try {
		const response = await axios.post(
			"/api/refresh-token",
			{},
			{
				withCredentials: true,
			}
		);
		if (response.status === 200) {
			console.log("Token refreshed successfully");
		}
	} catch (error) {
		console.error("Failed to refresh token", error);
		// Puoi aggiungere ulteriori logiche qui per gestire il fallimento del rinnovo del token
	}
};

export function parseDate(input: string): Date {
	const parts = input.split("/");
	// Nota: i mesi sono 0-based in JavaScript
	return new Date(parseInt(parts[2]), parseInt(parts[1]) - 1, parseInt(parts[0]));
}

export function calcolaPunti(squadra: ISquadra, penalita: Penalita[]): number {
	const puntiBase = squadra.vittorie * 3 + squadra.pareggi;
	const penalitaSquadra = penalita.find((p) => p.squadraId === squadra._id);
	const puntiPenalita = penalitaSquadra ? penalitaSquadra.penalita.reduce((acc, p) => acc + p, 0) : 0;
	return puntiBase - puntiPenalita;
}

const calcolaScontriDiretti = (squadraA: ISquadra, squadraB: ISquadra, giornate: Giornata[]): number => {
	let vittorieA = 0;
	let vittorieB = 0;
	let pareggi = 0;

	giornate.forEach((giornata) => {
		giornata.partite.forEach((partita) => {
			const isDirectMatch =
				(partita.squadraCasaId === squadraA.id && partita.squadraOspiteId === squadraB.id) ||
				(partita.squadraCasaId === squadraB.id && partita.squadraOspiteId === squadraA.id);

			console.log(partita.squadraCasaId, squadraA.id);
			console.log(partita.squadraOspiteId, squadraB.id);
			if (isDirectMatch) {
				const [golCasa, golOspite] = partita.risultato ? partita.risultato.split("-").map(Number) : [0, 0];

				console.log(
					`Partita trovata: ${partita.squadraCasaNome} (${partita.squadraCasaId}) vs ${partita.squadraOspiteNome} (${partita.squadraOspiteId})`
				);
				console.log(`Risultato: ${golCasa}-${golOspite}`);
				console.log(`Confrontando squadre: ${squadraA.nome} (${squadraA.id}) e ${squadraB.nome} (${squadraB._id})`);

				if (partita.squadraCasaId === squadraA.id && partita.squadraOspiteId === squadraB.id) {
					if (golCasa > golOspite) {
						vittorieA++;
					} else if (golCasa < golOspite) {
						vittorieB++;
					} else {
						pareggi++;
					}
				} else if (partita.squadraCasaId === squadraB.id && partita.squadraOspiteId === squadraA.id) {
					if (golCasa > golOspite) {
						vittorieB++;
					} else if (golCasa < golOspite) {
						vittorieA++;
					} else {
						pareggi++;
					}
				}

				console.log(
					`Aggiornamento scontri diretti: ${squadraA.nome} Vittorie: ${vittorieA}, ${squadraB.nome} Vittorie: ${vittorieB}, Pareggi: ${pareggi}`
				);
			}
		});
	});

	console.log(
		`Scontri diretti tra ${squadraA.nome} e ${squadraB.nome}: Vittorie ${squadraA.nome}: ${vittorieA}, Vittorie ${squadraB.nome}: ${vittorieB}, Pareggi: ${pareggi}`
	);

	if (vittorieA > vittorieB) return -1; // Squadra A ha vinto più scontri diretti
	if (vittorieB > vittorieA) return 1; // Squadra B ha vinto più scontri diretti
	return 0; // Parità negli scontri diretti
};

export const squadreOrdinate = (squadre: ISquadra[], penalita: Penalita[], giornate: Giornata[]): ISquadra[] => {
	return squadre.sort((a, b) => {
		// Calcola le statistiche delle due squadre
		const statsA = calcolaStatisticheSquadra(a);
		const statsB = calcolaStatisticheSquadra(b);

		console.log(`Statistiche ${a.nome}:`, statsA);
		console.log(`Statistiche ${b.nome}:`, statsB);

		// Calcola i punti delle due squadre includendo le penalità
		const puntiA = calcolaPunti(a, penalita);
		const puntiB = calcolaPunti(b, penalita);

		console.log(`Punti ${a.nome}: ${puntiA}, Punti ${b.nome}: ${puntiB}`);

		// Calcola la differenza reti dinamicamente
		const diffRetiA = statsA.goalFatti - statsA.goalSubiti;
		const diffRetiB = statsB.goalFatti - statsB.goalSubiti;

		console.log(`Differenza reti ${a.nome}: ${diffRetiA}, Differenza reti ${b.nome}: ${diffRetiB}`);

		// Confronta prima i punti
		if (puntiA !== puntiB) {
			return puntiB - puntiA;
		}

		// Se i punti sono uguali, confronta gli scontri diretti
		const scontriDiretti = calcolaScontriDiretti(a, b, giornate);
		if (scontriDiretti !== 0) {
			console.log(`Scontri diretti tra ${a.nome} e ${b.nome}:`, scontriDiretti);
			return scontriDiretti;
		}

		// Se la differenza reti è uguale, confronta i goal fatti
		if (diffRetiA !== diffRetiB) {
			return diffRetiB - diffRetiA;
		}

		// Se i goal fatti sono uguali, confronta le partite giocate
		if (statsA.goalFatti !== statsB.goalFatti) {
			return statsB.goalFatti - statsA.goalFatti;
		}

		return b.partiteGiocate - a.partiteGiocate;
	});
};

// Funzione per settare un cookie
export const setCookie = (name: string, value: string, days: number) => {
	Cookies.set(name, value, { expires: days, path: "/" });
};

// Funzione per ottenere un cookie
export const getCookie = (name: string): string | null => {
	// Ritorna il valore del cookie o null se non esiste
	return Cookies.get(name) || null;
};

// Funzione per ottenere un valore dal localStorage
export const getLocalStorage = (key: string): string | null => {
	try {
		const value = localStorage.getItem(key);
		return value ? value : null;
	} catch (error) {
		console.error("Errore durante il recupero dei dati dal localStorage:", error);
		return null;
	}
};

// Funzione per impostare un valore nel localStorage
export const setLocalStorage = (key: string, value: string, expirationDays: number | null = null): void => {
	try {
		if (expirationDays) {
			const expirationDate = new Date();
			expirationDate.setDate(expirationDate.getDate() + expirationDays);
			localStorage.setItem(key, value);
			localStorage.setItem(`${key}_expiration`, expirationDate.getTime().toString());
		} else {
			localStorage.setItem(key, value);
		}
	} catch (error) {
		console.error("Errore durante l'impostazione dei dati nel localStorage:", error);
	}
};
export const tuttiGiocatori = squadre.flatMap(
	(squadra) =>
		squadra.giocatori?.map((giocatore) => ({
			...(giocatore as Giocatore),
			logoSquadra: squadra.logo,
			squadra: squadra.nome,
			squadraId: squadra.id,
		})) || []
);

export const sortedPlayers = tuttiGiocatori.sort((a, b) => b.goal - a.goal);
export const giocatoriConStar = tuttiGiocatori.filter((giocatore) => giocatore.star && giocatore.star > 0);
// funzione per calcolare i migliori marcatori
export const topGoalScorers = tuttiGiocatori
	.sort((a, b) => b.goal - a.goal) // Ordina decrescente per goal
	.slice(0, 3); // Prende i primi tre

export const calcolaStatisticheSquadra = (squadra: ISquadra) => {
	let goalFattiGiocatori = 0;
	let cartelliniGialli = 0;
	let cartelliniRossi = 0;
	let goalSubiti = squadra.goalSubiti || 0;
	let goalFatti = squadra.goalFatti || 0; // Inizia con i goalFatti della squadra

	squadra.giocatori?.forEach((giocatore: Giocatore) => {
		goalFattiGiocatori += giocatore.goal;
		cartelliniGialli += giocatore.cartelliniGialli;
		cartelliniRossi += giocatore.cartelliniRossi;
		if (giocatore.ruolo === "Portiere") {
			goalSubiti += giocatore.goalSubiti || 0;
		}
	});

	goalFatti += goalFattiGiocatori; // Somma i goal fatti dai giocatori

	return { goalFatti, cartelliniGialli, cartelliniRossi, goalSubiti };
};

// export const squadreOrdinate = (squadre: ISquadra[], penalita: Penalita[], giornate: Giornata[]): ISquadra[] => {
// 	return squadre.sort((a, b) => {
// 		// Calcola le statistiche delle due squadre
// 		const statsA = calcolaStatisticheSquadra(a);
// 		const statsB = calcolaStatisticheSquadra(b);

// 		console.log(`Statistiche ${a.nome}:`, statsA);
// 		console.log(`Statistiche ${b.nome}:`, statsB);

// 		// Calcola i punti delle due squadre includendo le penalità
// 		const puntiA = calcolaPunti(a, penalita);
// 		const puntiB = calcolaPunti(b, penalita);

// 		console.log(`Punti ${a.nome}: ${puntiA}, Punti ${b.nome}: ${puntiB}`);

// 		// Calcola la differenza reti dinamicamente
// 		const diffRetiA = statsA.goalFatti - statsA.goalSubiti;
// 		const diffRetiB = statsB.goalFatti - statsB.goalSubiti;

// 		console.log(`Differenza reti ${a.nome}: ${diffRetiA}, Differenza reti ${b.nome}: ${diffRetiB}`);

// 		// Confronta prima i punti
// 		if (puntiA !== puntiB) {
// 			return puntiB - puntiA;
// 		}

// 		// Se i punti sono uguali, confronta gli scontri diretti
// 		const scontriDiretti = calcolaScontriDiretti(a, b, giornate);
// 		if (scontriDiretti !== 0) {
// 			console.log(`Scontri diretti tra ${a.nome} e ${b.nome}:`, scontriDiretti);
// 			return scontriDiretti;
// 		}

// 		// Se la differenza reti è uguale, confronta i goal fatti
// 		if (diffRetiA !== diffRetiB) {
// 			return diffRetiB - diffRetiA;
// 		}

// 		// Se i goal fatti sono uguali, confronta le partite giocate
// 		if (statsA.goalFatti !== statsB.goalFatti) {
// 			return statsB.goalFatti - statsA.goalFatti;
// 		}

// 		return b.partiteGiocate - a.partiteGiocate;
// 	});
// };

// Funzione per mescolare un array in modo casuale senza ripetizioni
export const shuffleArray = <T extends { _id: string }>(array: T[]): T[] => {
	const newArray: T[] = []; // Array per gli elementi mescolati
	const selectedSet: Set<string> = new Set(); // Set per tracciare gli elementi già selezionati

	// Algoritmo di Fisher-Yates
	for (let i = 0; i < array.length; i++) {
		const randomIndex = Math.floor(Math.random() * array.length); // Genera un indice casuale
		const selectedItem = array[randomIndex]; // Elemento selezionato casualmente

		// Verifica se l'elemento è già stato selezionato
		if (!selectedSet.has(selectedItem._id)) {
			newArray.push(selectedItem); // Aggiungi l'elemento selezionato all'array mescolato
			selectedSet.add(selectedItem._id); // Aggiungi l'id dell'elemento al set dei selezionati
		} else {
			i--; // Se l'elemento è già stato selezionato, ripeti l'iterazione
		}
	}

	return newArray; // Restituisce l'array mescolato
};

export const getStyle = (index: number) => {
	const style = {
		color: "white", // Colore di default
		fontWeight: "normal", // Peso del font di default
	};

	switch (index) {
		case 0:
			style.color = "#c0a868"; // oro
			style.fontWeight = "600";
			break;
		case 1:
			style.color = "#C0C0C0";
			break;
		case 2:
			style.color = "#cd7f32"; // bronzo
			break;
	}

	return style;
};

// ###################        GIRONI        ##################### //
export const gironeAIds = [1, 2, 4, 12, 8, 13, 9];
export const gironeBIds = [3, 5, 10, 11, 7, 6, 14];

export const gironeA = squadre.filter((squadra) => gironeAIds.includes(parseInt(squadra.id)));
export const gironeB = squadre.filter((squadra) => gironeBIds.includes(parseInt(squadra.id)));

// PlaySound per le notifiche

export const playSound = (type: "success" | "error", soundEnabled: boolean) => {
	if (!soundEnabled) return; // Non riprodurre il suono se è disabilitato

	let soundFile;
	if (type === "success") {
		soundFile = "/assets/sound/successNotify.mp3"; // Assicurati che il percorso sia corretto
	} else if (type === "error") {
		soundFile = "/assets/sound/errorNotify.mp3"; // Assicurati che il percorso sia corretto
	}

	const audio = new Audio(soundFile);
	audio.play().catch((error) => {
		console.error("Failed to play the audio file:", error);
	});
};

export const getResult = (partita: Partita, tipo: "casa" | "ospite", dataGiornata: string, oggi: Date): string => {
	const [golCasa, golOspite] = partita.risultato ? partita.risultato.split("-").map(Number) : [0, 0];

	const [day, month, year] = dataGiornata.split("/");
	const dataPartita = new Date(+year, +month - 1, +day);

	const oraInizioParts = partita.oraInizio.split(":");
	const oraInizioPartita = new Date(dataPartita.setHours(+oraInizioParts[0], +oraInizioParts[1], 0));

	const oraFineParts = partita.oraFine.split(":");
	const oraFinePartita = new Date(dataPartita.setHours(+oraFineParts[0], +oraFineParts[1], 0));

	if (oggi < oraInizioPartita) {
		return "InCorso"; // Partita non ancora iniziata
	}

	if (oggi < oraFinePartita) {
		return "InCorso"; // Partita non ancora terminata
	}

	if (tipo === "casa") {
		if (golCasa > golOspite) return "V";
		if (golCasa < golOspite) return "S";
		return "P";
	} else {
		if (golOspite > golCasa) return "V";
		if (golOspite < golCasa) return "S";
		return "P";
	}
};

export const calculateWinningStreak = (
	squadre: ISquadra[],
	giornate: Giornata[],
	startDate: Date,
	endDate: Date
): { [key: string]: string[] } => {
	const results: { [key: string]: string[] } = {};
	const today = new Date();

	const filteredGiornate = giornate.filter((giornata) => {
		const giornataDate = parseDate(giornata.data);
		return giornataDate >= startDate && giornataDate <= endDate && giornataDate <= today;
	});

	filteredGiornate
		.sort((a, b) => parseDate(a.data).getTime() - parseDate(b.data).getTime())
		.forEach((giornata) => {
			giornata.partite.forEach((partita: Partita) => {
				const resultCasa = getResult(partita, "casa", giornata.data, today);
				const resultOspite = getResult(partita, "ospite", giornata.data, today);

				if (!results[partita.squadraCasaId]) {
					results[partita.squadraCasaId] = [];
				}
				if (!results[partita.squadraOspiteId]) {
					results[partita.squadraOspiteId] = [];
				}

				results[partita.squadraCasaId].push(resultCasa);
				results[partita.squadraOspiteId].push(resultOspite);
			});
		});

	return results;
};

const squadreMap = squadreData.reduce(
	(map, squadra: ISquadra) => {
		map[squadra.id] = squadra.logo; // Usa id invece di _id
		return map;
	},
	{} as { [key: string]: string }
);

export { squadreMap };
