import { v4 as uuidv4 } from "uuid";
import squadreData from "../Script/squadre.json";
import giornateData from "../Script/giornate.json";
import penalitaData from "../Script/penalita.json";
// Interfacce
export interface Allenatore {
	id: string;
	nome: string;
}

export interface Giocatore {
	_id: string;
	id: string;
	nome: string;
	numero: number;
	goal: number;
	assist: number;
	cartelliniGialli: number;
	cartelliniRossi: number;
	squadraId: string;
	logoSquadra: string;
	squadra: string;
	ruolo: string;
	star: number;
	goalSubiti: number;
}

export type PlayerRole = "Portiere" | "Difensore" | "Centrocampista" | "Attaccante";

export interface ISquadra {
	_id: string;
	id: string;
	nome: string;
	logo: string;
	href?: string;
	giocatori?: Giocatore[];
	allenatore: Allenatore;
	goalSubiti: number;
	partiteGiocate: number;
	vittorie: number;
	pareggi: number;
	sconfitte: number;
	presidente?: string;
	goalFatti: number;
}

export interface Evento {
	id: string;
	tipo: string;
	minuto: number;
	giocatoreNome: string;
	giocatoreId: string;
	giocatoreNumero: number;
	squadraNome: string;
	colore?: string;
	_id: string;
}

export interface Partita {
	id: string;
	squadraCasaId: string;
	squadraCasaNome: string;
	squadraOspiteId: string;
	squadraOspiteNome: string;
	oraInizio: string;
	oraFine: string;
	risultato?: string;
	eventi?: Evento[];
	_id: string;
}

export interface Giornata {
	id: string;
	data: string;
	giorno: string;
	partite: Partita[];
	dataParsed?: Date;
	_id: string;
}

export interface Stats {
	goals: number;
	assists: number;
	yellowCards: number;
	redCards: number;
	stars: number;
}

export interface Formazione {
	players: Giocatore[];
	teamName: string;
	username: string;
	linkInstagram: string;
}

export interface TorneoData {
	_id: string;
	id: string;
	squadraId: string;
	nomeSquadra: string;
	logoSquadra: string;
	quartiDiFinale: boolean;
	semifinale: boolean;
	finalista: boolean;
	posizione: number;
}

export interface Penalita {
	_id: string;
	squadraId: string;
	nomeSquadra: string;
	penalita: number[];
}
export interface IUser {
	_id: string;
	username: string;
	password?: string; // Reso opzionale
	refreshToken: string;
	roles: IRole[]; // Array di oggetti IRole
	isTwoFactorEnabled: boolean;
	twoFactorSecret?: string;
	email: string;
	firstName: string;
	lastName: string;
	phoneNumber?: string; // Reso opzionale
	googleId?: string; // Campo opzionale per Google ID
	isGoogleUser?: boolean; // Campo opzionale per flag di utente Google
	googleProfileImage?: string; // Campo opzionale per l'immagine del profilo di Google
}

export interface IRole {
	_id: string;
	name: string;
}
// Exporta i dati delle penalità
export const penalita: Penalita[] = penalitaData.map((penalita: any) => ({
	_id: penalita._id,
	squadraId: penalita.squadraId._id, // Assumendo che squadraId sia un oggetto con un campo _id
	nomeSquadra: penalita.nomeSquadra,
	penalita: penalita.penalita,
}));
// Funzioni di caching
const CACHE_EXPIRATION_TIME = 1000 * 60 * 60; // 1 ora

const setCache = <T>(key: string, data: T) => {
	const item = {
		data,
		timestamp: new Date().getTime(),
	};
	localStorage.setItem(key, JSON.stringify(item));
};

const getCache = (key: string) => {
	const itemStr = localStorage.getItem(key);
	if (!itemStr) return null;

	const item = JSON.parse(itemStr);
	const now = new Date().getTime();
	if (now - item.timestamp > CACHE_EXPIRATION_TIME) {
		localStorage.removeItem(key);
		return null;
	}

	return item.data;
};

export const clearCache = (key: string) => {
	localStorage.removeItem(key);
};

// Funzioni di fetch
export const fetchSquadre = async (): Promise<ISquadra[]> => {
	try {
		const response = await fetch("/api/squadra");

		if (!response.ok) {
			throw new Error(`Error: ${response.status} ${response.statusText}`);
		}

		const data = await response.json();
		setCache("squadre", data);
		return data.map((squadra: ISquadra) => ({
			...squadra,
			giocatori: (squadra.giocatori || []).map((giocatore: Giocatore) => ({
				...giocatore,
				squadraId: squadra._id, // Usa _id invece di id
				logoSquadra: squadra.logo,
				squadra: squadra.nome,
				star: giocatore.star ?? 0,
				goalSubiti: giocatore.goalSubiti ?? 0,
				_id: giocatore._id || uuidv4(),
			})),
		}));
	} catch (err) {
		if (err instanceof Error) {
			console.error("Failed to fetch squadre:", err.message);
			throw err;
		} else {
			console.error("Failed to fetch squadre:", err);
			throw new Error("An unknown error occurred");
		}
	}
};

export const fetchGiornate = async (): Promise<Giornata[]> => {
	try {
		const response = await fetch("/api/giornate");

		if (!response.ok) {
			throw new Error(`Error: ${response.status} ${response.statusText}`);
		}

		const data = await response.json();
		setCache("giornate", data);
		return data.map((giornata: Giornata) => ({
			...giornata,
			id: giornata._id || uuidv4(),
			partite: giornata.partite.map((partita: Partita) => ({
				...partita,
				id: partita._id || uuidv4(),
				eventi:
					partita.eventi?.map((evento: Evento) => ({
						...evento,
						id: evento._id ?? uuidv4(),
					})) || [],
			})),
		}));
	} catch (err) {
		if (err instanceof Error) {
			console.error("Failed to fetch giornate:", err.message);
			throw err;
		} else {
			console.error("Failed to fetch giornate:", err);
			throw new Error("An unknown error occurred");
		}
	}
};

export const getSquadre = async (): Promise<ISquadra[]> => {
	let squadre = getCache("squadre");
	if (!squadre) {
		try {
			squadre = await fetchSquadre();
		} catch (err) {
			if (err instanceof Error) {
				console.error("Failed to get squadre:", err.message);
				throw err;
			} else {
				console.error("Failed to get squadre:", err);
				throw new Error("An unknown error occurred");
			}
		}
	}
	return squadre;
};

export const getGiornate = async (): Promise<Giornata[]> => {
	let giornate = getCache("giornate");
	if (!giornate) {
		try {
			giornate = await fetchGiornate();
		} catch (err) {
			if (err instanceof Error) {
				console.error("Failed to get giornate:", err.message);
				throw err;
			} else {
				console.error("Failed to get giornate:", err);
				throw new Error("An unknown error occurred");
			}
		}
	}
	return giornate;
};

export const squadre: ISquadra[] = squadreData.map((squadra): ISquadra => {
	return {
		...squadra,
		giocatori: (squadra.giocatori as Giocatore[]).map((giocatore): Giocatore => {
			return {
				...giocatore,
				squadraId: squadra._id, // Usa _id invece di id
				logoSquadra: squadra.logo,
				squadra: squadra.nome,
				star: giocatore.star ?? 0,
				goalSubiti: giocatore.goalSubiti ?? 0,
				_id: giocatore._id || uuidv4(),
			};
		}),
	};
});

export const giornate: Giornata[] = giornateData.map((giornata): Giornata => {
	return {
		...giornata,
		id: giornata._id || uuidv4(),
		partite: giornata.partite.map(
			(partita): Partita => ({
				...partita,
				id: partita._id || uuidv4(),
				eventi: partita.eventi.map(
					(evento): Evento => ({
						...evento,
						id: evento._id || uuidv4(),
					})
				),
			})
		),
	};
});

export const partite: Partita[] = giornate.flatMap((giornata) => giornata.partite);
