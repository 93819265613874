import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form, ButtonGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NotifyToast from "../Toast/NotifyToast";
import { useNotificationSound } from "../Context/NoticationSoundContext";
import { TorneoData } from "../Interfaces/Interface";
import axiosInstance from "../Script/axiosConfig";
import { fetchProtectedData } from "../Script/fetchProtectedData";
import { useAuth } from "../Context/AuthContext";

const Finals: React.FC = () => {
	const [torneoData, setTorneoData] = useState<TorneoData[]>([]);
	const [filteredData, setFilteredData] = useState<TorneoData[]>([]);
	const { soundEnabled } = useNotificationSound();
	const navigate = useNavigate();
	const [filter, setFilter] = useState<string>("all");
	const { token } = useAuth();

	// UseEffect per ottenere i dati del torneo
	useEffect(() => {
		const fetchTorneoData = async () => {
			try {
				const response = await axiosInstance.get("/api/torneo");
				if (response.status === 200) {
					const data = response.data;
					if (Array.isArray(data)) {
						setTorneoData(data);
						setFilteredData(data);
					} else {
						NotifyToast.error("Errore nel formato dei dati del torneo", soundEnabled);
					}
				} else {
					NotifyToast.error(`Errore nel recupero dei dati del torneo: ${response.statusText}`, soundEnabled);
				}
			} catch (error) {
				NotifyToast.error("Errore nel recupero dei dati del torneo", soundEnabled);
			}
		};
		fetchTorneoData();
	}, [soundEnabled]);

	// UseEffect per applicare il filtro
	useEffect(() => {
		applyFilter(filter);
	}, [filter, torneoData]);

	const applyFilter = (filter: string) => {
		if (filter === "all") {
			setFilteredData(torneoData);
		} else if (filter === "quarti") {
			setFilteredData(torneoData.filter((item) => item.quartiDiFinale));
		} else if (filter === "semifinale") {
			setFilteredData(torneoData.filter((item) => item.semifinale));
		} else if (filter === "finale") {
			setFilteredData(torneoData.filter((item) => item.finalista));
		}
	};

	const handleChange = (id: string, field: string, value: any) => {
		setTorneoData((prevState) => prevState.map((item) => (item._id === id ? { ...item, [field]: value } : item)));
	};

	const handleSave = async (id: string) => {
		const updatedData = torneoData.find((item) => item._id === id);
		try {
			const response = await fetchProtectedData(`/api/torneo/${id}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`, // Utilizza il token ottenuto dal contesto
				},
				data: updatedData,
			});
			NotifyToast.success("Dati del torneo aggiornati con successo", soundEnabled);
		} catch (error) {
			NotifyToast.error("Errore nell'aggiornamento dei dati del torneo", soundEnabled);
		}
	};

	return (
		<Container className="my-5">
			<ButtonGroup className="mb-4">
				<Button variant="secondary" onClick={() => setFilter("all")}>
					Tutte le Squadre
				</Button>
				<Button variant="secondary" onClick={() => setFilter("quarti")}>
					Quarti di Finale
				</Button>
				<Button variant="secondary" onClick={() => setFilter("semifinale")}>
					Semifinale
				</Button>
				<Button variant="secondary" onClick={() => setFilter("finale")}>
					Finale
				</Button>
			</ButtonGroup>
			<Row className="justify-content-center">
				{filteredData.map((item) => (
					<Col xs={12} sm={6} md={3} className="mb-4" key={item._id}>
						<Card>
							<Card.Body>
								<Card.Title>{item.nomeSquadra}</Card.Title>
								<Card.Img
									variant="top"
									src={item.logoSquadra}
									style={{ width: "50px", height: "50px", objectFit: "cover" }}
								/>
								<Form>
									<Row>
										<Col xs={6}>
											<Form.Group className="my-2">
												<Form.Label>Quarti di Finale</Form.Label>
												<Form.Check
													type="checkbox"
													checked={item.quartiDiFinale}
													onChange={(e) => handleChange(item._id, "quartiDiFinale", e.target.checked)}
												/>
											</Form.Group>
										</Col>
										<Col xs={6}>
											<Form.Group className="my-2">
												<Form.Label>Semifinale</Form.Label>
												<Form.Check
													type="checkbox"
													checked={item.semifinale}
													onChange={(e) => handleChange(item._id, "semifinale", e.target.checked)}
												/>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col xs={6}>
											<Form.Group className="my-2">
												<Form.Label>Finalista</Form.Label>
												<Form.Check
													type="checkbox"
													checked={item.finalista}
													onChange={(e) => handleChange(item._id, "finalista", e.target.checked)}
												/>
											</Form.Group>
										</Col>
										<Col xs={6}>
											<Form.Group className="my-2">
												<Form.Label>Posizione</Form.Label>
												<Form.Control
													type="number"
													value={item.posizione}
													onChange={(e) => handleChange(item._id, "posizione", parseInt(e.target.value))}
												/>
											</Form.Group>
										</Col>
									</Row>
									<Button variant="primary" onClick={() => handleSave(item._id)} className="mt-3">
										Salva Modifiche
									</Button>
								</Form>
							</Card.Body>
						</Card>
					</Col>
				))}
			</Row>
		</Container>
	);
};

export default Finals;
