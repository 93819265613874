import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import ReactModal from "react-modal";

interface AlertManagerProps {
	hasFormation: boolean;
	onClose: (alertType: "success" | "warning") => void;
}

const AlertManager = ({ hasFormation, onClose }: AlertManagerProps) => {
	const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
	const [showWarningAlert, setShowWarningAlert] = useState<boolean>(!hasFormation);
	const [isModalOpen, setIsModalOpen] = useState(false); // Stato per il modale
	const [isFirstVisit, setIsFirstVisit] = useState(false);
	const [canCloseModal, setCanCloseModal] = useState(false); // Stato per il bottone di chiusura del modale
	const [countdown, setCountdown] = useState(5); // Stato per il countdown

	useEffect(() => {
		// Controlla se l'utente ha già letto il regolamento
		const hasReadRegulation = localStorage.getItem("hasReadRegulation");
		if (!hasReadRegulation && !hasFormation) {
			// Se non ha letto, mostra il modale e imposta isFirstVisit a true
			setIsModalOpen(true);
			setIsFirstVisit(true);
			// Imposta un timer per abilitare il bottone di chiusura dopo 5 secondi
			const timerId = setInterval(() => {
				setCountdown((prevCountdown) => {
					if (prevCountdown > 1) {
						return prevCountdown - 1;
					} else {
						setCanCloseModal(true);
						clearInterval(timerId);
						return 0;
					}
				});
			}, 1000);
		} else {
			setCanCloseModal(true); // Se ha già letto il regolamento, abilita direttamente il bottone per chiudere
		}
	}, [hasFormation]);

	useEffect(() => {
		if (hasFormation) {
			setShowSuccessAlert(true);
			setShowWarningAlert(false);
		} else {
			setShowSuccessAlert(false);
			setShowWarningAlert(true);
		}
	}, [hasFormation]);

	const handleCloseAlert = (type: "success" | "warning") => {
		if (type === "success") {
			setShowSuccessAlert(false);
		} else if (type === "warning") {
			setShowWarningAlert(false);
		}
		onClose(type);
	};

	const handleOpenModal = () => {
		setIsModalOpen(true); // Apri il modale
	};

	const handleCloseModal = () => {
		if (canCloseModal) {
			setIsModalOpen(false);
			if (isFirstVisit) {
				// Se è la prima visita, imposta il flag nel localStorage
				localStorage.setItem("hasReadRegulation", "true");
				setIsFirstVisit(false);
			}
		}
	}; // Chiudi il modale

	return (
		<div>
			{/* Alert di successo */}
			{showSuccessAlert && (
				<Alert variant="success" className="fade-in-animation glass-card">
					<div className="d-flex flex-column align-items-center justify-content-center position-relative">
						<button className="btn btn-sm back exit-pst" onClick={() => handleCloseAlert("success")}>
							❌
						</button>
						<p className="p-0 m-0 text-center text-light">
							Squadra creata con <span className="fw-bold confirm">successo!</span> Segui il campionato fino alla
							<span className="text-underline"> fine</span> per scoprire se la tua squadra è la migliore!
						</p>
						<button onClick={handleOpenModal} className="nav-link gold tel text-underline">
							Leggi il regolamento
						</button>
					</div>
				</Alert>
			)}
			{/* Alert di avvertimento */}
			{showWarningAlert && (
				<Alert variant="warning" className="fade-in-animation glass-card">
					<div className="d-flex flex-column align-items-center justify-content-center position-relative">
						<button className="nav-link back exit-pst" onClick={() => handleCloseAlert("warning")}>
							❌
						</button>
						<p className="p-0 m-0 text-center text-light">
							<span className="fw-bold back fs-5">Attenzione!</span> Potrai creare una sola squadra valida per tutto il
							campionato.
						</p>
						<button onClick={handleOpenModal} className="nav-link text-danger tel text-underline">
							Leggi il regolamento
						</button>
					</div>
				</Alert>
			)}
			<ReactModal
				isOpen={isModalOpen}
				onRequestClose={handleCloseModal}
				contentLabel="Regolamento del Concorso"
				className="Modal-evento fanta-scroll"
				overlayClassName="Overlay"
			>
				<div className="position-relative">
					{canCloseModal ? (
						<button className="nav-link back exit-pst-modal" onClick={handleCloseModal}>
							❌
						</button>
					) : (
						<div id="countdown" className="exit-pst-modal">
							<div id="countdown-number">{countdown}</div>
							<svg className="circle">
								<circle r="18" cx="20" cy="20"></circle>
							</svg>
						</div>
					)}
					<h2 className="text-center my-fs">Regolamento Fant&apos;Antonio</h2>
					<ul className="fanta-list">
						<li className="text-light fs-5 my-3">
							Potrai creare una solo squadra a tuo nome e collegata al tuo profilo instagram principale. Piú squadre
							appartenenti allo stesso profilo instagram e nome saranno considerate nulle. Una volta creata la squadra
							non potrá essere modificata.
						</li>
						<li className="text-light fs-5 my-3">
							<span className="text-underline">Potrai partecipare solo con il</span>{" "}
							<span className="text-danger text-underline">tuo profilo personale</span>.
						</li>
						<li className="text-light fs-5 my-3">
							{`Hai tempo fino alla fine della prima settimana dall'inizio del campionato, (Domenica - 26/05/2024)`}
						</li>
						<li className="text-light fs-5 my-3">
							Una volta creata la squadra infine, bisognerá fare uno screen e una storia su instagram taggando la pagina
							ufficiale del campionato, senza la formazione non verrá ritenuta valida.
						</li>
						<li className="text-light fs-5 my-3">
							Per vincere bisogna avere la formazione migliore del campionato, che dovrá corrispondere a quella che
							verrá stipulata dallo staff alla fine del campionato.
						</li>
						<li className="text-light fs-5 my-3">
							Il premio finale sará la maglia ufficiale del merch del{" "}
							<a href="/merch" className="tel gold fs-6" target="_blank">
								Campionato di San Antonio
							</a>
							.
						</li>
						<li className="text-light fs-6 my-3">
							Ti sará comunque possibile creare una tua squadra personalizzata anche dopo la data necessaria alla
							partecipazione, ma non verrá presa in considerazione per il concorso.
						</li>
					</ul>
				</div>
			</ReactModal>
		</div>
	);
};

export default AlertManager;
