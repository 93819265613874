import React from "react";
import { TorneoData } from "../Interfaces/Interface";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface DeskFinalProps {
	teams: TorneoData[];
	filter: "semifinale" | "finalista" | "quartiDiFinale"; // Aggiunto il filtro come prop
}

const DeskFinal: React.FC<DeskFinalProps> = ({ teams, filter }) => {
	const filteredTeams = teams.filter((team) => team[filter]);
	const navigate = useNavigate();

	const getTitle = (filter: DeskFinalProps["filter"]): string => {
		switch (filter) {
			case "finalista":
				return "Finaliste";
			case "quartiDiFinale":
				return "Quarti di Finale";
			case "semifinale":
				return "Semifinaliste";
			default:
				return "";
		}
	};

	const handleDetails = (teamId: string) => {
		console.log("Dettagli squadra", teamId);
		// navigate(`/dettagli/${teamId}`);
		navigate(`/partita-finale`);
	};

	return (
		<Container className="my-1">
			{/* <h2 className="text-center mb-1">{getTitle(filter)}</h2> */}
			<h2 className="text-center mb-1">Vincitrice</h2>
			<Row className="justify-content-center">
				{filteredTeams.map(
					(team) =>
						team.posizione === 1 && (
							<Col key={team.id} xs={6} sm={4} md={3} lg={2} className="text-center">
								<Card className="bg-transparent border-0 h-100 card-scale-effect">
									<Card.Body
										className="d-flex align-items-center justify-content-center flex-column point"
										onClick={() => handleDetails(team.id)}
									>
										<Card.Img variant="top" src={team.logoSquadra} alt={team.nomeSquadra} className="drop-shadow" />
										<Card.Title className="gold mt-auto my-fs">{team.nomeSquadra}</Card.Title>
									</Card.Body>
								</Card>
							</Col>
						)
				)}
			</Row>
		</Container>
	);
};

export default DeskFinal;
