import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import UltimaGiornata from "./UltimaGiornata";
import { Link, NavLink } from "react-router-dom";
import TeamList from "./TeamList";
import Promo from "./Promo";
import DriveIcon from "../svg/DriveIcon";
import { squadre, TorneoData } from "../Interfaces/Interface";
import DeskFinal from "./deskFinal";
import torneoData from "../Script/torneo.json";

const Home = () => {
	return (
		<Container className="fade-in-animation d-flex flex-column gap-2">
			<div className="d-flex align-items-center justify-content-center">
				<h1 className="text-center fw-bold my-4 fs-2 glass-card bg-transparent px-2 py-1">HOME</h1>
			</div>
			<Row className="justify-content-center">
				<Col xs={12} xl={10} className="mx-auto">
					<DeskFinal teams={torneoData as TorneoData[]} filter="finalista" />
				</Col>
			</Row>
			<Row className="d-flex align-items-center justify-content-center">
				<Col xs={12} xl={10} className="mx-auto">
					<Row className="d-flex align-items-center justify-content-center">
						<Col xs={12} xl={10} className="mx-auto">
							<div className="">
								<Row>
									<Col className="d-flex align-items-center justify-content-center flex-column tooltip-container order-1 order-md-1">
										<NavLink
											to="/calendario"
											className="nav-link text-center glass-card bg-transparent point px-2 py-1 hvr2"
										>
											<span className="link-hvr">
												<img
													src="/assets/img/soccer-calendar.webp"
													alt="Immagine di un calendario con un pallone da calcio, se cliccato porta alla pagina del calendario completo del compleanno"
													className="invertedd"
													height={70}
												/>
											</span>
										</NavLink>
										<div className="tooltip-right px-1">Calendario</div>
									</Col>
									{/* <Col xs={12} md={4} className="my-3 mx-1 order-3 order-md-2">
										<div className="d-flex align-items-center justify-content-center">
											<NavLink
												to="/partita-finale"
												className="nav-link gold tel fs-3 text-deco-none glass-card px-4 py-1 hvr2"
											>
												Vai alla finale
											</NavLink>
										</div>
									</Col> */}
									<Col className="d-flex align-items-center justify-content-center flex-column tooltip-container order-2 order-md-3">
										<Link
											to="https://drive.google.com/drive/folders/1A4hfm8OzDWPck9Xrl4QxTznCd0TaGeOO?usp=share_link"
											target="_blank"
											className="nav-link text-center glass-card bg-transparent point px-2 py-1 hvr2"
										>
											<DriveIcon />
										</Link>
										<div className="tooltip-right px-1">Galleria</div>
									</Col>
								</Row>
							</div>
							<UltimaGiornata />
						</Col>
					</Row>
				</Col>
			</Row>
			<Container>
				<Row className=" mx-0 mb-0 p-0">
					<Col xs={12} sm={9} md={8} lg={9} xl={10} className="mx-auto">
						<TeamList items={squadre} />{" "}
					</Col>
				</Row>
			</Container>
			<Row>
				<Col xs={12} xl={10} className="mx-auto tooltip-container">
					<Card className="mb-3 glass-card hvr3">
						<NavLink to="/crea-squadra" className="nav-link tel gold text-deco-none">
							<Card.Body className="text-center">
								<Card.Text className="text-light">
									Crea la <span className="text-underline">tua squadra personalizzata</span> con i giocatori del
									campionato e <br /> prova a vincere anche tu la maglia del Campionato di San Antonio!
								</Card.Text>
								<img
									src="/assets/img/fantantonio.webp"
									alt="Logo FantaAntonio, concorso gratis con premio finale"
									className="img-fluid"
								/>
								<span className="tooltip-right">Fantantonio</span>
							</Card.Body>
						</NavLink>
					</Card>
				</Col>
			</Row>
			<Row className="d-flex align-items-center justify-content-center">
				<Col xs={12} xl={10} className="mx-auto">
					<Promo />
				</Col>
			</Row>
		</Container>
	);
};

export default Home;
