import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import axiosInstance, { refreshToken as axiosRefreshToken } from "../Script/axiosConfig";
import { IUser } from "../Interfaces/Interface";
import { fetchProtectedData } from "../Script/fetchProtectedData";
import { useLoading } from "./LoadingContext";

interface AuthContextType {
	token: string | null;
	isAuthenticated: boolean;
	user: IUser | null;
	authLoading: boolean;
	login: (token: string, refreshToken: string, user: IUser) => void;
	logout: () => void;
	refreshToken: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [token, setToken] = useState<string | null>(null);
	const [user, setUser] = useState<IUser | null>(null);
	const [authLoading, setAuthLoading] = useState<boolean>(true);
	const { startLoading, stopLoading } = useLoading();

	const checkToken = async () => {
		startLoading();
		setAuthLoading(true);
		try {
			const response = await axiosInstance.get("/api/check-token");
			const { token } = response.data;

			if (token) {
				axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
				const userResponse = await fetchProtectedData("/api/me");
				setToken(userResponse.token);
				setUser(userResponse.user);
				axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${userResponse.token}`;
			} else {
				throw new Error("Token not found in response");
			}
		} catch (error) {
			console.error("Error during token check:", error);
			await refreshToken();
		} finally {
			stopLoading();
			setAuthLoading(false);
		}
	};

	useEffect(() => {
		checkToken();
	}, []);

	const login = (token: string, refreshToken: string, user: IUser) => {
		setToken(token);
		setUser(user);
		axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		setAuthLoading(false);
	};

	const logout = async () => {
		startLoading();
		setAuthLoading(true);
		try {
			await axiosInstance.post("/api/logout");
			setToken(null);
			setUser(null);
			delete axiosInstance.defaults.headers.common["Authorization"];
		} catch (error) {
			console.error("Error during logout:", error);
		} finally {
			stopLoading();
			setAuthLoading(false);
		}
	};

	const refreshToken = async (): Promise<void> => {
		startLoading();
		setAuthLoading(true);
		try {
			await axiosRefreshToken();
			const newTokenResponse = await axiosInstance.get("/api/check-token");
			const newToken = newTokenResponse.data.token;
			if (newToken) {
				const response = await fetchProtectedData("/api/me");
				setToken(newToken);
				setUser(response.user);
				axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${newToken}`;
			} else {
				throw new Error("Token not found after refresh");
			}
		} catch (error) {
			console.error("Failed to refresh token", error);
			logout();
		} finally {
			stopLoading();
			setAuthLoading(false);
		}
	};

	return (
		<AuthContext.Provider
			value={{
				token,
				isAuthenticated: !!token,
				user,
				authLoading,
				login,
				logout,
				refreshToken,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = (): AuthContextType => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
