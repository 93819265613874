import "./i18n";
import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { CookieConsentProvider } from "./components/Context/CoockieContext";
import { FavoriteProvider } from "./components/Context/FavoriteContext";
import { HelmetProvider } from "./components/Context/HelmetContext";
import { TranslationProvider } from "./components/Context/TranslationContext";
import { LoadingProvider } from "./components/Context/LoadingContext";
import { OffcanvasProvider } from "./components/Context/OffCanvasContenxt";
import { BrowserRouter } from "react-router-dom";
import { NotificationSoundProvider } from "./components/Context/NoticationSoundContext";
import { AuthProvider } from "./components/Context/AuthContext";
import { setupConsole } from "./components/Script/Utility";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");

const root = createRoot(rootElement);
setupConsole();

// Avvolgi il componente App con tutti i providers necessari
root.render(
	<LoadingProvider>
		<AuthProvider>
			<TranslationProvider>
				<CookieConsentProvider>
					<HelmetProvider>
						<NotificationSoundProvider>
							<OffcanvasProvider>
								<FavoriteProvider>
									<BrowserRouter>
										<App />
									</BrowserRouter>
								</FavoriteProvider>
							</OffcanvasProvider>
						</NotificationSoundProvider>
					</HelmetProvider>
				</CookieConsentProvider>
			</TranslationProvider>
		</AuthProvider>
	</LoadingProvider>
);
