// src/Context/NotificationSoundContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import NotifyToast from '../Toast/NotifyToast';

interface NotificationSoundContextProps {
  soundEnabled: boolean;
  toggleSound: () => void;
}

const NotificationSoundContext = createContext<
  NotificationSoundContextProps | undefined
>(undefined);

const STORAGE_KEY = 'soundEnabled';
const STORAGE_TIMESTAMP_KEY = 'soundEnabledTimestamp';
const EXPIRY_DAYS = 90;
const MILLISECONDS_IN_A_DAY = 24 * 60 * 60 * 1000;

const isExpired = (timestamp: number) => {
  return Date.now() - timestamp > EXPIRY_DAYS * MILLISECONDS_IN_A_DAY;
};

export const NotificationSoundProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [soundEnabled, setSoundEnabled] = useState(true);

  useEffect(() => {
    const savedSoundEnabled = localStorage.getItem(STORAGE_KEY);
    const savedTimestamp = localStorage.getItem(STORAGE_TIMESTAMP_KEY);

    if (savedSoundEnabled !== null && savedTimestamp !== null) {
      const timestamp = JSON.parse(savedTimestamp);

      if (!isExpired(timestamp)) {
        setSoundEnabled(JSON.parse(savedSoundEnabled));
      } else {
        localStorage.removeItem(STORAGE_KEY);
        localStorage.removeItem(STORAGE_TIMESTAMP_KEY);
      }
    }
  }, []);

  const toggleSound = () => {
    setSoundEnabled(prev => {
      const newSoundEnabled = !prev;
      localStorage.setItem(STORAGE_KEY, JSON.stringify(newSoundEnabled));
      localStorage.setItem(STORAGE_TIMESTAMP_KEY, JSON.stringify(Date.now()));

      // Mostra il toast con il nuovo valore di soundEnabled
      if (newSoundEnabled) {
        NotifyToast.success('Suono notifiche abilitato', newSoundEnabled);
      } else {
        NotifyToast.error('Suono notifiche disabilitato', newSoundEnabled);
      }

      return newSoundEnabled;
    });
  };

  return (
    <NotificationSoundContext.Provider value={{ soundEnabled, toggleSound }}>
      {children}
    </NotificationSoundContext.Provider>
  );
};

export const useNotificationSound = () => {
  const context = useContext(NotificationSoundContext);
  if (!context) {
    throw new Error(
      'useNotificationSound must be used within a NotificationSoundProvider'
    );
  }
  return context;
};
