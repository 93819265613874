import axios from "axios";
import Cookies from "js-cookie";

// // const axiosInstance = axios.create({
// // 	baseURL: "http://localhost:3000",
// // 	withCredentials: true,
// // });
// const axiosInstance = axios.create({
// 	baseURL: "http://192.168.1.57:5000", // Aggiornato per riflettere l'URL del server backend
// 	withCredentials: true,
// });
// Determina l'URL base del backend in base all'host del frontend
const getBaseURL = () => {
	if (window.location.hostname === "localhost") {
		return "http://localhost:5000";
	} else if (window.location.hostname === "192.168.1.57") {
		return "http://192.168.1.57:5000";
	} else {
		return "https://campionatosanantonio.com";
	}
};

const axiosInstance = axios.create({
	baseURL: getBaseURL(),
	withCredentials: true,
});

// Interceptor per aggiungere il token CSRF alle richieste
axiosInstance.interceptors.request.use((config) => {
	const csrfToken = Cookies.get("XSRF-TOKEN");
	if (csrfToken) {
		config.headers["X-CSRF-Token"] = csrfToken;
	}
	return config;
});

// Interceptor per aggiungere il token di autenticazione alle richieste
axiosInstance.interceptors.request.use((config) => {
	const token = Cookies.get("token");
	if (token) {
		config.headers["Authorization"] = `Bearer ${token}`;
	}
	console.log("Request config:", config);
	return config;
});

// Interceptor per gestire le risposte e i token di autenticazione
axiosInstance.interceptors.response.use(
	(response) => {
		console.log("Response data:", response.data);
		return response;
	},
	async (error) => {
		const originalRequest = error.config;
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true;
			try {
				await refreshToken();
				const newToken = Cookies.get("token");
				if (newToken) {
					originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
					return axiosInstance(originalRequest);
				}
			} catch (refreshError) {
				console.error("Token refresh failed", refreshError);
				return Promise.reject(refreshError);
			}
		}
		return Promise.reject(error);
	}
);

const refreshToken = async (): Promise<void> => {
	try {
		const response = await axios.post(
			"/api/refresh-token",
			{},
			{
				withCredentials: true,
			}
		);
		if (response.status === 200) {
			const data = response.data;
			Cookies.set("token", data.token, { expires: 1, sameSite: "Strict", secure: false });
			Cookies.set("refreshToken", data.refreshToken, { expires: 7, sameSite: "Strict", secure: false });
			axios.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
			console.log("Token refreshed successfully");
		}
	} catch (error) {
		console.error("Failed to refresh token", error);
		// Puoi aggiungere ulteriori logiche qui per gestire il fallimento del rinnovo del token
	}
};

export default axiosInstance;
export { refreshToken };
