// components/Loader.tsx
import React from 'react';
import GiglioIcon from '../svg/GiglioIcon';

const Loader: React.FC = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0)',
        zIndex: 9999,
      }}
    >
      <GiglioIcon />
    </div>
  );
};

export default Loader;
