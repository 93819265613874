import React, { createContext, useContext, useState, ReactNode } from "react";

type MetaTagsState = {
	charset: string;
	title: string;
	viewport: string;
	description: string;
	keywords: string;
	"og:site_name": string;
	"og:image": string;
	"msapplication-TileColor": string;
	"msapplication-TileImage": string;
	"theme-color": string;
	canonical: string;
	"content-security-policy": string;
	"x-dns-prefetch-control": string;
	"strict-transport-security": string;
	"x-content-type-options": string;
	"og:url": string;
	"twitter:title": string;
	"twitter:description": string;
	"twitter:image": string;
	"twitter:card": string;
	"twitter:url": string;
	favicon: string;
	"apple-touch-icon": string;
	"android-chrome-192x192": string;
	"android-chrome-512x512": string;
};

type HelmetContextType = {
	state: MetaTagsState;
	setState: React.Dispatch<React.SetStateAction<MetaTagsState>>;
};

// Creazione del Context con un valore di default che corrisponde alla struttura dello stato che userai
export const HelmetContext = createContext<HelmetContextType>({
	// I valori di default che riflettono la struttura dei tuoi metadati
	state: {
		charset: "UTF-8",
		title: "Campionato di Calcio San Antonio - Oria (BR)",
		viewport: "width=device-width, initial-scale=1, shrink-to-fit=no",
		description:
			"Il campionato sportivo di calcio a 7 (6+1) amatoriale con oltre 40 anni di storia, tradizione, passione.",
		"og:site_name": "Campionato di San Antonio",
		keywords:
			"calcio, campionato, San Antonio, Oria, sport, amatoriale, torneo, squadre, competizione, divertimento, eventi sportivi, partite, giocatori, associazione sportiva, Fantantonio",
		"msapplication-TileColor": "#ffffff",
		"msapplication-TileImage": "https://www.campionatosanantonio.it/assets/img/logo.webp",
		"theme-color": "#ffffff",
		"og:image": "https://www.campionatosanantonio.it/assets/img/logo.webp",
		canonical: "https://www.campionatosanantonio.it",
		"content-security-policy":
			"default-src 'self'; script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self' 'unsafe-inline';ws://localhost:3000",
		"x-dns-prefetch-control": "off",
		"strict-transport-security": "max-age=31536000; includeSubDomains",
		"x-content-type-options": "nosniff",
		"og:url": "https://www.campionatosanantonio.it",
		"twitter:title": "Campionato di Calcio San Antonio - Oria (BR)",
		"twitter:description":
			"Il campionato sportivo di calcio a 7 (6+1) amatoriale con oltre 40 anni di storia, tradizione, passione.",
		"twitter:image": "https://www.campionatosanantonio.it/assets/img/logo.webp",
		"twitter:card": "summary_large_image",
		"twitter:url": "https://www.campionatosanantonio.it",
		favicon: "https://www.campionatosanantonio.it/assets/img/logo-noback.webp",
		"apple-touch-icon": "https://www.campionatosanantonio.it/assets/img/logo.webp",
		"android-chrome-192x192": "https://www.campionatosanantonio.it/assets/img/logo.webp",
		"android-chrome-512x512": "https://www.campionatosanantonio.it/assets/img/logo.webp",
	},
	setState: () => {
		console.warn("setState is not implemented");
	}, // Intentionally left blank
});

// Tipizza i props del tuo provider
type HelmetProviderProps = {
	children: ReactNode; // ReactNode include qualsiasi cosa renderizzabile in React, come JSX, stringhe, numeri, ecc.
};

// Provider del context che conterrà la logica per aggiornare i metadati
export const HelmetProvider: React.FC<HelmetProviderProps> = ({ children }) => {
	const [state, setState] = useState<MetaTagsState>({
		charset: "UTF-8",
		title: "Campionato di Calcio San Antonio - Oria (BR)",
		viewport: "width=device-width, initial-scale=1, shrink-to-fit=no",
		description:
			"Il campionato sportivo di calcio a 7 (6+1) amatoriale con oltre 40 anni di storia, tradizione, passione.",
		"og:site_name": "Campionato di San Antonio",
		keywords:
			"calcio, campionato, San Antonio, Oria, sport, amatoriale, torneo, squadre, competizione, divertimento, eventi sportivi, partite, giocatori, associazione sportiva, Fantantonio",
		"msapplication-TileColor": "#ffffff",
		"msapplication-TileImage": "https://www.campionatosanantonio.it/assets/img/logo.webp",
		"theme-color": "#ffffff",
		"og:image": "https://www.campionatosanantonio.it/assets/img/logo.webp",
		canonical: "https://www.campionatosanantonio.it",
		"content-security-policy":
			"default-src 'self'; script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self' 'unsafe-inline';ws://localhost:3000",
		"x-dns-prefetch-control": "off",
		"strict-transport-security": "max-age=31536000; includeSubDomains",
		"x-content-type-options": "nosniff",
		"og:url": "https://www.campionatosanantonio.it",
		"twitter:title": "Campionato di Calcio San Antonio - Oria (BR)",
		"twitter:description":
			"Il campionato sportivo di calcio a 7 (6+1) amatoriale con oltre 40 anni di storia, tradizione, passione.",
		"twitter:image": "https://www.campionatosanantonio.it/assets/img/logo.webp",
		"twitter:card": "summary_large_image", // cambia questo in base al tipo di Twitter card che preferisci
		"twitter:url": "https://www.campionatosanantonio.it",
		favicon: "https://www.campionatosanantonio.it/assets/img/logo-noback.webp",
		"apple-touch-icon": "https://www.campionatosanantonio.it/assets/img/logo.webp",
		"android-chrome-192x192": "https://www.campionatosanantonio.it/assets/img/logo.webp",
		"android-chrome-512x512": "https://www.campionatosanantonio.it/assets/img/logo.webp",
	});

	return <HelmetContext.Provider value={{ state, setState }}>{children}</HelmetContext.Provider>;
};

// Hook personalizzato
export const useHelmet = () => useContext(HelmetContext);
