import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Giornata as GiornataType, Partita as PartitaType, ISquadra } from "../Interfaces/Interface";
import Partita from "../Calendario/Partita";
import { isWithinInterval, format } from "date-fns";
import { it } from "date-fns/locale"; // Importa la locale italiana
import { ChevronUp, ChevronDown } from "react-bootstrap-icons"; // Icone per l'espansione e il collasso

interface TournamentBracketProps {
	giornate: GiornataType[];
	squadre: ISquadra[];
}

const TournamentBracket: React.FC<TournamentBracketProps> = ({ giornate, squadre }) => {
	const [statiFasi, setStatiFasi] = useState<{ [key: string]: string }>({});
	const [expandedGiornate, setExpandedGiornate] = useState<{ [key: string]: boolean }>({});

	const parseTime = (time: string) => {
		const [hours, minutes] = time.split(":").map(Number);
		return { hours, minutes };
	};

	const sortPartiteByTime = (partite: PartitaType[]) => {
		return partite.sort((a, b) => {
			const { hours: hoursA, minutes: minutesA } = parseTime(a.oraInizio);
			const { hours: hoursB, minutes: minutesB } = parseTime(b.oraInizio);
			if (hoursA !== hoursB) return hoursA - hoursB;
			return minutesA - minutesB;
		});
	};

	const renderPartite = (partite: PartitaType[], dataGiornata: string, fase: string, isExpanded: boolean) => {
		const sortedPartite = sortPartiteByTime(partite);
		return sortedPartite.map((partita, index) => (
			<Col xs={12} className="my-3" key={partita._id}>
				{index === 0 || isExpanded ? <Partita partita={partita} squadre={squadre} dataGiornata={dataGiornata} /> : null}
			</Col>
		));
	};

	const aggiornaStatoFase = (giornate: GiornataType[]): string => {
		const oraAttuale = new Date();

		if (giornate.length === 0) return "Nessuna partita";

		const primaPartita = giornate[0].partite[0];
		const ultimaPartita = giornate[giornate.length - 1].partite[giornate[giornate.length - 1].partite.length - 1];

		const [day, month, year] = giornate[0].data.split("/");
		const dataGiornata = new Date(+year, +month - 1, +day);

		const oraInizioParts = primaPartita.oraInizio.split(":");
		const oraInizioPrimaPartita = new Date(dataGiornata.setHours(+oraInizioParts[0], +oraInizioParts[1], 0));

		const oraFineParts = ultimaPartita.oraFine.split(":");
		const oraFineUltimaPartita = new Date(dataGiornata.setHours(+oraFineParts[0], +oraFineParts[1], 0));

		if (oraAttuale < oraInizioPrimaPartita) {
			return "Da giocare";
		} else if (
			isWithinInterval(oraAttuale, {
				start: oraInizioPrimaPartita,
				end: oraFineUltimaPartita,
			})
		) {
			return "In corso";
		} else {
			return "Giocate";
		}
	};

	const fasi = [
		{
			fase: "Quarti di Finale Andata",
			giornate: giornate.filter((g) => g.giorno.includes("Quarti di Finale") && !g.giorno.includes("Ritorno")),
		},
		{
			fase: "Quarti di Finale Ritorno",
			giornate: giornate.filter((g) => g.giorno.includes("Quarti di Finale") && g.giorno.includes("Ritorno")),
		},
		{
			fase: "Semifinali Andata",
			giornate: giornate.filter((g) => g.giorno.includes("Semifinale") && !g.giorno.includes("Ritorno")),
		},
		{
			fase: "Semifinali Ritorno",
			giornate: giornate.filter((g) => g.giorno.includes("Semifinale") && g.giorno.includes("Ritorno")),
		},
		{
			fase: "Finale",
			giornate: giornate.filter((g) => g.data === "19/07/2024" && g.giorno.includes("Finale")),
		},
	];

	useEffect(() => {
		const stati = fasi.reduce(
			(acc, { fase, giornate }) => {
				acc[fase] = aggiornaStatoFase(giornate);
				return acc;
			},
			{} as { [key: string]: string }
		);

		setStatiFasi(stati);

		const interval = setInterval(() => {
			const nuoviStati = fasi.reduce(
				(acc, { fase, giornate }) => {
					acc[fase] = aggiornaStatoFase(giornate);
					return acc;
				},
				{} as { [key: string]: string }
			);

			setStatiFasi(nuoviStati);
		}, 60000); // Aggiorna ogni minuto

		return () => clearInterval(interval); // Pulisce l'intervallo al dismount del componente
	}, [giornate]);

	const groupByDate = (giornate: GiornataType[]) => {
		return giornate.reduce(
			(acc, giornata) => {
				const formattedDate = format(new Date(giornata.data.split("/").reverse().join("-")), "eeee dd/MM/yyyy", {
					locale: it,
				});
				if (!acc[formattedDate]) {
					acc[formattedDate] = [];
				}
				acc[formattedDate].push(giornata);
				return acc;
			},
			{} as { [key: string]: GiornataType[] }
		);
	};

	const toggleExpand = (date: string) => {
		setExpandedGiornate((prev) => ({
			...prev,
			[date]: !prev[date],
		}));
	};

	return (
		<Container className="tournament-bracket">
			{fasi.map(({ fase, giornate }) => (
				<Row className="justify-content-between mt-5 glass-card bg-transparent" key={fase}>
					<Col xs={12} className="p-3 bg-transparent">
						<h3 className="text-center">{fase}</h3>
						{Object.entries(groupByDate(giornate)).map(([data, giornatePerData]) => (
							<div key={data}>
								<div className="d-flex align-items-center justify-content-center bg-transparent">
									<h3
										className="text-center glass-card px-3 py-2 bg-transparent point"
										onClick={() => toggleExpand(data)}
									>
										{data}{" "}
										{expandedGiornate[data] ? <ChevronUp className="point" size={20} /> : <ChevronDown size={20} />}
									</h3>
								</div>
								<div className={`collapse-content ${expandedGiornate[data] ? "expanded" : ""}`}>
									{giornatePerData.map((giornata) =>
										renderPartite(giornata.partite, giornata.data, fase, expandedGiornate[data])
									)}
								</div>
							</div>
						))}
						<div className="d-flex align-items-center justify-content-center">
							<span className="fs-4 gold mx-auto">{statiFasi[fase]}</span>
						</div>
					</Col>
				</Row>
			))}
		</Container>
	);
};

export default TournamentBracket;
