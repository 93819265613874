import React from "react";
import { Card, CardText, Col } from "react-bootstrap";
import { Giocatore } from "../Interfaces/Interface";
import { PlayerRole, getRoleAbbreviation, splitPlayerName } from "./SquadCreator";

// Definisci i tipi per le props
interface SelectedPlayerSummaryProps {
	selectedPlayers: Giocatore[];
	handleRemovePlayer: (id: string) => void;
}

const roleOrder: PlayerRole[] = ["Portiere", "Difensore", "Centrocampista", "Attaccante"];

const SelectedPlayerSummary: React.FC<SelectedPlayerSummaryProps> = ({ selectedPlayers, handleRemovePlayer }) => {
	// Ordina i giocatori secondo l'ordine dei ruoli definito
	const sortedPlayers = selectedPlayers.sort((a, b) => {
		// Se il ruolo del giocatore non è presente, assegna un valore alto per posizionarlo in fondo
		const indexA =
			a.ruolo && roleOrder.includes(a.ruolo as PlayerRole)
				? roleOrder.indexOf(a.ruolo as PlayerRole)
				: Number.MAX_SAFE_INTEGER;
		const indexB =
			b.ruolo && roleOrder.includes(b.ruolo as PlayerRole)
				? roleOrder.indexOf(b.ruolo as PlayerRole)
				: Number.MAX_SAFE_INTEGER;
		return indexA - indexB;
	});
	return (
		<>
			{sortedPlayers.map((player, index) => (
				<Col xs={6} lg={4} key={index} className="squad-hvr my-3" onClick={() => handleRemovePlayer(player.id)}>
					<Card className="mt-2 point position-relative h-100 mb-1 glass-card hvr3 gap-3">
						<div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mb-3 w-100">
							<CardText className="text-center text-light p-0 my-0 mx-auto my-fs-fanta">
								{getRoleAbbreviation(player.ruolo as PlayerRole)}
							</CardText>
							<img
								src={player.logoSquadra}
								alt={`Logo squadra ${player.squadra}`}
								className="logo-squad-create mx-auto mx-lg-0 ms-lg-auto me-lg-1"
							/>
						</div>
						<div className="text-center mt-3">
							<CardText className="player-name mt-3 mb-0 p-0 my-fs-player player-name">
								{splitPlayerName(player.nome)}
								<span className="ms-md-2 player-number">{player.numero}</span>
							</CardText>
						</div>
					</Card>
				</Col>
			))}
		</>
	);
};

export default SelectedPlayerSummary;
