import React, { createContext, useContext, useState, ReactNode } from 'react';

// Definisci il tipo per il contesto
interface OffcanvasContextType {
  show: boolean;
  toggleShow: () => void;
  handleClose: () => void;
  handleOpen: () => void;
}

// Crea il contesto con il tipo appropriato, ma inizialmente con `null`
const OffcanvasContext = createContext<OffcanvasContextType | null>(null);

// Custom hook per utilizzare il contesto
export const useOffcanvas = () => {
  const context = useContext(OffcanvasContext);
  if (!context) {
    throw new Error(
      "useOffcanvas deve essere utilizzato all'interno di un OffcanvasProvider"
    );
  }
  return context;
};

// Definisci il tipo per le props del provider
interface OffcanvasProviderProps {
  children: ReactNode;
}

// Implementa l'OffcanvasProvider con tipi espliciti
export const OffcanvasProvider: React.FC<OffcanvasProviderProps> = ({
  children,
}) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => setShow(prev => !prev);
  const handleClose = () => setShow(false);
  const handleOpen = () => setShow(true);

  return (
    <OffcanvasContext.Provider
      value={{ show, toggleShow, handleClose, handleOpen }}
    >
      {children}
    </OffcanvasContext.Provider>
  );
};
