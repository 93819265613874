import React from 'react';

const OldManIcon = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 61.166 61.166"
      className="icon-lang"
      fill="CurrentColor"
    >
      <g>
        <g>
          <circle cx="33.248" cy="9.517" r="5.414" />
          <path
            d="M43.963,26.359c-0.377-2.683-0.467-5.407-1.194-8.031c-0.968-3.489-6.011-2.408-6.02,0.74
c-0.064-0.073-0.137-0.139-0.201-0.212c-0.511-0.584-1.087-0.879-1.664-0.965c-1.062-1.996-3.236-3.276-5.635-3.083
c-0.587,0.048-1.146,0.183-1.668,0.388c-2.143-4.074-3.583-8.396-4.448-12.981c-0.734-3.88-6.635-2.232-5.906,1.628
c1.156,6.125,3.348,11.963,6.667,17.248c0,0.006,0,0.012,0,0.019c0.291,3.55-0.073,9.405-0.298,12.145
c-0.104,0.164-0.204,0.334-0.284,0.525v-0.001c-3.191,7.591-4.416,16.28-5.038,24.442c-0.298,3.941,5.829,3.912,6.126,0
c0.458-6.008,1.343-12.271,3.144-18.128c0.39,0.127,0.798,0.216,1.223,0.259c0.505,6.146,0.929,12.297,1.805,18.405
c0.562,3.896,6.462,2.231,5.908-1.63c-0.916-6.381-1.229-12.84-1.791-19.256c-0.021-0.24-0.063-0.465-0.125-0.676
c0.326-0.643,0.541-1.355,0.611-2.117c0.023-0.248,0.385-4.199,0.489-8.504c1.05,0.928,2.124,1.832,3.179,2.763
C41.298,31.504,44.382,29.342,43.963,26.359z"
          />
        </g>
      </g>
    </svg>
  );
};
export default OldManIcon;
