import React, { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLoading } from "../Context/LoadingContext";
import { useOffcanvas } from "../Context/OffCanvasContenxt";
import { useFavorite } from "../Context/FavoriteContext";
import { Formazione, squadre } from "../Interfaces/Interface";
import { getLocalStorage } from "../Script/Utility";

type OffCanvasProps = {
	imgSrc: string;
	scroll: boolean;
	backdrop: boolean;
};

function MyOffcanvas({ imgSrc, ...props }: OffCanvasProps) {
	const { show, handleClose } = useOffcanvas();
	const navigate = useNavigate();
	const { t, ready } = useTranslation("offCanvas");
	const { startLoading, stopLoading } = useLoading();
	const { favorite } = useFavorite();
	const [favoriteTeamLogo, setFavoriteTeamLogo] = useState<string>("");
	const [formazione, setFormazione] = useState<Formazione | null>(null);
	useEffect(() => {
		const preferitiData = getLocalStorage("formazione");
		if (preferitiData) {
			setFormazione(JSON.parse(preferitiData));
		}
	}, [show]);

	useEffect(() => {
		if (!ready) {
			startLoading();
		} else {
			stopLoading();
		}
		// Trova il team preferito ogni volta che il preferito cambia
		const favoriteTeam = squadre.find((team) => team.id === favorite);
		setFavoriteTeamLogo(favoriteTeam ? favoriteTeam.logo : "/assets/img/logo-lettering-new.webp");
	}, [ready, startLoading, stopLoading, favorite]);

	const handleNavigateToFavorites = () => {
		if (favorite) {
			navigate(`/dettagli/${favorite}`);
			handleClose();
		}
	};

	return (
		<>
			<Offcanvas show={show} onHide={handleClose} {...props} className="custom-offcanvas-bg glass-card rounded-0">
				<Offcanvas.Header>
					<Offcanvas.Title className="mx-auto">
						<img
							src={favoriteTeamLogo || "/assets/img/logo-lettering-new.webp"}
							alt={favorite ? "Logo squadra preferita" : "Logo lettering del campionato San Antonio"}
							className="img-title-canvas point"
							onClick={
								favorite
									? handleNavigateToFavorites
									: () => {
											navigate("/");
											handleClose();
										}
							}
						/>
					</Offcanvas.Title>
				</Offcanvas.Header>

				<Offcanvas.Body>
					<Container fluid className="d-flex flex-column align-items-center justify-content-between h-100">
						<Row className="my-2">
							<NavLink to={"/merch"} className="nav-link" onClick={() => handleClose()}>
								<Col xs={12} className="hvr d-flex align-items-center justify-content-start">
									<img
										src="/assets/img/shirt.webp"
										alt={t("calendario")}
										className="img-canvas-icon-maglia inverted me-2 mb-2"
									/>
									<span className="fs-4"> {t("merch")}</span>
								</Col>
							</NavLink>
							<NavLink to={"/doc"} className="nav-link d-none d-md-block" onClick={() => handleClose()}>
								<Col xs={12} className="hvr d-flex align-items-center justify-content-start">
									<img src="/assets/img/doc.webp" alt={t("generale")} className="img-canvas-icon inverted me-1 mb-2" />
									<span className="fs-4"> {t("generale")}</span>
								</Col>
							</NavLink>
						</Row>
						<Row className="mt-auto">
							<NavLink to={"/crea-squadra"} className="nav-link my-3" onClick={() => handleClose()}>
								<Col xs={12} className="d-flex align-items-center justify-content-start fanta-hvr">
									{formazione?.teamName ? (
										<div className="d-flex align-items-center justify-content-center flex-column">
											<span className="fs-5 text-light">{formazione.teamName}</span>
											<img
												src="/assets/img/fantantonio.webp"
												alt="Logo Fantantonio, concorso a premio, partecipazione gratuita"
												className="img-fluid fantaIcon-canvas"
												title="Crea la tua squadra con i giocatori del Campionato"
											/>
										</div>
									) : (
										<div className="d-flex align-items-center justify-content-center">
											<img
												src="/assets/img/fantantonio.webp"
												alt="Logo Fantantonio, concorso a premio, partecipazione gratuita"
												className="img-fluid fantaIcon-canvas"
												title="Crea la tua squadra con i giocatori del Campionato"
											/>
										</div>
									)}
								</Col>
							</NavLink>
							<Col xs={12} className="bg-dc">
								<Link
									to="https://sitravelnetwork.it/"
									className="nav-link"
									onClick={() => handleClose()}
									target="_blank"
									title="Sito ufficiale SiTravel, Main Sponsor del Campionato di San Antonio"
								>
									<Card className="bg-doc banner p-3 hvr3 rounded-5 bg-transparent">
										<h4 className="text-center m-0 p-0 fw-semibold fs-6 px-2 py-1">MAIN SPONSOR</h4>
										<img
											src="/assets/img/sitravel.webp"
											alt="SiTravel Logo, Main Sponsor del Campionato di San Antonio"
											className="img-fluid major"
										/>
									</Card>
								</Link>
							</Col>
						</Row>
					</Container>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
}

export default MyOffcanvas;
