import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import torneoData from "../Script/torneo.json";
import giornateData from "../Script/giornate.json";
import squadreData from "../Script/squadre.json";
import { TorneoData, Giornata, ISquadra } from "../Interfaces/Interface";
import TeamList from "../Home/TeamList";
import TournamentBracket from "./TournamentBracket";
import { useHelmet } from "../Context/HelmetContext";
import { useTranslation } from "react-i18next";
import { useLoading } from "../Context/LoadingContext";

const TorneoPage: React.FC = () => {
	const [filteredTorneoData, setFilteredTorneoData] = useState<TorneoData[]>([]);
	const [filteredGiornate, setFilteredGiornate] = useState<Giornata[]>([]);
	const [squadre, setSquadre] = useState<ISquadra[]>(squadreData);

	const { setState } = useHelmet();
	const { t, ready } = useTranslation("finale");
	const { startLoading, stopLoading } = useLoading();

	useEffect(() => {
		if (!ready) {
			startLoading();
		} else {
			stopLoading();
		}
	}, [ready, startLoading, stopLoading]);

	useEffect(() => {
		// Imposta il titolo SEO e la descrizione per la pagina Registrazione
		setState((prevState) => ({
			...prevState,
			title: t("seo.title"),
			description: t("seo.description"),
		}));
		return () => {
			setState((prevState) => ({
				...prevState,
				title: prevState.title,
				description: prevState.description,
			}));
		};
	}, [setState, t]);

	useEffect(() => {
		const filteredTorneo = torneoData
			.filter((team) => team.semifinale)
			.map((team) => ({
				...team,
				id: team.id || team._id, // Assicurati che il campo id sia presente
			}));
		setFilteredTorneoData(filteredTorneo);

		const filteredGiornate = giornateData
			.filter((giornata) => {
				const [day, month, year] = giornata.data.split("/");
				const giornataDate = new Date(`${year}-${month}-${day}`);
				return giornataDate >= new Date("2024-06-27") && giornataDate <= new Date("2024-07-19");
			})
			.map((giornata) => ({
				...giornata,
				partite: giornata.partite.map((partita) => ({
					...partita,
					id: partita._id, // Assicurati che id sia presente
					eventi: partita.eventi.map((evento) => ({
						...evento,
						id: evento._id, // Assicurati che id sia presente
					})),
				})),
			}));

		setFilteredGiornate(filteredGiornate);
	}, []);

	return (
		<Container className="my-5">
			<Row className="justify-content-center mb-4">
				<Col xs={12} sm={9} md={8} lg={9} xl={10} className="mx-auto">
					<TeamList items={filteredTorneoData} />
				</Col>
			</Row>
			<Row className="justify-content-center">
				<Col xs={12}>
					<TournamentBracket giornate={filteredGiornate} squadre={squadre} />
				</Col>
			</Row>
		</Container>
	);
};

export default TorneoPage;
