import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";

interface UserRouteProps {
	element: React.ComponentType;
}

const UserRoute: React.FC<UserRouteProps> = ({ element: Component }) => {
	const { isAuthenticated } = useAuth();
	const location = useLocation();

	if (!isAuthenticated) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return <Component />;
};

export default UserRoute;
