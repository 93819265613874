import React, { useEffect, useState } from "react";
import { Giocatore, ISquadra, Stats } from "../Interfaces/Interface";
import { Card, CardText, Container, Row, Col, Badge } from "react-bootstrap";
import Loader from "../Loader/Loader";
import ModalStatistiche from "./ModalStatistiche";
import StatsIcon from "../svg/StatsIcon";
import { PlayerRole, getRoleAbbreviation, splitPlayerName } from "./SquadCreator";
import { useAuth } from "../Context/AuthContext";
import axiosInstance from "../Script/axiosConfig";

interface Props {
	players: Giocatore[];
}

const SquadraPersonalizzata: React.FC<Props> = ({ players: initialPlayers }) => {
	const [players, setPlayers] = useState<Giocatore[]>(initialPlayers);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [username, setUsername] = useState("");
	const [teamName, setTeamName] = useState("");
	const [stats, setStats] = useState<Stats>({
		goals: 0,
		assists: 0,
		yellowCards: 0,
		redCards: 0,
		stars: 0,
	});
	const { user } = useAuth();

	useEffect(() => {
		const fetchFormazione = async () => {
			try {
				if (user && user._id) {
					const response = await axiosInstance.get(`/api/formazione?userId=${user._id}`);
					const formazione = response.data;
					setPlayers(formazione.players);
					setUsername(formazione.username);
					setTeamName(formazione.teamName);
					setStats(calculateStats(formazione.players));
				}
			} catch (error) {
				console.error("Errore nel recupero della formazione:", error);
			}
		};

		fetchFormazione();
	}, [user]);

	const handleOpenModal = () => {
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const calculateStats = (players: Giocatore[]): Stats => {
		return players.reduce(
			(acc: Stats, player: Giocatore) => ({
				goals: acc.goals + (player.goal || 0),
				assists: acc.assists + (player.assist || 0),
				yellowCards: acc.yellowCards + (player.cartelliniGialli || 0),
				redCards: acc.redCards + (player.cartelliniRossi || 0),
				stars: acc.stars + (player.star || 0),
			}),
			{ goals: 0, assists: 0, yellowCards: 0, redCards: 0, stars: 0 }
		);
	};

	if (!stats) {
		return <Loader />;
	}

	const goalkeepers = players.filter((player) => player.ruolo === "Portiere");
	const defenders = players.filter((player) => player.ruolo === "Difensore");
	const midfielders = players.filter((player) => player.ruolo === "Centrocampista");
	const attackers = players.filter((player) => player.ruolo === "Attaccante");

	const playersInfo = players.map((player) => ({
		_id: player._id,
		id: player.id,
		nome: player.nome,
		squadra: player.squadra,
	}));

	return (
		<Container>
			<div className="bg-campo px-2 px-md-5 pt-1 postion-relative rounded-5">
				<Badge className="pst-coach text-black glass-card bg-transparent">{`Allenatore: ${username}`}</Badge>
				<div className="semicircle"></div>
				<div className="semicircle-bottom"></div>
				<button onClick={handleOpenModal} className="nav-link stast-pst">
					<StatsIcon />
				</button>
				<Row className="d-flex align-items-center justify-content-center mb-3 mb-lg-5 right-entry team-entry ">
					<Col xs={5} md={4} lg={3}>
						{goalkeepers.map((player, index) => (
							<Card
								key={player.nome + "-" + index}
								className="mt-2 point position-relative h-100 mb-1 glass-card hvr3 gap-3"
							>
								<div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mb-3 w-100">
									<CardText className="text-center p-0 my-0 mx-auto my-fs-fanta player-role">
										{getRoleAbbreviation(player.ruolo as PlayerRole)}
									</CardText>
									<img
										src={player.logoSquadra}
										alt={`Logo squadra ${player.squadra}`}
										className="logo-squad-create mx-auto mx-lg-0 ms-lg-auto me-lg-1"
									/>
								</div>
								<div className="text-center mt-3">
									<CardText className="player-name mt-3 mb-0 p-0 my-fs player-name">
										{splitPlayerName(player.nome)}
										<span className="ms-md-2 player-number-fanta">{player.numero}</span>
									</CardText>
								</div>
							</Card>
						))}
					</Col>
				</Row>
				<Row className="d-flex align-items-center justify-content-around mt-3 mb-3 mt-lg-5 mb-lg-5">
					<Col xs={5} md={4} lg={3} className="right-entry team-entry ">
						{defenders.slice(0, 1).map((player, index) => (
							<Card
								key={player.nome + "-" + index}
								className="mt-2 point position-relative h-100 mb-1 glass-card hvr3 gap-3"
							>
								<div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mb-3 w-100">
									<CardText className="text-center p-0 my-0 mx-auto my-fs-fanta">
										{getRoleAbbreviation(player.ruolo as PlayerRole)}
									</CardText>
									<img
										src={player.logoSquadra}
										alt={`Logo squadra ${player.squadra}`}
										className="logo-squad-create mx-auto mx-lg-0 ms-lg-auto me-lg-1"
									/>
								</div>
								<div className="text-center mt-3">
									<CardText className="player-name mt-3 mb-0 p-0 my-fs player-name">
										{splitPlayerName(player.nome)}
										<span className="ms-md-2 player-number-fanta">{player.numero}</span>
									</CardText>
								</div>
							</Card>
						))}
					</Col>
					<Col xs={5} md={4} lg={3} className="left-entry team-entry ">
						{defenders.slice(1).map((player, index) => (
							<Card
								key={player.nome + "-" + index}
								className="mt-2 point position-relative h-100 mb-1 glass-card hvr3 gap-3"
							>
								<div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mb-3 w-100">
									<CardText className="text-center p-0 my-0 mx-auto my-fs-fanta">
										{getRoleAbbreviation(player.ruolo as PlayerRole)}
									</CardText>
									<img
										src={player.logoSquadra}
										alt={`Logo squadra ${player.squadra}`}
										className="logo-squad-create mx-auto mx-lg-0 ms-lg-auto me-lg-1"
									/>
								</div>
								<div className="text-center mt-3">
									<CardText className="player-name mt-3 mb-0 p-0 my-fs player-name">
										{splitPlayerName(player.nome)}
										<span className="ms-md-2 player-number-fanta">{player.numero}</span>
									</CardText>
								</div>
							</Card>
						))}
					</Col>
				</Row>
				<div className="space"></div>
				<Row className="d-flex align-items-center justify-content-between mt-3 mb-3 mt-lg-5 mb-lg-5">
					<Col xs={5} md={4} lg={3} className="right-entry team-entry ">
						{midfielders.slice(1).map((player, index) => (
							<Card
								key={player.nome + "-" + index}
								className="mt-2 point position-relative h-100 mb-1 glass-card hvr3 gap-3"
							>
								<div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mb-3 w-100">
									<CardText className="text-center p-0 my-0 mx-auto my-fs-fanta">
										{getRoleAbbreviation(player.ruolo as PlayerRole)}
									</CardText>
									<img
										src={player.logoSquadra}
										alt={`Logo squadra ${player.squadra}`}
										className="logo-squad-create mx-auto mx-lg-0 ms-lg-auto me-lg-1"
									/>
								</div>
								<div className="text-center mt-3">
									<CardText className="player-name mt-3 mb-0 p-0 my-fs player-name">
										{splitPlayerName(player.nome)}
										<span className="ms-md-2 player-number-fanta">{player.numero}</span>
									</CardText>
								</div>
							</Card>
						))}
					</Col>
					<Col xs={5} md={4} lg={3} className="left-entry team-entry ">
						{midfielders.slice(0, 1).map((player, index) => (
							<Card
								key={player.nome + "-" + index}
								className="mt-2 point position-relative h-100 mb-1 glass-card hvr3 gap-3"
							>
								<div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mb-3 w-100">
									<CardText className="text-center p-0 my-0 mx-auto my-fs-fanta">
										{getRoleAbbreviation(player.ruolo as PlayerRole)}
									</CardText>
									<img
										src={player.logoSquadra}
										alt={`Logo squadra ${player.squadra}`}
										className="logo-squad-create mx-auto mx-lg-0 ms-lg-auto me-lg-1"
									/>
								</div>
								<div className="text-center mt-3">
									<CardText className="player-name mt-3 mb-0 p-0 my-fs player-name">
										{splitPlayerName(player.nome)}
										<span className="ms-md-2 player-number-fanta">{player.numero}</span>
									</CardText>
								</div>
							</Card>
						))}
					</Col>
				</Row>
				<Row className="d-flex align-items-center justify-content-center mt-3 mt-lg-5">
					<Col xs={5} md={4} lg={3} className="left-entry team-entry ">
						{attackers.map((player, index) => (
							<Card
								key={player.nome + "-" + index}
								className="mt-2 point position-relative h-100 mb-1 glass-card hvr3 gap-3"
							>
								<div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mb-3 w-100">
									<CardText className="text-center p-0 my-0 mx-auto my-fs-fanta">
										{getRoleAbbreviation(player.ruolo as PlayerRole)}
									</CardText>
									<img
										src={player.logoSquadra}
										alt={`Logo squadra ${player.squadra}`}
										className="logo-squad-create mx-auto mx-lg-0 ms-lg-auto me-lg-1"
									/>
								</div>
								<div className="text-center mt-3">
									<CardText className="player-name mt-3 mb-0 p-0 my-fs-player player-name">
										{splitPlayerName(player.nome)}
										<span className="ms-md-2 player-number-fanta">{player.numero}</span>
									</CardText>
								</div>
							</Card>
						))}
					</Col>
				</Row>
			</div>

			<ModalStatistiche
				isOpen={isModalOpen}
				onRequestClose={handleCloseModal}
				teamName={teamName}
				playersInfo={playersInfo}
			/>
		</Container>
	);
};

export default SquadraPersonalizzata;
