import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHelmet } from "../Context/HelmetContext";
import { useLoading } from "../Context/LoadingContext";
const Policy = () => {
	const { t, ready } = useTranslation("policy");
	const { setState } = useHelmet();
	const { startLoading, stopLoading } = useLoading();

	useEffect(() => {
		if (!ready) {
			startLoading();
		} else {
			stopLoading();
		}
	}, [ready, startLoading, stopLoading]);

	useEffect(() => {
		// Imposta il titolo SEO e la descrizione per la pagina Registrazione
		setState((prevState) => ({
			...prevState,
			title: t("seo.title"),
			description: t("seo.description"),
		}));
		return () => {
			setState((prevState) => ({
				...prevState,
				title: prevState.title,
				description: prevState.description,
			}));
		};
	}, [setState, t]);

	return (
		<Container className="text-white">
			<Row>
				<div className="fade-in-animation">
					<Col className=" px-3 py-1  glass-card bg-transparent">
						<h1 className="text-center p-0 m-0 mt-3 mb-3">{t("privacyAndCookiePolicyTitle")}</h1>
						<h2>• {t("introductionTitle")}</h2>
						<p className="fs-5">{t("introductionText")}</p>

						<h2>• {t("dataCollectedTitle")}</h2>
						<p className="fs-5">{t("dataCollectedText")}</p>

						<h2>• {t("useOfDataTitle")}</h2>
						<p className="fs-5">{t("useOfDataText")}</p>

						<h2>• {t("userRightsTitle")}</h2>
						<p className="fs-5">{t("userRightsText")}</p>

						<h2>• {t("externalLinksTitle")}</h2>
						<p className="fs-5">{t("externalLinksText")}</p>

						<h2>• {t("securityTitle")}</h2>
						<p className="fs-5">{t("securityText")}</p>

						<h2>• {t("privacyPolicyChangesTitle")}</h2>
						<p className="fs-5">{t("privacyPolicyChangesText")}</p>

						<h2>• {t("preOrderPolicyTitle")}</h2>
						<p className="fs-5">{t("preOrderPolicyText")}</p>

						<h2 className="text-center gold my-4">{t("cookiePolicyTitle")}</h2>

						<h3 className="gold">•{t("useOfCookiesTitle")}</h3>
						<p className="fs-5">{t("useOfCookiesText")}</p>

						<h3 className="gold">•{t("managingCookiesTitle")}</h3>
						<p className="fs-5">{t("managingCookiesText")}</p>

						<h3 className="gold">•{t("thirdPartyCookiesTitle")}</h3>
						<p className="fs-5">{t("thirdPartyCookiesText")}</p>

						<h2>• {t("contactsTitle")}</h2>
						<p className="fs-5">
							{t("contactsText")}{" "}
							<a href="mailto:campionatosanantonio@gmail.com" className="tel">
								campionatosanantonio@gmail.com
							</a>
							.
						</p>
					</Col>
				</div>
			</Row>
		</Container>
	);
};

export default Policy;
