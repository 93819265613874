import { Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import LanguageSelector from "../language/LanguageSelector";
import { useLoading } from "./Context/LoadingContext";
import React, { useEffect, useState } from "react";
import { UAParser } from "ua-parser-js";
import { useCookieConsent } from "./Context/CoockieContext";
import PinIcon from "./svg/PinIcon";
import { useNotificationSound } from "./Context/NoticationSoundContext";

const Footer = () => {
	const date = new Date();
	const year = date.getFullYear();
	const { t, ready } = useTranslation("footer");
	const { startLoading, stopLoading } = useLoading();
	const { updateConsent } = useCookieConsent();
	const { soundEnabled, toggleSound } = useNotificationSound();
	const [isHovered, setIsHovered] = useState(false);

	const handleResetConsent = () => {
		updateConsent(null);
	};

	useEffect(() => {
		if (!ready) {
			startLoading();
		} else {
			stopLoading();
		}
	}, [ready, startLoading, stopLoading]);

	const handleOpenMap = () => {
		const iosUrl = "https://maps.apple.com/?ll=40.496805,17.649842&q=Segnaposto%20inserito&t=h";
		const otherUrl = "https://maps.app.goo.gl/7md1PSiAqRvPUc9cA";

		const parser = new UAParser();
		const result = parser.getResult();
		const osName = result.os.name;

		if (osName === "iOS" || osName === "Mac OS") {
			window.open(iosUrl);
		} else {
			window.open(otherUrl, "_blank");
		}
	};

	return (
		<Container>
			<footer
				id="contatti"
				className={`my-3 bg-transparent ${isHovered ? "glass-card" : ""}`}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<Row className="d-flex align-items-center justify-content-center">
					<Col xs={12} md={6} lg={4} className="mx-auto">
						<div className="card my-card bg-transparent border-0 d-flex align-items-center justify-content-center">
							<div className="link-description mb-0 mt-3">
								<div className="text-center fw-semibold fs-5 gold my-2">{t("footer.contactUs")}</div>
								<div className="tooltip-container mb-1">
									<Container>
										<Row>
											<Col>
												<span className="text-light">{t("people.fernando")}</span>
											</Col>
											<Col className="d-flex align-items-start-justify-content-start">
												<a href="tel:3501390994" className="tel">
													{t("contact.fernando")}
												</a>
											</Col>
											<span className="tooltip-right">{t("footer.organizer")}</span>
										</Row>
									</Container>
								</div>
								<div className="tooltip-container mb-1">
									<Container>
										<Row>
											<Col>
												<span className="text-light">{t("people.simone")}</span>
											</Col>
											<Col className="d-flex align-items-start-justify-content-start">
												<a href="tel:3773457733" className="tel">
													{t("contact.simone")}
												</a>
											</Col>
											<span className="tooltip-right">{t("footer.organizer")}</span>
										</Row>
									</Container>
								</div>
								<div className="tooltip-container mb-1">
									<Container>
										<Row>
											<Col>
												<span className="text-light">{t("people.sigi")}</span>
											</Col>
											<Col className="d-flex align-items-start-justify-content-start">
												<a href="tel:3920394959" className="tel">
													{t("contact.sigi")}
												</a>
											</Col>
											<span className="tooltip-right"> {t("footer.socialMediaManager")}</span>
										</Row>
									</Container>
								</div>
								<div className="tooltip-container mb-1">
									<Container>
										<Row>
											<Col>
												<span className="text-light">{t("people.pierpaolo")}</span>
											</Col>
											<Col className="d-flex align-items-start-justify-content-start">
												<a href="tel:3290498818" className="tel">
													{t("contact.pierpaolo")}
												</a>
											</Col>
											<span className="tooltip-right"> {t("footer.socialMediaManager")}</span>
										</Row>
									</Container>
								</div>
								<div className="tooltip-container mb-1">
									<Container>
										<Row>
											<Col>
												<span className="text-light">{t("people.andrea")}</span>
											</Col>
											<Col className="d-flex align-items-start-justify-content-start">
												<a href="tel:3886112667" className="tel">
													{t("contact.andrea")}
												</a>
											</Col>
											<span className="tooltip-right">{t("footer.service")}</span>
										</Row>
									</Container>
								</div>
								<div className="tooltip-container mb-1">
									<Container>
										<Row>
											<Col>
												<span className="text-light">Salvatore D.</span>
											</Col>
											<Col className="d-flex align-items-start-justify-content-start">
												<a href="tel:3792242254" className="tel">
													3792242254
												</a>
											</Col>
											<span className="tooltip-right">Web Developer</span>
										</Row>
									</Container>
								</div>
							</div>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={12} className="d-flex align-items-center justify-content-center gap-2">
						<span className="tooltip-container">
							<a href="https://instagram.com/campionatosanantonio" target="_blank" rel="noopener noreferrer">
								<img
									src="/assets/img/instagram_bold.webp"
									alt="Instagram icon"
									className="icon rounded-3 p-1 invertedd"
								/>
							</a>
							<span className="tooltip">{t("footer.visitInstagram")}</span>
						</span>

						<span className="tooltip-container">
							<a href="https://facebook.com/campionatosanantonio" target="_blank" rel="noopener noreferrer">
								<img
									src="/assets/img/facebook_bold.webp"
									alt="Facebook icon"
									className="icon rounded-3 p-1 invertedd"
								/>
							</a>
							<span className="tooltip">{t("footer.visitFacebook")}</span>
						</span>
						<span className="tooltip-container">
							<a
								href="https://www.tiktok.com/@campionatosananto?_t=8liG50QnS5Y&_r=1"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src="/assets/img/tik-tok.webp" alt="Instagram icon" className="icon rounded-3 p-1 invertedd" />
							</a>
							<span className="tooltip">{t("footer.visitTikTok")}</span>
						</span>
					</Col>
					<Col xs={12} className="d-flex align-items-center justify-content-center mt-3">
						<LanguageSelector />
						<button className="point nav-link icon-pst" onClick={handleOpenMap} title="Posizione Campo di gioco">
							<PinIcon />
						</button>
						<div className="d-flex align-items-center justify-content-center mt-1 ms-1">
							<div className="toggle9 mx-auto">
								<input
									type="checkbox"
									checked={soundEnabled}
									onChange={toggleSound}
									title={soundEnabled ? "Disattiva suono notifiche" : "Attiva suono notifiche"}
								/>
								<label></label>
							</div>
						</div>
					</Col>
					<Col xs={12} className="mt-2">
						<button
							className="nav-link gold tel mx-auto"
							onClick={handleResetConsent}
							title="Rivedi consenso dei Cookie"
						>
							Rivedi Cookie
						</button>
					</Col>
				</Row>

				<div className="d-flex flex-column align-items-center justify-content-center">
					<p className="mb-0 p-0 text-light">{t("footer.championship")}</p>
					<div className="d-flex align-items-center justify-content-center mx-auto">
						<NavLink
							to={"/policy"}
							className="nav-link gold p-0 m-0 tel mx-1"
							title="Policy Sito Web campionatosanantonio.it"
						>
							{t("footer.policy")}
						</NavLink>
					</div>
					<p className="text-light reservati text-center">
						© {year}
						{t("footer.allRightsReserved")}
					</p>
				</div>
			</footer>
		</Container>
	);
};

export default Footer;
