import React, { useEffect, useState, useRef } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Giornata } from "../Interfaces/Interface";
import { parseDate } from "../Script/Utility";
import ColorThief from "colorthief";

ChartJS.register(ArcElement, Tooltip, Legend);

interface TeamPerformanceChartProps {
	giornate: Giornata[];
	teamId: string;
	logo: string; // Aggiungi il logo come prop
}

const TeamPerformanceChart: React.FC<TeamPerformanceChartProps> = ({ giornate, teamId, logo }) => {
	const [colors, setColors] = useState<string[]>([
		"rgba(75,192,192,1)",
		"rgba(54, 162, 235, 1)",
		"rgba(255, 99, 132, 1)",
		"rgba(75, 192, 75, 1)",
		"rgba(192, 192, 75, 1)",
		"rgba(192, 75, 75, 1)",
	]);

	const chartRefs = useRef<any[]>([]); // Refs for each chart instance

	useEffect(() => {
		const img = new Image();
		img.crossOrigin = "Anonymous";
		img.src = logo;
		img.onload = () => {
			const colorThief = new ColorThief();
			const palette = colorThief.getPalette(img, 10); // Get more colors for better variety
			const uniqueColors = ensureDistinctColors(palette, 6); // Ensure the colors are distinct
			const rgbaColors = uniqueColors.map((color) => `rgba(${color[0]}, ${color[1]}, ${color[2]}, 0.8)`);
			setColors(rgbaColors);
		};
	}, [logo]);

	const ensureDistinctColors = (palette: [number, number, number][], numColors: number): [number, number, number][] => {
		const distinctColors: [number, number, number][] = [];
		for (let i = 0; i < palette.length && distinctColors.length < numColors; i++) {
			let isDistinct = true;
			for (let j = 0; j < distinctColors.length; j++) {
				if (colorDistance(palette[i], distinctColors[j]) < 10) {
					// Adjust threshold as needed
					isDistinct = false;
					break;
				}
			}
			if (isDistinct) {
				distinctColors.push(palette[i]);
			}
		}
		return distinctColors.length < numColors ? palette.slice(0, numColors) : distinctColors;
	};

	const colorDistance = (color1: [number, number, number], color2: [number, number, number]): number => {
		return Math.sqrt(
			Math.pow(color1[0] - color2[0], 2) + Math.pow(color1[1] - color2[1], 2) + Math.pow(color1[2] - color2[2], 2)
		);
	};

	const getTeamPerformanceData = () => {
		const points = { label: "Punti Accumulati", value: 0 };
		const goalsFor = { label: "Goal Fatti", value: 0 };
		const goalsAgainst = { label: "Goal Subiti", value: 0 };
		const winCount = { label: "Partite Vinte", value: 0 };
		const drawCount = { label: "Partite Pareggiate", value: 0 };
		const loseCount = { label: "Partite Perse", value: 0 };

		const startDate = new Date("2024-05-19");
		const endDate = new Date("2024-07-20");

		giornate.forEach((giornata) => {
			const giornataDate = parseDate(giornata.data);
			if (giornataDate >= startDate && giornataDate <= endDate) {
				giornata.partite.forEach((partita) => {
					if (partita.squadraCasaId === teamId || partita.squadraOspiteId === teamId) {
						const [golCasa, golOspite] = partita.risultato ? partita.risultato.split("-").map(Number) : [0, 0];

						if (partita.squadraCasaId === teamId) {
							goalsFor.value += golCasa;
							goalsAgainst.value += golOspite;
							if (golCasa > golOspite) {
								points.value += 3;
								winCount.value++;
							} else if (golCasa === golOspite) {
								points.value += 1;
								drawCount.value++;
							} else {
								loseCount.value++;
							}
						} else {
							goalsFor.value += golOspite;
							goalsAgainst.value += golCasa;
							if (golOspite > golCasa) {
								points.value += 3;
								winCount.value++;
							} else if (golOspite === golCasa) {
								points.value += 1;
								drawCount.value++;
							} else {
								loseCount.value++;
							}
						}
					}
				});
			}
		});

		return [
			{ label: points.label, value: points.value, max: 14 * 3, color: colors[0] },
			{ label: goalsFor.label, value: goalsFor.value, max: 100, color: colors[1] }, // max of 100 for visualization purposes
			{ label: goalsAgainst.label, value: goalsAgainst.value, max: 100, color: colors[2] }, // max of 100 for visualization purposes
			{ label: winCount.label, value: winCount.value, max: 14, color: colors[3] },
			{ label: drawCount.label, value: drawCount.value, max: 14, color: colors[4] },
			{ label: loseCount.label, value: loseCount.value, max: 14, color: colors[5] },
		];
	};

	const performanceData = getTeamPerformanceData();

	const createDoughnutData = (data: { label: string; value: number; max?: number; color: string }) => ({
		labels: [data.label],
		datasets: [
			{
				data: data.max ? [data.value, data.max - data.value] : [data.value],
				backgroundColor: [data.color, "rgba(211,211,211,0.3)"],
				hoverBackgroundColor: [data.color, "rgba(211,211,211,0.3)"],
				borderWidth: 1,
				borderColor: "#000000", // Add border color
				hoverBorderColor: "#000000", // Add hover border color
				hoverBorderWidth: 2, // Add hover border width
			},
		],
	});

	const options = {
		plugins: {
			legend: {
				labels: {
					color: "#c0a868", // Imposta il colore delle etichette della leggenda su dorato
				},
			},
			tooltip: {
				titleColor: "#c0a868", // Imposta il colore del titolo del tooltip su dorato
				bodyColor: "#c0a868", // Imposta il colore del corpo del tooltip su dorato
				backgroundColor: "rgba(0, 0, 0, 0.7)", // Set tooltip background color
				borderColor: "#c0a868", // Set tooltip border color
				borderWidth: 1, // Set tooltip border width
				cornerRadius: 4, // Set tooltip corner radius
				xPadding: 10, // Set tooltip x padding
				yPadding: 10, // Set tooltip y padding
				displayColors: false, // Remove color boxes in tooltip
				callbacks: {
					label: function (tooltipItem: any) {
						const label = tooltipItem.label || "";
						const value = tooltipItem.raw || 0; // Mostra solo il valore ottenuto
						return `${label}: ${value}`;
					},
				},
			},
		},
		animation: {
			animateScale: true,
			animateRotate: true,
		},
	};

	return (
		<div className="mt-3">
			<h3 className="text-center">Andamento della Squadra</h3>
			<div className="d-flex flex-wrap justify-content-center">
				{performanceData.map((data, index) => (
					<div
						key={index}
						className="m-3"
						style={{ width: "200px", height: "200px" }}
						ref={(el) => (chartRefs.current[index] = el!)}
						data-index={index}
					>
						<Doughnut data={createDoughnutData(data)} options={options} />
						<p className="text-center mt-2" style={{ color: "#c0a868" }}>
							{data.label}
						</p>
					</div>
				))}
			</div>
		</div>
	);
};

export default TeamPerformanceChart;
