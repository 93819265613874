import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import { Evento } from "../Interfaces/Interface";

interface EventoIconaProps {
	evento: Evento;
}

const GoalIcon = () => <img src="/assets/img/soccer.webp" alt="goal" className="img-fluid invertedd goal-icon me-1" />;
const YellowCardIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className="me-1 me-lg-2 cartellino">
		<rect x="12" y="4" width="40" height="56" fill="yellow" stroke="black" strokeWidth="2" />
	</svg>
);
const RedCardIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className="me-1 me-lg-2 cartellino">
		<rect x="12" y="4" width="40" height="56" fill="red" stroke="black" strokeWidth="2" />
	</svg>
);
const GenericEventIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" className="cartellino me-1 me-lg-2">
		<circle cx="32" cy="32" r="30" fill="white" stroke="black" strokeWidth="2" />
	</svg>
);

const EventoIcona: React.FC<EventoIconaProps> = ({ evento }) => {
	switch (evento.tipo) {
		case "goal":
			return <GoalIcon />; // Icona per il goal
		case "auto-goal":
			return <GoalIcon />; // Icona per l'auto-goal
		case "cartellino giallo":
			return <YellowCardIcon />; // Icona per cartellino giallo
		case "cartellino rosso":
			return <RedCardIcon />; // Icona per cartellino rosso
		default:
			return <GenericEventIcon />; // Icona per eventi generici
	}
};

interface ListaEventiProps {
	eventi: Evento[];
	squadraCasaNome: string;
	squadraOspiteNome: string;
	squadraCasaLogo: string;
	squadraOspiteLogo: string;
}

const ListaEventi: React.FC<ListaEventiProps> = ({
	eventi,
	squadraCasaNome,
	squadraOspiteNome,
	squadraCasaLogo,
	squadraOspiteLogo,
}) => {
	// Filtra e ordina gli eventi per squadra
	const eventiCasa = eventi
		.filter((evento) => evento.squadraNome === squadraCasaNome)
		.sort((a, b) => a.minuto - b.minuto);
	const eventiOspite = eventi
		.filter((evento) => evento.squadraNome === squadraOspiteNome)
		.sort((a, b) => a.minuto - b.minuto);

	return (
		<div>
			<div className="d-flex justify-content-between eventi-desktop">
				<div className="squadra-casa scrollable-list">
					<ListGroup className="bg-transparent border-0 p-0">
						{eventiCasa.map((evento, index) => (
							<ListGroupItem key={index} className="bg-transparent border-0 text-white px-0 py-2">
								<div className="event-item">
									<EventoIcona evento={evento} />
									<span className="giocatoreNome my-fs ms-2 me-3">{evento.giocatoreNome}</span>
									<span className="minuto me-2 mt-1 ms-auto">{`${evento.minuto}'`}</span>
								</div>
							</ListGroupItem>
						))}
					</ListGroup>
				</div>

				<div className="squadra-ospite scrollable-list">
					<ListGroup className="bg-transparent border-0 p-0">
						{eventiOspite.map((evento, index) => (
							<ListGroupItem key={index} className="bg-transparent border-0 text-white px-0 py-2">
								<div className="event-item">
									<EventoIcona evento={evento} />
									<span className="giocatoreNome my-fs ms-2 me-3">{evento.giocatoreNome}</span>
									<span className="minuto me-2 mt-1 ms-auto">{`${evento.minuto}'`}</span>
								</div>
							</ListGroupItem>
						))}
					</ListGroup>
				</div>
			</div>

			<div className="eventi-centrali scrollable-list">
				<ListGroup className="bg-transparent border-0 p-0">
					{eventi.map((evento, index) => (
						<ListGroupItem key={index} className="bg-transparent border-0 text-white px-0 py-2">
							<div className="event-item">
								<EventoIcona evento={evento} />
								<img
									src={evento.squadraNome === squadraCasaNome ? squadraCasaLogo : squadraOspiteLogo}
									alt={`Logo ${evento.squadraNome}`}
									className="logo-small mx-1"
								/>
								<span className="giocatoreNome my-fs ms-2 me-3">{evento.giocatoreNome}</span>
								<span className="minuto me-2 mt-1 ms-auto">{`${evento.minuto}'`}</span>
							</div>
						</ListGroupItem>
					))}
				</ListGroup>
			</div>
		</div>
	);
};

export default ListaEventi;
