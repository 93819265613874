import React from 'react';

const GiglioIcon = () => {
  return (
    <svg
      viewBox="0 0 1000 1000"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: '250px', height: '250px' }}
      className="animatedStroke"
    >
      <g stroke="#c0a868" fill="none" strokeWidth="8" strokeMiterlimit="10">
        <g>
          <path
            className="st0"
            d="M523.47,242.81c6.21,13.45,14.77,25.19,23.42,37.16c16.79,23.23,35.35,45.62,50.18,70.19
			c7.34,12.16,13.26,25.17,17.66,38.68c2.29,7.03,3.91,14.17,5.3,21.43c0.99,5.15,4.65,14.15,1.94,19.11
			c-2.29,4.19-9.62,6.65-13.47,9.07c-6.15,3.85-12.08,8.05-17.78,12.54c-9.29,7.31-17.05,17.39-27.17,23.35
			c-3.5-16.88,2.31-38.46,4.1-55.62c0.95-9.07,1.82-18.14,2.85-27.2c0.84-7.43,4-17.21-3.07-22.78c-4.67-3.68-12.34-2.9-16.37,1.37
			c-5.09,5.4-4.67,17.33-5.43,24.41c-2.08,19.42-3.71,38.88-5.74,58.3c-1.95,18.57-3.98,37.14-6.05,55.7
			c-1.05,9.43-3.13,16.39-7.24,24.95c-2.25,4.67-7.51,15.27-12.12,6.27c-2.91-5.7-0.15-20.53-0.14-27.04
			c0.02-9.97,0.01-19.94,0.01-29.91c0-20.09,0.01-40.17,0.01-60.26c0-19.37-0.01-38.74-0.03-58.1c-0.01-9.33-0.02-18.65-0.03-27.98
			c-0.01-7.95,2.17-20.36-4.57-26.25c-4.36-3.81-12.13-4.59-17.12-1.39c-7.59,4.87-5.91,15.8-5.91,23.36c0,19.73,0,39.47,0.01,59.2
			c0.01,40.93,0.02,81.86,0.02,122.78c0,6.51,2.38,18.73-0.12,24.57c-4.38,10.21-10.6-1.64-12.84-6.54
			c-8.07-17.67-7.91-37.17-9.82-56.2c-2.23-22.21-4.58-44.42-7.1-66.6c-1.31-11.51,1.33-44.17-16.57-42.84
			c-18.62,1.38-9.51,32.74-8.26,44.24c1.24,11.36,2.54,22.71,3.82,34.06c1.02,9.07,4.71,21.03,1.33,29.52
			c-10.42-4.69-19.31-17.59-28.7-24.7c-5.07-3.84-10.33-7.45-15.71-10.84c-4.56-2.86-12.29-5.5-14.27-10.89
			c-1.96-5.36,1.27-14.11,2.4-19.54c1.32-6.35,2.88-12.66,4.85-18.84c4.42-13.87,10.99-26.93,18.49-39.37
			c14.4-23.86,32.8-44.85,48.92-67.49c9.17-12.87,17.15-26.44,24.64-40.35c2.81-5.22,5.12-10.7,7.12-16.28
			c0.63-1.75,1.34-3.48,2.08-5.19c5.27-12.21,22.71-12.08,27.73,0.24C517.66,228.28,520.2,235.71,523.47,242.81z"
          />
          <path
            className="st0"
            d="M136.98,486.94c10.16-10.42,21.53-19.64,33.7-27.62c25.18-16.5,55.36-26.7,85.29-30.02
			c59.06-6.55,120.51,17.83,160.77,60.89c9.41,10.06,17.68,21.08,24.67,32.95c2.88,4.88,9.13,12.42,10.04,18.03
			c1.38,8.47-7.71,7.81-14.61,9.54c-24.06,6.03-47.72,12.61-61.11,35.33c-3.87,6.56-6.51,13.71-8.32,21.1
			c-1.01,4.11-0.48,14.66-3.17,18.03c-5.26,6.59-24.44-12.89-29.04-17.3c-21.61-20.71-33.55-48.44-50.48-72.66
			c-17.51-25.05-43.22-40.87-74.08-42.32c-22.01-1.03-42.42,4.44-62.24,12.17c-10.85,4.23-19.93-9.33-11.84-17.7
			C136.71,487.22,136.84,487.08,136.98,486.94z"
          />
          <path
            className="st0"
            d="M499.7,566.77c23.8-0.22,48.42,3.29,71.1,10.57c23.19,7.45,36.28,17.5,40.44,42.47
			c8.14,48.87-31.52,87.07-61.52,119.84c-7.13,7.79-14.32,15.58-20.7,24.02c-5.73,7.58-11.77,25.17-20.34,28.57
			c-2.74,1.09-12.66,1.12-15.71,0.28c-4.72-1.3-6.1-4.41-8.36-8.49c-5.67-10.24-11.85-19.62-19.38-28.61
			c-28.93-34.51-71.89-68.1-76.23-116.01c-2.03-22.4,4.92-49.15,27.99-57.72C442.78,572.11,472.15,566.38,499.7,566.77z"
          />
          <path
            className="st0"
            d="M878.05,502.97c4.15,5.29-1.53,12.57-7.63,9.76c-46.79-21.58-103.69-33.02-142.98,8.78
			c-19.87,21.15-30.75,49.26-48.58,72c-8.3,10.58-18.05,19.72-29.03,27.48c-2.54,1.8-8.5,7.61-11.76,6.26
			c-3.7-1.53-3.86-16.81-4.65-20.41c-6.26-28.46-27.37-45.09-54.43-52.62c-7.65-2.13-15.5-3.41-23.15-5.5
			c-7.13-1.95-8.05-3.02-4.83-10.18c6.05-13.47,14.97-26.59,24.21-38.05c37.7-46.78,97.34-74.29,157.42-71.8
			c33.44,1.38,66.65,11.12,95.05,28.87c13.12,8.2,25.3,17.91,36.1,28.99c5.01,5.14,9.73,10.58,14.13,16.25
			C877.96,502.87,878,502.92,878.05,502.97z"
          />
          <path
            className="st0"
            d="M755.72,714.06c-0.18,1.31-0.32,2.69-0.45,4.11c-0.73,8.25-11.24,11.05-16.13,4.37
			c-0.04-0.06-0.09-0.12-0.13-0.17c-5.01-6.72-11.51-12.08-18.85-16.09c-14.7-8.01-31.99-10.61-48.4-12.54
			c-9.12-1.07-18.37-1.52-27.42-3.08c-4.74-0.82-16.8-1.34-19.03-5.85c-2.11-4.28,4.14-15.59,6.17-19.91
			c3.93-8.37,10.36-10.25,18.06-14.6c13.52-7.64,25.98-17.11,36.8-28.25c5.65-5.81,9.16-15.11,17.79-10.94
			c7.2,3.48,14.9,11.88,20.26,17.59C746.23,651.96,755.59,682.65,755.72,714.06z"
          />
          <path
            className="st0"
            d="M245.13,711.91c1.5-34.27,12.86-65.9,38.23-89.74c3.79-3.56,12.51-13.07,18.01-13.05
			c5.21,0.02,12.09,11.84,15.54,15.24c11.63,11.49,25.08,20.94,39.57,28.5c7.07,3.69,10.54,6.03,13.52,13.5
			c1.73,4.33,8.01,14.46,5.62,19.11c-2.05,3.99-19.72,5.11-23.96,5.78c-9.73,1.54-19.55,2.27-29.32,3.46
			c-15.81,1.94-32.28,5.01-45.81,13.93c-6.39,4.22-12.03,9.68-16.12,16.17c-0.03,0.05-0.07,0.11-0.1,0.16
			c-4,6.45-13.88,4.16-14.41-3.41C245.66,718.17,245.49,714.86,245.13,711.91z"
          />
        </g>
      </g>
    </svg>
  );
};
export default GiglioIcon;
