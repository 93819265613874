const DriveIcon = () => {
	return (
		<svg
			id="Layer_1"
			enableBackground="new 0 0 500 500"
			viewBox="0 0 500 500"
			xmlns="http://www.w3.org/2000/svg"
			className="invertedd"
			height={70}
		>
			<path
				d="m199.0845 350.1836h11.4863 75.1694 27.0972 32.1025 10.0991v-116.5615h-155.9545zm49.5918-22 13.0278-22.5654 13.0278 22.5654zm47.1613-7.4443 3.3494-5.8018 7.6475 13.2461h-6.6987zm-74.7531-65.1172h111.9546v72.5615h-.8008l-23.5249-40.7461-9.5264-16.5-16.051 27.8018-21.4319-37.1211-38.4316 66.5654h-2.188z"
				fill="#000"
			/>
			<path d="m363.406 204.398h24.74v22h-24.74z" fill="#000" />
			<path
				d="m405.3071 164.0986v-1.332c0-20.2588-16.4819-36.7412-36.7407-36.7412h-116.2891c-3.9375 0-7.6392-1.5332-10.4229-4.3174l-21.9917-21.9912c-6.9395-6.9395-16.1655-10.7607-25.9795-10.7607h-90.6934c-20.2588 0-36.7407 16.4814-36.7407 36.7402v107.666h22v-107.666c0-8.1279 6.6128-14.7402 14.7407-14.7402h90.6934c3.9375 0 7.6392 1.5332 10.4229 4.3174l21.9917 21.9912c6.9395 6.9395 16.1655 10.7607 25.9795 10.7607h116.2891c8.1279 0 14.7407 6.6133 14.7407 14.7412v.3291h-231.5737c-20.2588 0-36.7402 16.4814-36.7402 36.7402v111.9473 15.8867 48.1025c0 7.3184-5.9536 13.2715-13.272 13.2715s-13.272-5.9531-13.272-13.2715v-76.0137h-22v76.0137c0 19.4492 15.8228 35.2715 35.272 35.2715.057 0 .113-.0039.1699-.0044v.0044h294.1294c20.6943 0 37.5303-16.8359 37.5303-37.5303v-173.6777c0-17.3335-12.0698-31.8921-28.2437-35.7373zm6.2437 209.4151c0 8.5635-6.9668 15.5303-15.5303 15.5303h-261.6278c1.6715-4.0996 2.6005-8.5781 2.6005-13.2715v-48.1025-15.8867-111.9474c0-8.1279 6.6123-14.7402 14.7402-14.7402h245.0767c8.1279 0 14.7407 6.6123 14.7407 14.7402z"
				fill="#000"
			/>
			<path d="m66.449 251.548h22v31.718h-22z" fill="#000" />
		</svg>
	);
};
export default DriveIcon;
