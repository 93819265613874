import React, { useState, useEffect } from "react";
import { Giocatore, ISquadra } from "../Interfaces/Interface";
import SquadraPersonalizzata from "./SquadraPersonalizzata";
import SelectedPlayerSummary from "./SelectPlayerSummary";
import { getLocalStorage, gironeA, gironeB, setLocalStorage } from "../Script/Utility";
import { Card, CardBody, Container, Row, Col, CardText, Alert } from "react-bootstrap";
import { useCookieConsent } from "../Context/CoockieContext";
import { useHelmet } from "../Context/HelmetContext";
import ReactModal from "react-modal";
import AlertManager from "./AlertManager";
import AboutIcon from "../svg/AboutIcon";
import { format, isAfter } from "date-fns";
import NotifyToast from "../Toast/NotifyToast";
import { useNotificationSound } from "../Context/NoticationSoundContext";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext"; // importa il contesto di autenticazione
import axiosInstance from "../Script/axiosConfig";

function isValidRole(role: string): role is PlayerRole {
	return ["Portiere", "Difensore", "Centrocampista", "Attaccante"].includes(role);
}

export type PlayerRole = "Portiere" | "Difensore" | "Centrocampista" | "Attaccante";
export const getRoleAbbreviation = (role: PlayerRole): string => {
	switch (role) {
		case "Portiere":
			return "PT";
		case "Difensore":
			return "DF";
		case "Centrocampista":
			return "CC";
		case "Attaccante":
			return "AT";
		default:
			return "";
	}
};
export const splitPlayerName = (name: string) => {
	const nameParts = name.split(" ");
	const firstName = nameParts[0];
	const lastName = nameParts.slice(1).join(" ");
	return (
		<>
			{firstName}
			<br />
			{lastName}
		</>
	);
};
const roleLimits: Record<PlayerRole, number> = {
	Portiere: 1,
	Difensore: 2,
	Centrocampista: 2,
	Attaccante: 1,
};

const roleOrder: PlayerRole[] = ["Portiere", "Difensore", "Centrocampista", "Attaccante"];

const updateLocalStorage = (newData: ISquadra[]) => {
	setLocalStorage("squadre", JSON.stringify(newData));
};

const SquadCreator = () => {
	const { consent } = useCookieConsent();
	const { setState } = useHelmet();
	const navigate = useNavigate();
	const { soundEnabled } = useNotificationSound();
	const { user } = useAuth(); // usa il contesto di autenticazione
	const [selectedPlayers, setSelectedPlayers] = useState<Giocatore[]>([]);
	const [selectedRole, setSelectedRole] = useState<PlayerRole>("Portiere");
	const [username, setUsername] = useState<string>("");
	const [teamName, setTeamName] = useState<string>("");
	const [linkInstagram, setLinkInstagram] = useState<string>("");
	const [filteredPlayers, setFilteredPlayers] = useState<Giocatore[]>([]);
	const [hasFormation, setHasFormation] = useState<boolean>(false);
	const [playerCounts, setPlayerCounts] = useState<Record<PlayerRole, number>>({
		Portiere: 0,
		Difensore: 0,
		Centrocampista: 0,
		Attaccante: 0,
	});
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [teamNameError, setTeamNameError] = useState<string>("");
	const [userNameError, setUserNameError] = useState<string>("");
	const [linkInstagramError, setLinkInstagramError] = useState<string>("");
	const [showAlert, setShowAlert] = useState<boolean>(false);
	const [showDetails, setShowDetails] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [localFormation, setLocalFormation] = useState<Giocatore[]>([]);
	const [isResetModalOpen, setIsResetModalOpen] = useState(false);
	const cutoffDate = new Date(2024, 4, 27);

	useEffect(() => {
		const timer = setTimeout(() => {
			if (username && teamName) {
				setShowDetails(true);
			} else {
				setShowDetails(false);
			}
		}, 1000);

		return () => clearTimeout(timer);
	}, [teamName, username]);

	// useEffect(() => {
	// 	const allSquadre = [...gironeA, ...gironeB];
	// 	const allPlayers: Giocatore[] = allSquadre.flatMap((squadra: ISquadra) => squadra.giocatori || []);

	// 	// const updatePlayerData = () => {
	// 	// 	const localStorageData = getLocalStorage("formazione");
	// 	// 	if (localStorageData) {
	// 	// 		const existingFormation = JSON.parse(localStorageData);

	// 	// 		const updatedPlayers = existingFormation.players.map((player: Giocatore) => {
	// 	// 			const updatedPlayer = allPlayers.find((p) => p.nome.toLowerCase() === player.nome.toLowerCase());
	// 	// 			return updatedPlayer ? { ...player, ...updatedPlayer } : player;
	// 	// 		});

	// 	// 		const updatedFormation = { ...existingFormation, players: updatedPlayers };
	// 	// 		setLocalStorage("formazione", JSON.stringify(updatedFormation));
	// 	// 		setSelectedPlayers(updatedPlayers);
	// 	// 		setUsername(existingFormation.username);
	// 	// 		setTeamName(existingFormation.teamName);
	// 	// 		setLinkInstagram(existingFormation.linkInstagram);
	// 	// 	}
	// 	// };

	// 	// if (allPlayers.length > 0) {
	// 	// 	updatePlayerData();
	// 	// }
	// }, []);

	useEffect(() => {
		const fetchFormazione = async () => {
			try {
				const response = await axiosInstance.get("/api/formazione");
				if (response.data) {
					const { teamName, username, linkInstagram, players } = response.data;
					setTeamName(teamName);
					setUsername(username);
					setLinkInstagram(linkInstagram);
					setSelectedPlayers(players);
					setHasFormation(true);
				}
			} catch (error) {
				console.error("Errore nel recupero della formazione:", error);
			}
		};

		if (user) {
			fetchFormazione();
		}
	}, [user]);

	const handleCloseAlert = (type: "success" | "warning") => {
		setShowAlert(false);
	};

	const handleOpenModal = () => {
		if (!username) {
			NotifyToast.error("Devi inserire il tuo nome.", soundEnabled);
			return;
		}

		if (!teamName) {
			NotifyToast.error("Devi inserire il nome della squadra.", soundEnabled);
			return;
		}

		if (!linkInstagram) {
			NotifyToast.error("Devi inserire il nome del profilo instagram.", soundEnabled);
			return;
		}

		let missingRolesMessage = "";
		Object.entries(roleLimits).forEach(([role, limit]) => {
			const count = selectedPlayers.filter((player) => player.ruolo === role).length;
			if (count < limit) {
				missingRolesMessage += `${limit - count} ${role}, `;
			}
		});

		if (missingRolesMessage) {
			NotifyToast.error(`Devi selezionare ancora ${missingRolesMessage.slice(0, -2)}.`, soundEnabled);
			return;
		}
		setIsModalOpen(true);
	};
	const handleCloseModal = () => setIsModalOpen(false);

	const handleOpenResetModal = () => setIsResetModalOpen(true);
	const handleCloseResetModal = () => setIsResetModalOpen(false);

	const handleReset = async () => {
		try {
			const response = await axiosInstance.delete("/api/formazione", { data: { userId: user?._id } });
			if (response.status === 200) {
				localStorage.removeItem("formazione");
				localStorage.removeItem("formazione_expiration");
				setSelectedPlayers([]);
				setUsername("");
				setTeamName("");
				setLinkInstagram("");
				setHasFormation(false);
				setIsResetModalOpen(false);
				NotifyToast.success("Formazione resettata con successo.", soundEnabled);
			}
		} catch (error) {
			console.error("Errore nel resettare la formazione:", error);
			NotifyToast.error("Errore nel resettare la formazione.", soundEnabled);
		}
	};

	useEffect(() => {
		updateFilteredPlayersByRole(selectedRole, selectedPlayers);

		updatePlayerCounts(selectedPlayers);
	}, [selectedPlayers, selectedRole]);

	useEffect(() => {
		setState((prevState) => ({
			...prevState,
			title: "Crea la tua squadra del Campionato San Antonio",
			description: "Squadra personalizzata del campionato di calcio a 7 di San Antonio Abate",
		}));
		const savedData = getLocalStorage("formazione");
		if (savedData) {
			const formationData = JSON.parse(savedData);
			if (formationData && formationData.players && formationData.players.length > 0) {
				setHasFormation(true);
				if (formationData.players) {
					setSelectedPlayers(formationData.players);
				}
				if (formationData.teamName) {
					setTeamName(formationData.teamName);
				}
				if (formationData.username) {
					setUsername(formationData.username);
				}
				updatePlayerCounts(formationData.players);
			} else {
				setHasFormation(false);
			}
		}
	}, [setState]);

	const updateFilteredPlayersByRole = (role: PlayerRole, players: Giocatore[]) => {
		const allSquadre = [...gironeA, ...gironeB];
		const allPlayers: Giocatore[] = allSquadre.flatMap((squadra: ISquadra) => squadra.giocatori || []);

		if (players.length >= 6) {
			setFilteredPlayers([]);
		} else {
			if (players.length === 0) {
				const newFilteredPlayers = allPlayers.filter((player) => player.ruolo === "Portiere");
				setFilteredPlayers(newFilteredPlayers);
			} else {
				const newFilteredPlayers = allPlayers.filter(
					(player) => player.ruolo === role && !players.some((p) => p.id === player.id)
				);
				setFilteredPlayers(newFilteredPlayers);
			}
		}
	};

	const updatePlayerCounts = (players: Giocatore[]): Record<PlayerRole, number> => {
		const counts: Record<PlayerRole, number> = {
			Portiere: 0,
			Difensore: 0,
			Centrocampista: 0,
			Attaccante: 0,
		};

		players.forEach((player) => {
			if (player.ruolo && isValidRole(player.ruolo)) {
				counts[player.ruolo]++;
			}
		});

		setPlayerCounts(counts);
		return counts;
	};

	const handlePlayerSelect = (player: Giocatore) => {
		if (selectedPlayers.some((p) => p.id === player.id)) {
			NotifyToast.error("Questo giocatore è già stato selezionato.", soundEnabled);
			return;
		}

		const playerRole: PlayerRole = player.ruolo as PlayerRole;
		if (playerCounts[playerRole] >= roleLimits[playerRole]) {
			NotifyToast.error(`Puoi selezionare solo ${roleLimits[playerRole]} ${playerRole}.`, soundEnabled);
			return;
		}

		const newPlayers = [...selectedPlayers, player];
		setSelectedPlayers(newPlayers);
		const newCounts = updatePlayerCounts(newPlayers);

		if (newCounts[playerRole] < roleLimits[playerRole]) {
			setSelectedRole(playerRole);
		} else {
			const nextRole = roleOrder.find((role) => newCounts[role] < roleLimits[role]);
			if (nextRole) {
				setSelectedRole(nextRole);
			}
		}
	};

	const handleRemovePlayer = (playerId: string) => {
		const newPlayers = selectedPlayers.filter((player) => player.id !== playerId);
		setSelectedPlayers(newPlayers);

		const removedPlayer = selectedPlayers.find((player) => player.id === playerId);
		if (removedPlayer) {
			const removedPlayerRole = removedPlayer.ruolo as PlayerRole;
			const newCounts = updatePlayerCounts(newPlayers);

			if (newCounts[removedPlayerRole] < roleLimits[removedPlayerRole]) {
				setSelectedRole(removedPlayerRole);
			}

			updateFilteredPlayersByRole(selectedRole, newPlayers);
		}
	};

	const handleSubmit = async () => {
		if (!consent) {
			NotifyToast.error("Devi accettare i cookie prima di salvare la squadra.", soundEnabled);
			return;
		}
		setIsLoading(true);

		try {
			const formData = {
				teamName,
				username,
				linkInstagram,
				players: selectedPlayers,
				userId: user?._id,
			};

			const response = await axiosInstance.post("/api/formazione", formData);
			if (response.status === 200) {
				NotifyToast.success("Formazione salvata con successo!", soundEnabled);
				setLocalStorage("formazione", JSON.stringify(selectedPlayers), 90);
				setHasFormation(true);
			} else {
				NotifyToast.error("Errore nel salvataggio della formazione.", soundEnabled);
			}
		} catch (error) {
			console.error("Errore nel salvataggio della formazione:", error);
			NotifyToast.error("Errore nel salvataggio della formazione.", soundEnabled);
		} finally {
			setIsLoading(false);
		}
	};

	const buttonDisabled = (role: PlayerRole) => {
		if (playerCounts[role] >= roleLimits[role]) {
			return true;
		}

		if (role === "Portiere") return false;

		const roleIndex = roleOrder.indexOf(role);
		const previousRole = roleOrder[roleIndex - 1];
		return playerCounts[previousRole] < roleLimits[previousRole];
	};

	const width = "25px";

	const handleHome = () => {
		navigate("/");
	};

	return (
		<Container>
			<div className="d-flex align-items-center justify-content-center">
				<img
					src="/assets/img/fantantonio.webp"
					alt="Logo Fantantonio, concorso a premio, partecipazione gratuita"
					className="img-fluid fantaIcon point"
					title="Fantantonio, concorso a premio, partecipazione gratuita"
					onClick={handleHome}
				/>
			</div>
			<ReactModal
				isOpen={isModalOpen}
				onRequestClose={handleCloseModal}
				contentLabel="Conferma Scelta Squadra"
				className="Modal-evento"
				overlayClassName="Overlay"
			>
				<h2 className="text-center">Conferma Squadra Fantantonio</h2>
				<p className="text-center text-light">
					Sei sicuro di voler salvare questa formazione? Questa azione non può essere annullata.
				</p>
				<div className="d-flex align-items-center justify-content-around">
					<button
						onClick={() => {
							handleSubmit();
							handleCloseModal();
						}}
						className="nav-link tel confirm"
					>
						Conferma
					</button>
					<button onClick={handleCloseModal} className="nav-link tel back">
						Annulla
					</button>
				</div>
			</ReactModal>
			<ReactModal
				isOpen={isResetModalOpen}
				onRequestClose={handleCloseResetModal}
				contentLabel="Reset Squadra"
				className="Modal-evento"
				overlayClassName="Overlay"
			>
				<h2 className="text-center">Reset Squadra</h2>
				<p className="text-center text-light">
					Sei sicuro di voler cancellare la squadra? Questa azione non può essere annullata.
				</p>
				<p className="text-center text-light text-underline">
					Se hai già inviato una formazione valida, la nuova formazione non verrà presa in considerazione e la prima
					valida sarà in gioco nel concorso.
				</p>
				<div className="d-flex align-items-center justify-content-around">
					<button onClick={handleReset} className="nav-link tel confirm">
						Conferma
					</button>
					<button onClick={handleCloseResetModal} className="nav-link tel back">
						Annulla
					</button>
				</div>
			</ReactModal>
			<Row className="mb-3 bg-transparent">
				<div className="d-flex align-items-center justify-content-center bg-transparent">
					<h1 className="text-center my-2 mx-auto glass-card bg-transparent px-2 py-1">
						{hasFormation ? `Squadra ${teamName}` : "Crea la tua squadra del Fantantonio"}
					</h1>

					{!showAlert && (
						<button
							onClick={() => {
								setShowAlert(true);
							}}
							className="nav-link about-pst"
						>
							<AboutIcon width={width} />
						</button>
					)}
				</div>
				{showAlert && (
					<Col xs={12}>{showAlert && <AlertManager hasFormation={hasFormation} onClose={handleCloseAlert} />}</Col>
				)}
				{!hasFormation && (
					<>
						{!hasFormation && (
							<Row className="mx-auto">
								{roleOrder.map((role, index) => (
									<Col key={role + index} xs={6} lg={3} className="my-3">
										<div className="d-flex align-items-center justify-content-around flex-column flex-lg-row bg-transparent">
											<button
												key={role}
												className="gold banner rounded mx-auto mx-lg-1 my-1 px-auto py-1 button-custom button-role glass-card w-100 bg-transparent"
												onClick={() => setSelectedRole(role)}
												disabled={buttonDisabled(role)}
											>
												{role}
											</button>
										</div>
									</Col>
								))}
							</Row>
						)}
						<Row className="glass-card mx-auto bg-transparent">
							<Col xs={12} md={6}>
								{filteredPlayers.length > 0 ? (
									<h3 className="fs-5 text-center mt-4 mt-lg-2 mb-3 px-2 py-1">Lista {selectedRole}</h3>
								) : (
									""
								)}
								{selectedPlayers.length < 6 && filteredPlayers.length > 0 ? (
									<Row xs={1} sm={2} lg={3} className="g-2 clsx-scroll mb-2">
										{filteredPlayers.map((player, index) => (
											<Col
												xs={6}
												lg={4}
												key={index}
												onClick={() => handlePlayerSelect(player)}
												className="squad-hvr my-3"
											>
												<Card className="mt-2 point position-relative h-100 mb-1 glass-card hvr3 bg-transparent gap-3 ">
													<div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-3 mb-3 w-100">
														<CardText className="text-center text-light p-0 my-0 mx-auto my-fs-fanta">
															{getRoleAbbreviation(player.ruolo as PlayerRole)}
														</CardText>
														<img
															src={player.logoSquadra}
															alt={`Logo squadra ${player.squadra}`}
															className="logo-squad-create mx-auto mx-lg-0 ms-lg-auto me-lg-1"
														/>
													</div>
													<div className="text-center mt-3">
														<CardText className="player-name mt-3 mb-0 p-0 my-fs-player player-name">
															{splitPlayerName(player.nome)}
															<span className="ms-md-2 player-number">{player.numero}</span>
														</CardText>
													</div>
												</Card>
											</Col>
										))}
									</Row>
								) : (
									<Row xs={1} className="g-2 mb-2">
										<Col xs={12}>
											<p className="text-light my-3 text-center">Tutti i giocatori sono stati selezionati.</p>
										</Col>
									</Row>
								)}
							</Col>

							<Col>
								<h3 className="text-center mt-4 mt-lg-2 mb-3 fs-5 glass-card py-1">
									{teamName ? `Squadra ${teamName}` : "Squadra"}
								</h3>
								<Row xs={1} sm={2} lg={3} className="g-2 mb-2">
									<SelectedPlayerSummary selectedPlayers={selectedPlayers} handleRemovePlayer={handleRemovePlayer} />
								</Row>
							</Col>
						</Row>
					</>
				)}

				{hasFormation && <SquadraPersonalizzata players={localFormation} />}
				{hasFormation && (
					<div className="d-flex align-items-center justify-content-start ms-2">
						<button onClick={handleOpenResetModal} className="nav-link text-danger reset-button text-underline mt-2">
							Reset Squadra
						</button>
					</div>
				)}
			</Row>

			{!hasFormation && (
				<Row className="mt-5 mb-3">
					<Col xs={11} md={8} lg={6} xl={5} className="mx-auto">
						<Card className="glass-card ">
							<CardBody>
								{showDetails ? (
									<h3 className="text-center mb-3 text-light my-fs">
										Il tuo nome allenatore sará:
										<span className="text-underline">{username}</span> e la squadra si chiama{" "}
										<span className="text-underline">{teamName}</span>
									</h3>
								) : (
									<h3 className="text-center mb-3 text-light my-fs">
										Completa la squadra con il <span className="text-underline">tuo nome</span>, Il tuo{" "}
										<span className="text-underline">nome su Instagram</span> e il{" "}
										<span className="text-underline">nome della squadra</span>
									</h3>
								)}

								<div className="d-flex alig-items-center justify-content-center flex-column">
									<input
										className="form-control bg-transparent input-custom"
										type="text"
										value={username}
										onChange={(e) => {
											const newUsername = e.target.value;
											if (newUsername.length <= 20) {
												setUsername(newUsername);
												setUserNameError("");
											} else {
												setUserNameError("Il tuo nome e cognome non deve essere più lungo di 20 caratteri.");
											}
										}}
										placeholder="Nome e cognome"
										required
									/>
									<input
										className="form-control bg-transparent input-custom"
										type="text"
										value={linkInstagram}
										onChange={(e) => {
											const newLinkInstagram = e.target.value;
											if (newLinkInstagram.length <= 50) {
												setLinkInstagram(newLinkInstagram);
												setLinkInstagramError("");
											} else {
												setLinkInstagramError(
													`Inserisci un nome utente Instagram con lunghezza non superiore a 50 caratteri.`
												);
											}
										}}
										placeholder="Nome Profilo Instagram"
										required
									/>
									<input
										className="form-control bg-transparent input-custom"
										type="text"
										value={teamName}
										onChange={(e) => {
											const newTeamName = e.target.value;
											if (newTeamName.length <= 10) {
												setTeamName(newTeamName);
												setTeamNameError("");
											} else {
												setTeamNameError("Il nome della squadra non deve essere più lungo di 10 caratteri.");
											}
										}}
										placeholder="Nome Squadra"
										required
									/>
									{teamNameError && (
										<Alert variant="danger" className="mt-2 my-fs text-black">
											{teamNameError}
										</Alert>
									)}
									{userNameError && (
										<Alert variant="danger" className="mt-2 my-fs text-black">
											{userNameError}
										</Alert>
									)}
									{linkInstagramError && (
										<Alert variant="danger" className="mt-2 my-fs text-black">
											{linkInstagramError}
										</Alert>
									)}
									<div className="d-flex align-items-center justify-content-center">
										<button
											className="nav-link text-light glass-card bg-action px-3 py-2 mt-2"
											onClick={handleOpenModal}
											disabled={isLoading}
										>
											{isLoading ? (
												<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
											) : null}
											Salva Formazione
										</button>
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}
		</Container>
	);
};

export default SquadCreator;
