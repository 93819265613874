import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { getCookie, setCookie } from '../Script/Utility';

interface FavoriteContextType {
  favorite: string | null;
  setFavorite: (id: string | null) => void;
  removeFavorite: () => void; // Aggiunta di una funzione per rimuovere il preferito
}

const FavoriteContext = createContext<FavoriteContextType | undefined>(
  undefined
);

export const useFavorite = () => {
  const context = useContext(FavoriteContext);
  if (!context) {
    throw new Error(
      "useFavorite deve essere usato all'interno di un FavoriteProvider"
    );
  }
  return context;
};

interface FavoriteProviderProps {
  children: ReactNode;
}

export const FavoriteProvider: React.FC<FavoriteProviderProps> = ({
  children,
}) => {
  const [favorite, setFavorite] = useState<string | null>(null);

  const removeFavorite = () => {
    setFavorite(null); // Resetta il preferito a null
    setCookie('favoriteTeam', '', -1);
  };

  useEffect(() => {
    const favoriteFromCookie = getCookie('favoriteTeam');
    setFavorite(favoriteFromCookie);
  }, []);

  return (
    <FavoriteContext.Provider value={{ favorite, setFavorite, removeFavorite }}>
      {children}
    </FavoriteContext.Provider>
  );
};
