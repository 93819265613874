import React, { useEffect, useState } from "react";
import { ISquadra, PlayerRole } from "../Interfaces/Interface";
import anime from "animejs";
import { Col, Row, Card, CardText, Badge, Container } from "react-bootstrap";
import { getRoleAbbreviation, splitPlayerName } from "../SquadCreator/SquadCreator";

interface WinnerPlayersProps {
	team: ISquadra;
	colors: string[];
}

const WinnerPlayers: React.FC<WinnerPlayersProps> = ({ team, colors }) => {
	const [badgeColors, setBadgeColors] = useState<string[][]>([]);

	// Funzione per aggiungere una classe dinamica con !important
	const addDynamicStyle = (color: string) => {
		if (!color) return ""; // Controlla se il colore è undefined o vuoto

		const cleanedColor = color.replace("#", "");
		const styleId = `dynamic-style-${cleanedColor}`;

		if (!document.getElementById(styleId)) {
			const style = document.createElement("style");
			style.id = styleId;
			style.innerHTML = `.badge-${cleanedColor} { background-color: ${color} !important; }`;
			document.head.appendChild(style);
		}
		return `badge-${cleanedColor}`;
	};

	useEffect(() => {
		anime({
			targets: ".player-item",
			opacity: [0, 1],
			translateX: (el: any, i: any) => {
				const positionInRow = i % 3;
				if (positionInRow === 0) return ["-100vw", "0"]; // Sinistra
				if (positionInRow === 1) return ["0", "0"]; // Centro
				return ["100vw", "0"]; // Destra
			},
			scale: (el: any, i: any) => {
				const positionInRow = i % 3;
				if (positionInRow === 1) return [0.5, 1]; // Centro
				return [1, 1];
			},
			delay: anime.stagger(200),
			duration: 1000,
			easing: "easeInOutQuad",
		});
	}, []);

	useEffect(() => {
		const newBadgeColors: string[][] = [];

		team.giocatori?.forEach((_, index) => {
			const cardColor = colors[index % colors.length];
			const badgeColorSet: string[] = [];

			// Generate two alternating colors for the badges
			for (let i = 0; i < 4; i++) {
				let offset = i % 2 === 0 ? 1 : 2;
				let color = colors[(index + offset) % colors.length];
				while (color === cardColor) {
					offset++;
					color = colors[(index + offset) % colors.length];
				}
				badgeColorSet.push(color);
			}

			newBadgeColors.push(badgeColorSet);
		});

		setBadgeColors(newBadgeColors);
	}, [team.giocatori, colors]);

	// Funzione per controllare se il ruolo è valido
	const isValidRole = (role: string): role is PlayerRole => {
		return ["Portiere", "Difensore", "Centrocampista", "Attaccante"].includes(role);
	};

	return (
		<div className="mt-3">
			<h2 className="text-light">Rosa vincitrice</h2>
			<Row className="player-list justify-content-center">
				{team.giocatori?.map((player, index) => {
					const cardColor = colors[index % colors.length];
					const badgeColorSet = badgeColors[index] || [];

					return (
						<Col key={player.id} xs={12} md={6} lg={4} className="player-item glass-card bg-transparent mx-lg-1 my-1">
							<Card
								className="mt-2 point position-relative h-100 mb-1 glass-card hvr3 gap-3 h-100"
								style={{ backgroundColor: cardColor }}
							>
								<div className="d-flex align-items-center justify-content-between mt-3 mb-3 w-100">
									<CardText className="text-center p-0 my-0 my-fs-fanta-final player-role ms-3">
										{isValidRole(player.ruolo) ? getRoleAbbreviation(player.ruolo as PlayerRole) : "Unknown"}
									</CardText>
									<img
										src={player.logoSquadra}
										alt={`Logo squadra ${player.squadra}`}
										className="logo-squad-create-final"
									/>
								</div>
								<div className="text-center mt-3">
									<CardText className="player-name-final mb-0 p-0 my-fs">{player.nome}</CardText>
									<span className="ms-md-2 player-number-fanta-final">{player.numero}</span>
								</div>
								<Container className="mt-3">
									<Row>
										<Col xs={6}>
											<Badge className={`custom-badge w-100 mb-2 ${addDynamicStyle(badgeColorSet[0])}`}>
												<span>Goal: {player.goal}</span>
											</Badge>
										</Col>
										<Col xs={6}>
											<Badge className={`custom-badge w-100 mb-2 ${addDynamicStyle(badgeColorSet[1])}`}>
												<span>Assist: {player.assist}</span>
											</Badge>
										</Col>
										<Col xs={6}>
											<Badge className={`custom-badge w-100 mb-2 ${addDynamicStyle(badgeColorSet[2])}`}>
												<span>Cartellini Gialli: {player.cartelliniGialli}</span>
											</Badge>
										</Col>
										<Col xs={6}>
											<Badge className={`custom-badge w-100 mb-2 ${addDynamicStyle(badgeColorSet[3])}`}>
												<span>Cartellini Rossi: {player.cartelliniRossi}</span>
											</Badge>
										</Col>
									</Row>
								</Container>
							</Card>
						</Col>
					);
				})}
			</Row>
		</div>
	);
};

export default WinnerPlayers;
