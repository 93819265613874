import React, { useState, useEffect } from "react";
import axiosInstance from "../Script/axiosConfig";
import { useAuth } from "../Context/AuthContext";
import { Card, Button, Container, Form } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap-switch-button-react/css/bootstrap-switch-button.css"; // Importa lo stile per l'interruttore a scorrimento

const UserProfile: React.FC = () => {
	const { user } = useAuth();
	const [qrCodeUrl, setQrCodeUrl] = useState("");
	const [secret, setSecret] = useState("");
	const [token, setToken] = useState("");
	const [message, setMessage] = useState("");
	const [isTwoFactorEnabled, setIsTwoFactorEnabled] = useState(false);

	useEffect(() => {
		if (user) {
			setIsTwoFactorEnabled(user.isTwoFactorEnabled);
		}
	}, [user]);

	const handleEnable2FA = async () => {
		try {
			const response = await axiosInstance.post("/api/generate-2fa-secret", {});
			setQrCodeUrl(response.data.qrCodeDataUrl);
			setSecret(response.data.secret);
		} catch (error) {
			setMessage("Error generating 2FA secret");
			console.error("Error enabling 2FA", error);
		}
	};

	const handleDisable2FA = async () => {
		try {
			await axiosInstance.post("/api/disable-2fa", {});
			setIsTwoFactorEnabled(false);
			setQrCodeUrl("");
			setSecret("");
		} catch (error) {
			setMessage("Error disabling 2FA");
			console.error("Error disabling 2FA", error);
		}
	};

	const enable2FA = async () => {
		try {
			const response = await axiosInstance.post("/api/enable-2fa", { token });
			setMessage(response.data.message);
			setIsTwoFactorEnabled(true);
			setQrCodeUrl("");
			setSecret("");
		} catch (err) {
			setMessage("Error enabling 2FA");
		}
	};

	const isMobileNumber = (number: string) => {
		// Rimuove spazi, trattini e parentesi
		const cleanedNumber = number.replace(/[\s\-()]/g, "");

		// Espressione regolare per numeri mobili italiani
		const mobileRegex = /^3\d{9}$/;

		// Espressione regolare per numeri fissi italiani
		const fixedRegex = /^0\d{9,10}$/;

		if (mobileRegex.test(cleanedNumber)) {
			return "Mobile";
		} else if (fixedRegex.test(cleanedNumber)) {
			return "Fisso";
		} else {
			return "Numero non valido";
		}
	};

	const formatPhoneNumber = (number: string) => {
		const type = isMobileNumber(number);
		if (type === "Mobile") {
			return `Cel: ${number}`;
		} else if (type === "Fisso") {
			return `Tel: ${number}`;
		} else {
			return `Numero non valido: ${number}`;
		}
	};

	return (
		<Container className="d-flex justify-content-center mt-5">
			<Card className="glass-card" style={{ width: "400px" }}>
				<Card.Body className="text-light">
					<Card.Title className="text-center">User Profile</Card.Title>
					{user && (
						<>
							{user.googleProfileImage && (
								<div className="text-center">
									<img
										src={user.googleProfileImage}
										alt="Profile"
										className="profile-image rounded-circle"
										style={{ width: "80px", height: "80px" }}
									/>
								</div>
							)}
							<Card.Text className="fs-3 text-center mt-2">
								<strong>
									{user.firstName} {user.lastName}
								</strong>
							</Card.Text>
							<Card.Text>
								<strong>Email:</strong> {user.email}
							</Card.Text>
							{user.phoneNumber && (
								<Card.Text>
									<strong>{formatPhoneNumber(user.phoneNumber)}</strong>
								</Card.Text>
							)}
							<Form>
								<Form.Check
									type="switch"
									id="custom-switch"
									label="2FA Enabled"
									checked={isTwoFactorEnabled}
									onChange={isTwoFactorEnabled ? handleDisable2FA : handleEnable2FA}
								/>
							</Form>

							{qrCodeUrl && (
								<div className="mt-3">
									<p>Scan this QR code with your 2FA app:</p>
									<img src={qrCodeUrl} alt="2FA QR Code" className="img-fluid" />
									<p>Or enter this secret manually: {secret}</p>
									<input
										type="text"
										placeholder="Enter 2FA token"
										value={token}
										onChange={(e) => setToken(e.target.value)}
									/>
									<Button variant="primary" onClick={enable2FA}>
										Enable 2FA
									</Button>
								</div>
							)}
						</>
					)}
					{message && <p>{message}</p>}
				</Card.Body>
			</Card>
		</Container>
	);
};

export default UserProfile;
