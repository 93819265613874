import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLoading } from "./Context/LoadingContext";
import { NavLink, useLocation } from "react-router-dom";

const Header = () => {
	const { t, ready } = useTranslation(["header"]);
	const { startLoading, stopLoading } = useLoading();
	const location = useLocation();
	const paths = ["/", "/*", "/policy", "/", "/doc", "/generale", "/registrazione", "/merch", "/calendario"];

	useEffect(() => {
		if (!ready) {
			startLoading();
		} else {
			stopLoading();
		}
	}, [ready, startLoading, stopLoading]);

	if (!paths.includes(location.pathname)) {
		return null;
	}

	return (
		<div className="col-12 p-2">
			<div className="d-flex align-items-center justify-content-center">
				<NavLink to="/" className="">
					<img src="/assets/img/logo-lettering-new.webp" alt={t("header:title")} className="logo-top" />
				</NavLink>
			</div>

			<h1 className="user-name fs-3 text-center d-none">{t("header:title")}</h1>
			<div className="my-margin">
				<p className="user-descrip text-center fs-5">
					{t("header:description")} <br />
					{t("header:edition")}
				</p>
				<p className="text-light text-center fs-5">{t("header:slogan")}</p>
			</div>
		</div>
	);
};

export default Header;
