import React, { useState, useEffect } from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import { ISquadra, Giornata as GiornataType, squadre, giornate } from "../Interfaces/Interface";
import Partita from "../Calendario/Partita";
import Loader from "../Loader/Loader";
import WinnerPlayers from "./WinnerPlayers";
import confetti from "canvas-confetti";
import ColorThief from "colorthief";
import { useHelmet } from "../Context/HelmetContext";
import { useTranslation } from "react-i18next";
import { useLoading } from "../Context/LoadingContext";
import anime from "animejs";

interface PartitaFinaleProps {
	id: string;
	hasWinner: boolean | null;
}

const PartitaFinale: React.FC<PartitaFinaleProps> = ({ id, hasWinner }) => {
	const [giornataFinale, setGiornataFinale] = useState<GiornataType | null>(null);
	const [winner, setWinner] = useState<ISquadra | null>(null);
	const [showPlayers, setShowPlayers] = useState<boolean>(false);
	const [confettiColors, setConfettiColors] = useState<string[]>(["#c0a868", "#20275b", "#000000"]);
	const { setState } = useHelmet();
	const { t, ready } = useTranslation("partita-finale");
	const { startLoading, stopLoading } = useLoading();
	const [clickCount, setClickCount] = useState<number>(0);
	const [showEasterEgg, setShowEasterEgg] = useState<boolean>(false);
	const easterEggThreshold = 21;

	useEffect(() => {
		if (!ready) {
			startLoading();
		} else {
			stopLoading();
		}
	}, [ready, startLoading, stopLoading]);

	useEffect(() => {
		// Imposta il titolo SEO e la descrizione per la pagina Registrazione
		setState((prevState) => ({
			...prevState,
			title: t("seo.title"),
			description: t("seo.description"),
		}));
		return () => {
			setState((prevState) => ({
				...prevState,
				title: prevState.title,
				description: prevState.description,
			}));
		};
	}, [setState, t]);

	useEffect(() => {
		const loadData = () => {
			const finale = giornate.find((g) => g.id === id);
			setGiornataFinale(finale || null);
		};
		loadData();
	}, [id]);

	useEffect(() => {
		if (giornataFinale) {
			const partitaFinale = giornataFinale.partite[1];
			const [golCasa, golOspite] = partitaFinale.risultato ? partitaFinale.risultato.split("-").map(Number) : [0, 0];

			if (golCasa !== undefined && golOspite !== undefined) {
				if (hasWinner !== null) {
					if (hasWinner) {
						setWinner(squadre.find((s) => s.id === partitaFinale.squadraCasaId) || null);
					} else {
						setWinner(squadre.find((s) => s.id === partitaFinale.squadraOspiteId) || null);
					}
				} else if (golCasa > golOspite) {
					setWinner(squadre.find((s) => s.id === partitaFinale.squadraCasaId) || null);
				} else if (golCasa < golOspite) {
					setWinner(squadre.find((s) => s.id === partitaFinale.squadraOspiteId) || null);
				}
			}
		}
	}, [giornataFinale, hasWinner]);

	useEffect(() => {
		if (winner) {
			const img = new Image();
			img.crossOrigin = "Anonymous";
			img.src = winner.logo;
			img.onload = () => {
				const colorThief = new ColorThief();
				const palette = colorThief.getPalette(img, 5); // Get the top 5 colors
				const distinctColors = ensureDistinctColors(palette, 3); // Ensure the colors are distinct
				const hexColors = distinctColors.map((color) => rgbToHex(color[0], color[1], color[2]));
				setConfettiColors(hexColors);
			};
		}
	}, [winner]);

	const ensureDistinctColors = (palette: [number, number, number][], numColors: number): [number, number, number][] => {
		const distinctColors: [number, number, number][] = [];
		for (let i = 0; i < palette.length && distinctColors.length < numColors; i++) {
			let isDistinct = true;
			for (let j = 0; j < distinctColors.length; j++) {
				if (colorDistance(palette[i], distinctColors[j]) < 10) {
					// Adjust threshold as needed
					isDistinct = false;
					break;
				}
			}
			if (isDistinct) {
				distinctColors.push(palette[i]);
			}
		}
		return distinctColors.length < numColors ? palette.slice(0, numColors) : distinctColors;
	};

	const colorDistance = (color1: [number, number, number], color2: [number, number, number]): number => {
		return Math.sqrt(
			Math.pow(color1[0] - color2[0], 2) + Math.pow(color1[1] - color2[1], 2) + Math.pow(color1[2] - color2[2], 2)
		);
	};

	const rgbToHex = (r: number, g: number, b: number): string => {
		return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
	};

	const handleLogoClick = () => {
		setClickCount((prevCount) => prevCount + 1); // Increment click count

		confetti({
			particleCount: 1000,
			spread: 360,
			origin: { y: 0.38 },
			colors: confettiColors, // Use extracted colors
		});

		anime({
			targets: ".winner-logo",
			scale: [1, 1.3],
			duration: 1200,
			easing: "easeInOutQuad",
			complete: () => {
				setShowPlayers(true);
			},
		});

		if (clickCount + 1 === easterEggThreshold) {
			triggerEasterEgg();
		}
	};

	const triggerEasterEgg = () => {
		setShowEasterEgg(true);
		setTimeout(() => {
			setShowEasterEgg(false);
			setClickCount(0);
		}, 15000); // Nasconde la GIF dopo 15 secondi
	};

	if (!giornataFinale) {
		return (
			<>
				<div className="loader">
					<Loader />
				</div>
			</>
		);
	}

	const partitaFinale = giornataFinale.partite[1]; // Assumendo che ci sia solo una partita nella giornata finale

	return (
		<Container className="mt-3 h-100 glass-card p-2 bg-transparent">
			<Row>
				<Col>
					<h1 className="text-center mt-2 mb-4">{`Finale Campionato di San'Antonio`}</h1>
					<Partita partita={partitaFinale} squadre={squadre} dataGiornata={giornataFinale.data} />
					{winner && (
						<div className="text-center mt-5">
							<h2>Vincitore!</h2>
							<div style={{ position: "relative", display: "inline-block" }}>
								<img
									src={winner.logo}
									alt={`Logo ${winner.nome}`}
									className="winner-logo drop-shadow"
									onClick={handleLogoClick}
									style={{ cursor: "pointer", width: "150px", height: "150px" }}
								/>
								{!showPlayers && (
									<div
										className="click-me"
										style={{
											position: "absolute",
											top: "10px",
											left: "100%",
											transform: "translate(-50%, -50%)",
											backgroundColor: "#c0a868",
											color: "#fff",
											padding: "5px 10px",
											borderRadius: "5px",
											animation: "fadeInOut 2s infinite",
										}}
									>
										Cliccami!
									</div>
								)}
							</div>
							{showPlayers && (
								<>
									<h1 className="mt-3" style={{ fontSize: "3rem", color: "#c0a868" }}>
										Campioni!
									</h1>
									<WinnerPlayers team={winner} colors={confettiColors} />
								</>
							)}
							{showEasterEgg && (
								<Card
									className="mt-3 bg-transparent border-0 glass-card p-2 mx-auto"
									style={{
										maxHeight: "1000px",
										maxWidth: "1000px",
										minHeight: "380px",
										minWidth: "380px",
										zIndex: 1000,
										position: "fixed",
										top: "50%",
										left: "50%",
										transform: "translate(-50%, -50%)",
									}}
								>
									<div className="bg-doc text-light rounded-personal p-3">
										<Card.Title className="fs-5">
											Grazie a tutti ni vitimu a San Pascali! <br />
											per la stagione 2025 ❤️
										</Card.Title>
									</div>
									<Card.Body>
										<Card.Img
											variant="top"
											src="/assets/vid/de1a7532-7044-474e-9dcf-279e3340253b.webp"
											alt="Easter Egg"
											className="img-fluid"
										/>
									</Card.Body>
									<div className="bg-doc text-light rounded-personal p-2">
										<Card.Subtitle>ps: il vostro sviluppatore di fiducia! 😘</Card.Subtitle>
									</div>
								</Card>
							)}
						</div>
					)}
				</Col>
			</Row>
		</Container>
	);
};

export default PartitaFinale;
