import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import YoungManIcon from '../components/svg/YoungManIcon';
import OldManIcon from '../components/svg/OldManIcon';

const LanguageSelector = () => {
  const { i18n } = useTranslation(); // Questa è la dichiarazione di 'i18n'
  const [activeLanguage, setActiveLanguage] = useState(
    localStorage.getItem('i18nextLng') || 'it'
  );

  useEffect(() => {
    if (i18n.language !== activeLanguage) {
      i18n.changeLanguage(activeLanguage);
    }
  }, [i18n, activeLanguage]);

  const changeLanguage = (lng: string) => {
    if (lng !== activeLanguage) {
      i18n.changeLanguage(lng);
      setActiveLanguage(lng);
      localStorage.setItem('i18nextLng', lng);
    }
  };

  return (
    <div className="d-flex gap-2 icon-lang-container">
      <div
        onClick={() => changeLanguage('it')}
        className={`point bg-transparent ${activeLanguage !== 'it' ? 'show' : 'd-none'}`}
        title="Italiano"
      >
        <YoungManIcon />
      </div>
      <div
        onClick={() => changeLanguage('or')}
        className={` point bg-transparent ${activeLanguage !== 'or' ? 'show' : 'd-none'}`}
        title="Oritano"
      >
        <OldManIcon />
      </div>
    </div>
  );
};

export default LanguageSelector;
