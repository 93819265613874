import React, { useEffect, useRef, useState } from "react";
import { Col } from "react-bootstrap";

const Promo = () => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const [showPoster, setShowPoster] = useState(true);

	const handlePlay = () => {
		setShowPoster(false);
		if (videoRef.current) {
			videoRef.current.play().catch((error) => {
				console.error("Error attempting to play video:", error);
				setShowPoster(true); // Ripristina il poster se la riproduzione fallisce
			});
		}
	};

	const handleVideoEnd = () => {
		setShowPoster(true);
	};

	useEffect(() => {
		const video = videoRef.current;
		if (video) {
			video.addEventListener("play", handlePlay);
			video.addEventListener("ended", handleVideoEnd);

			return () => {
				video.removeEventListener("play", handlePlay);
				video.removeEventListener("ended", handleVideoEnd);
			};
		}
	}, []);

	return (
		<Col xs={10} sm={8} md={6} className="mx-auto mt-3 d-flex align-items-center justify-content-center flex-column">
			{showPoster && (
				<img
					src="/assets/img/cover.webp"
					alt="Video Poster"
					className="promo-video poster p-1 bg-doc home-round glass-card hvr2"
					onClick={handlePlay}
					style={{ cursor: "pointer" }}
				/>
			)}
			<video
				ref={videoRef}
				src="/assets/vid/promo.MP4"
				controls
				className="promo-video mx-auto p-1 bg-doc home-round"
				style={{ display: showPoster ? "none" : "block", cursor: "pointer" }}
				poster="/assets/img/cover.webp"
				preload="none"
			/>
		</Col>
	);
};

export default Promo;
