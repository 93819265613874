import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Table } from "react-bootstrap";
import { Giocatore, Stats } from "../Interfaces/Interface";
import squadreData from "../Script/squadre.json";

type PlayerInfo = {
	_id: string;
	id: string;
	nome: string;
	squadra: string;
};

type ModalType = {
	isOpen: boolean;
	onRequestClose: () => void;
	teamName: string;
	playersInfo: PlayerInfo[];
};

const ModalStatistiche: React.FC<ModalType> = ({ isOpen, onRequestClose, teamName, playersInfo }) => {
	const [teamStats, setTeamStats] = useState<Stats>();
	const [playerStats, setPlayerStats] = useState<Giocatore[]>([]);

	const calculateTeamStats = (playersData: Giocatore[]): Stats => {
		return playersData.reduce(
			(acc: Stats, player: Giocatore) => ({
				goals: acc.goals + (player.goal || 0),
				assists: acc.assists + (player.assist || 0),
				yellowCards: acc.yellowCards + (player.cartelliniGialli || 0),
				redCards: acc.redCards + (player.cartelliniRossi || 0),
				stars: acc.stars + (player.star || 0),
			}),
			{ goals: 0, assists: 0, yellowCards: 0, redCards: 0, stars: 0 }
		);
	};

	const findPlayerByIdOrName = (id: string, name: string, team: string): Giocatore | undefined => {
		let player: Giocatore | undefined = squadreData
			.flatMap((squadra) => squadra.giocatori || [])
			.find((player) => player.id === id);

		if (!player) {
			player = squadreData
				.flatMap((squadra) => squadra.giocatori || [])
				.find(
					(player) =>
						player.nome.toLowerCase() === name.toLowerCase() && player.squadra.toLowerCase() === team.toLowerCase()
				);
		}

		if (player) {
			player.ruolo = player.ruolo || "";
			player.star = player.star ?? 0;
			player.goalSubiti = player.goalSubiti ?? 0;
			player._id = player._id || "";
		}

		return player;
	};

	useEffect(() => {
		const players: Giocatore[] = playersInfo
			.map(({ id, nome, squadra }) => {
				let player = squadreData.flatMap((squadra) => squadra.giocatori || []).find((p) => p.id === id);

				if (!player) {
					player = findPlayerByIdOrName(id, nome, squadra);
				}

				if (player) {
					player.ruolo = player.ruolo || "";
					player.star = player.star ?? 0;
					player.goalSubiti = player.goalSubiti ?? 0;
					player._id = player._id || "";
				}
				return player as Giocatore | undefined;
			})
			.filter((player): player is Giocatore => player !== undefined);

		if (players.length > 0) {
			setTeamStats(calculateTeamStats(players));
			setPlayerStats(players);
		}
	}, [playersInfo, teamName]);

	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			contentLabel="Statistiche Squadra"
			className="Modal-evento"
			overlayClassName="Overlay"
		>
			<div className="statistiche-container">
				<div className="table-wrapper mx-5 my-auto">
					<h3 className="text-center gold mt-1 mb-4">Statistiche {teamName || ""}</h3>
					<div id="clsf">
						<div className="table-responsive">
							<Table striped bordered hover className="clsf">
								<thead>
									<tr>
										<th className="text-center">Statistiche</th>
										<th className="text-center">Totali</th>
									</tr>
								</thead>
								{teamStats ? (
									<tbody>
										<tr>
											<td className="text-center text-light">Goal Totali</td>
											<td className="text-center text-light">{teamStats.goals}</td>
										</tr>
										<tr>
											<td className="text-center text-light">Assist Totali</td>
											<td className="text-center text-light">{teamStats.assists}</td>
										</tr>
										<tr>
											<td className="text-center text-light">Cartellini Gialli</td>
											<td className="text-center text-light">{teamStats.yellowCards}</td>
										</tr>
										<tr>
											<td className="text-center text-light">Cartellini Rossi</td>
											<td className="text-center text-light">{teamStats.redCards}</td>
										</tr>
										<tr>
											<td className="text-center text-light">Stelle Totali</td>
											<td className="text-center text-light">{teamStats.stars}</td>
										</tr>
									</tbody>
								) : (
									<tbody>
										<tr>
											<td colSpan={2} className="text-center">
												Caricamento statistiche...
											</td>
										</tr>
									</tbody>
								)}
							</Table>
						</div>
					</div>
				</div>

				<div className="table-wrapper">
					<div id="clsf">
						<h4 className="text-center mt-1 mb-4">Statistiche Giocatori</h4>
						<div className="table-responsive">
							<Table striped bordered hover className="clsf">
								<thead>
									<tr>
										<th className="text-center">Giocatore</th>
										<th className="text-center">Goal</th>
										<th className="text-center">Assist</th>
										<th className="text-center">CG</th>
										<th className="text-center">CR</th>
										<th className="text-center">Stelle</th>
									</tr>
								</thead>
								<tbody>
									{playerStats.map((player) => (
										<tr key={player.id}>
											<td className="text-center text-light">{player.nome}</td>
											<td className="text-center text-light">{player.goal}</td>
											<td className="text-center text-light">{player.assist}</td>
											<td className="text-center text-light">{player.cartelliniGialli}</td>
											<td className="text-center text-light">{player.cartelliniRossi}</td>
											<td className="text-center text-light">{player.star}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</div>
					</div>
				</div>
			</div>
		</ReactModal>
	);
};

export default ModalStatistiche;
