import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";
import { IRole } from "../Interfaces/Interface";
import { isProduction } from "./Utility";

const AuthLinks = () => {
	const { token, isAuthenticated, logout, user } = useAuth();
	const navigate = useNavigate();

	const handleLogout = async () => {
		try {
			await logout();
			navigate("/login");
		} catch (error) {
			console.error("Error logging out", error);
		}
	};

	const handleClick = () => {
		navigate("/admin");
	};

	// Aggiungi log per il debug
	console.log("User object:", user);
	console.log("Is authenticated:", isAuthenticated);

	// Mostra i link solo se non è in produzione
	if (isProduction) {
		return null;
	}

	return (
		<div className="auth-links position-fixed top-50 right-0 z-index">
			{isAuthenticated && user && user.roles.some((role: IRole) => role.name === "admin") && (
				<NavLink to="/admin" className="nav-link nav-links glass-card px-4 py-2 mb-2" onClick={handleClick}>
					Admin
				</NavLink>
			)}
			{isAuthenticated ? (
				<>
					<button className="nav-link glass-card px-4 py-2 text-danger" onClick={handleLogout}>
						Logout
					</button>
				</>
			) : (
				<>
					<NavLink to="/login" className="nav-link nav-links">
						Login
					</NavLink>
					<NavLink to="/register" className="nav-link nav-links">
						Register
					</NavLink>
				</>
			)}
		</div>
	);
};

export default AuthLinks;
