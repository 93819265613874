import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import BackIcon from '../svg/BackIcon';

const Back = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showBack, setShowBack] = useState(false);
  const initialPath = useRef('/');

  useEffect(() => {
    // Carica il percorso iniziale dal localStorage o imposta quello attuale se non presente
    const savedPath = localStorage.getItem('initialPath');
    if (savedPath) {
      initialPath.current = savedPath;
    } else {
      initialPath.current = location.pathname;
      localStorage.setItem('initialPath', location.pathname);
      // Imposta un timer per rimuovere il percorso dopo 1 ora
      setTimeout(() => {
        localStorage.removeItem('initialPath');
      }, 3600000); // 1 ora
    }
  }, [location.pathname]);

  useEffect(() => {
    // Aggiorna lo stato showBack quando cambia il percorso
    if (location.pathname !== initialPath.current) {
      setShowBack(true);
    } else {
      setShowBack(false);
    }
  }, [location.pathname]);

  if (!showBack) {
    return null;
  }

  const goBack = () => navigate(-1);

  return (
    <button onClick={goBack} className="nav-link back-arrow">
      <BackIcon />
    </button>
  );
};

export default Back;
